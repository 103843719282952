$tooltip-font-family: var(--font-family-regular);
$tooltip-background-color: var(--gray-700);
$tooltip-color: var(--white);
$tooltip-font-size: 12px;
$tooltip-border-radius: 2px;
$tooltip-padding: 8px 12px;
$tooltip-line-height: 16px;

.dx-tooltip-wrapper .dx-overlay-content {
  background-color: $tooltip-background-color;
  border-radius: $tooltip-border-radius !important;
}
.dx-tooltip-wrapper:has(.event-wrapper) {
  .dx-overlay-content {
    background: white;
    border: 2px solid $tooltip-background-color;
  }
}

.dx-tooltip-wrapper .dx-overlay-content::before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  padding: $tooltip-padding;
  font-family: $tooltip-font-family;
  font-size: $tooltip-font-size;
  color: $tooltip-color;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: $tooltip-line-height;
}

.dx-tooltip-wrapper.dx-popover-wrapper .dx-popover-arrow {
  display: block !important;
}

.dx-popover-wrapper .dx-popover-arrow::after {
  background-color: $tooltip-background-color;
}
