@include media-breakpoint-up(desktop) {
  .header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--body-backgroud);
    display: flex;
    justify-content: space-between;
    height: $header-height-desktop;
    position: fixed;
    top: calc($environment-info-top-label-height + var(--release-info-label-top));
    right: 0;
    left: 0;
    z-index: 97;

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding-left: 0;
      width: 100%;
      padding: $header-container-padding;
      margin-right: auto;
      margin-left: auto;
    }

    .header-brand {
      height: $header-height-desktop;
      display: flex;

      .header-logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: $header-height-desktop;
        min-width: $header-height-desktop;
        padding: 0 8px;

        .pps-g2-logo {
          height: $header-height-desktop;
        }

        .brand-logo {
          height: $header-brand-logo-height-desktop;
        }

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: $header-border-padding;
          bottom: $header-border-padding;
          border-right: 1px solid $header-border-color;
        }
      }
    }
  }
}

@include media-breakpoint-down(tablet) {
  .header {
    display: none;
  }
  .header-brand {
    display: none;
  }
}
