$switch-height: 24px;
$switch-width: 52px;
$switch-small-height: 16px;
$switch-small-width: 34px;
$switch-handle-width: 18px;
$switch-handle-height: 18px;
$switch-small-handle-width: 12px;
$switch-small-handle-height: 12px;
$switch-handle-background-color: var(--white);
$switch-default-background-color: var(--gray-500);
$switch-value-background-color: var(--primary);
$switch-disabled-handle-background-color: var(--disabled-tertiary);
$switch-disabled-background-color: var(--disabled-primary);

.dx-switch {
  height: $switch-height;
  width: $switch-width;

  .dx-switch-container {
    height: $switch-height;
    width: $switch-width;

    .dx-switch-handle {
      width: $switch-handle-width;
      height: $switch-handle-height;
      background-color: $switch-handle-background-color;
      flex-basis: $switch-handle-width;
      box-shadow: unset;

      &::before {
        display: none;
      }
    }

    .dx-switch-inner {
      align-items: center;
    }

    &::before {
      margin: 0;
      height: $switch-height;
      width: $switch-width;
    }
  }

  &:not(.dx-switch-on-value) {
    .dx-switch-container {
      &::before {
        background-color: $switch-default-background-color;
      }

      .dx-switch-inner {
        .dx-switch-on {
          width: $switch-width + 3px;
        }
      }
    }
  }

  &.dx-switch-on-value {
    .dx-switch-container {
      &::before {
        background-color: $switch-value-background-color;
      }

      .dx-switch-inner {
        .dx-switch-on {
          width: $switch-width - 3px;
        }
      }
    }
  }

  &.dx-state-disabled {
    .dx-switch-container {
      &::before {
        background-color: $switch-disabled-background-color;
      }

      .dx-switch-handle {
        background-color: $switch-disabled-handle-background-color;
      }
    }

    &.dx-switch-on-value {
      .dx-switch-container {
        &::before {
          background-color: $switch-disabled-handle-background-color;
        }

        .dx-switch-handle {
          background-color: $switch-disabled-background-color;
        }
      }
    }
  }

  &.switch-small {
    height: $switch-small-height;
    width: $switch-small-width;

    .dx-switch-container {
      height: $switch-small-height;
      width: $switch-small-width;

      .dx-switch-handle {
        width: $switch-small-handle-width;
        height: $switch-small-handle-height;
        flex-basis: $switch-small-handle-width;
      }

      &::before {
        height: $switch-small-height;
        width: $switch-small-width;
      }
    }

    &:not(.dx-switch-on-value) {
      .dx-switch-container {
        .dx-switch-inner {
          .dx-switch-on {
            width: $switch-small-width + 3px;
          }
        }
      }
    }

    &.dx-switch-on-value {
      .dx-switch-container {
        .dx-switch-inner {
          .dx-switch-on {
            width: $switch-small-width - 3px;
          }
        }
      }
    }
  }
}
