$dispensing-header-height-desktop: 65px;
$dispensing-footer-height: 76px;

@include media-breakpoint-up(desktop) {
  #dispensingApp {
    .header {
      height: $dispensing-header-height-desktop;

      .header-brand {
        height: $dispensing-header-height-desktop;

        .header-logo {
          min-height: $dispensing-header-height-desktop;
          min-width: $dispensing-header-height-desktop;

          .pps-g2-logo {
            height: $dispensing-header-height-desktop;
          }
        }
      }

      .topbar {
        height: $dispensing-header-height-desktop;

        .topbar-item {
          font-size: 12px;
        }

        .circle-icon {
          .dx-icon {
            border-radius: 50%;
          }
        }
      }
    }

    .footer {
      height: $dispensing-footer-height;
      padding-block: 16px;
    }

    .content-wrapper {
      padding: $header-height-desktop + $environment-info-top-label-height 0 0;
    }

    .content {
      overflow-y: auto;
      height: calc(100vh - #{$dispensing-header-height-desktop} - #{$environment-info-top-label-height}) !important;
    }

    .page-without-footer {
      height: calc(100vh - #{$dispensing-header-height-desktop} - #{$environment-info-top-label-height}) !important;
    }

    .page-with-footer {
      height: calc(
        100vh - #{$dispensing-header-height-desktop} - #{$dispensing-footer-height} - #{$environment-info-top-label-height}
      ) !important;
    }

    .dispensing-container {
      width: min(100% - 3rem, 92vw);
      margin-inline: auto;
    }
  }
}

@include media-breakpoint-down(tablet) {
  #dispensingApp {
    .footer {
      height: $dispensing-footer-height;
    }

    .content-wrapper {
      padding: $header-height-desktop + $environment-info-top-label-height 0 0;
    }

    .content {
      overflow-y: auto;
      height: calc(100vh - #{$dispensing-header-height-desktop} - #{$environment-info-top-label-height}) !important;
    }

    .page-without-footer {
      height: calc(100vh - #{$dispensing-header-height-desktop} - #{$environment-info-top-label-height}) !important;
    }

    .page-with-footer {
      height: calc(
        100vh - #{$dispensing-header-height-desktop} - #{$dispensing-footer-height} - #{$environment-info-top-label-height}
      ) !important;
    }
  }
}
