// Override DevExtreme Load Indicator wrapper with PPS custom spinner
.dx-loadindicator-wrapper {
  background-image: url('/assets/media/svg/icons/spinner.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  transform-origin: 50% 50%;
  background-size: contain;
  -webkit-animation: spin 1568ms linear infinite;
  animation: spin 1568ms linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

// Hide default DevExtreme spinner icon
.dx-loadindicator-content {
  display: none;
}
