@font-face {
  font-family: "SKODA Next Light";
  font-style: normal;
  font-weight: 300;
  src: url("SKODANext-Light.otf") format("opentype");
}
@font-face {
  font-family: "SKODA Next Regular";
  font-style: normal;
  font-weight: 400;
  src: url("SKODANext-Regular.otf") format("opentype");
}
@font-face {
  font-family: "SKODA Next Bold";
  font-style: normal;
  font-weight: 700;
  src: url("SKODANext-Bold.otf") format("opentype");
}
:root {
  --primary: rgb(65, 148, 104);
  --primary-filter: invert(53%) sepia(34%) saturate(562%) hue-rotate(96deg) brightness(87%) contrast(94%);
  --transparent: transparent;
  --secondary: rgb(120, 250, 174);
  --secondary-filter: invert(87%) sepia(10%) saturate(1865%) hue-rotate(83deg) brightness(101%) contrast(96%);
  --transparent: transparent;
  --tertiary: rgb(14, 58, 47);
  --tertiary-filter: invert(15%) sepia(59%) saturate(496%) hue-rotate(115deg) brightness(102%) contrast(95%);
  --transparent: transparent;
  --quarternary: rgb(232, 255, 241);
  --quarternary-filter: invert(97%) sepia(5%) saturate(1088%) hue-rotate(67deg) brightness(107%) contrast(101%);
  --transparent: transparent;
  --gray-100: rgb(216, 216, 216);
  --gray-100-filter: invert(100%) sepia(1%) saturate(2796%) hue-rotate(252deg) brightness(113%) contrast(69%);
  --transparent: transparent;
  --gray-200: rgb(196, 198, 199);
  --gray-200-filter: invert(88%) sepia(3%) saturate(116%) hue-rotate(155deg) brightness(89%) contrast(95%);
  --transparent: transparent;
  --gray-300: rgb(158, 159, 160);
  --gray-300-filter: invert(72%) sepia(5%) saturate(58%) hue-rotate(169deg) brightness(87%) contrast(90%);
  --transparent: transparent;
  --gray-500: rgb(124, 125, 126);
  --gray-500-filter: invert(52%) sepia(7%) saturate(58%) hue-rotate(169deg) brightness(93%) contrast(94%);
  --transparent: transparent;
  --gray-600: rgb(90, 91, 92);
  --gray-600-filter: invert(35%) sepia(6%) saturate(96%) hue-rotate(169deg) brightness(94%) contrast(89%);
  --transparent: transparent;
  --gray-700: rgb(70, 71, 72);
  --gray-700-filter: invert(27%) sepia(8%) saturate(93%) hue-rotate(169deg) brightness(96%) contrast(93%);
  --transparent: transparent;
  --gray-800: rgb(48, 49, 50);
  --gray-800-filter: invert(15%) sepia(7%) saturate(229%) hue-rotate(169deg) brightness(95%) contrast(86%);
  --transparent: transparent;
  --gray-900: rgb(22, 23, 24);
  --gray-900-filter: invert(1%) sepia(30%) saturate(545%) hue-rotate(169deg) brightness(94%) contrast(84%);
  --transparent: transparent;
  --white: rgb(255, 255, 255);
  --white-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(35deg) brightness(109%) contrast(103%);
  --transparent: transparent;
  --black: rgb(0, 0, 0);
  --black-filter: invert(15%) sepia(7%) saturate(229%) hue-rotate(169deg) brightness(0%) contrast(86%);
  --transparent: transparent;
  --danger: rgb(232, 43, 55);
  --danger-filter: invert(33%) sepia(52%) saturate(6647%) hue-rotate(341deg) brightness(92%) contrast(98%);
  --transparent: transparent;
  --danger-deadline: rgba(255, 122, 131, 0.689);
  --danger-deadline-filter: invert(33%) sepia(52%) saturate(6647%) hue-rotate(341deg) brightness(92%) contrast(98%);
  --transparent: transparent;
  --danger-deadline-secondary: rgba(255, 122, 131, 0.474);
  --danger-deadline-secondary-filter: invert(33%) sepia(52%) saturate(6647%) hue-rotate(341deg) brightness(92%) contrast(98%);
  --transparent: transparent;
  --warning: rgb(236, 101, 19);
  --warning-filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%);
  --transparent: transparent;
  --warning-light: rgba(236, 101, 19, 0.2);
  --warning-light-filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%);
  --transparent: transparent;
  --warning-secondary: rgba(236, 101, 19, 0.08);
  --warning-secondary-filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%);
  --transparent: transparent;
  --warning-deadline: rgba(255, 246, 126, 0.759);
  --warning-deadline-filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%);
  --transparent: transparent;
  --warning-deadline-secondary: rgba(255, 246, 126, 0.564);
  --warning-deadline-secondary-filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%);
  --transparent: transparent;
  --success: rgb(55, 208, 2);
  --success-filter: invert(69%) sepia(69%) saturate(4721%) hue-rotate(69deg) brightness(109%) contrast(98%);
  --transparent: transparent;
  --info: rgb(45, 113, 215);
  --info-filter: invert(53%) sepia(70%) saturate(5402%) hue-rotate(203deg) brightness(88%) contrast(91%);
  --transparent: transparent;
  --hover-primary: rgb(89, 188, 135);
  --hover-primary-filter: invert(65%) sepia(35%) saturate(495%) hue-rotate(95deg) brightness(93%) contrast(96%);
  --transparent: transparent;
  --hover-secondary: rgb(168, 255, 204);
  --hover-secondary-filter: invert(88%) sepia(20%) saturate(591%) hue-rotate(82deg) brightness(102%) contrast(101%);
  --transparent: transparent;
  --disabled-primary: rgb(196, 198, 199);
  --disabled-primary-filter: invert(84%) sepia(6%) saturate(66%) hue-rotate(155deg) brightness(94%) contrast(91%);
  --transparent: transparent;
  --disabled-secondary: rgb(216, 216, 216);
  --disabled-secondary-filter: invert(97%) sepia(0%) saturate(0%) hue-rotate(197deg) brightness(88%) contrast(97%);
  --transparent: transparent;
  --disabled-tertiary: rgb(158, 159, 160);
  --disabled-tertiary-filter: invert(68%) sepia(6%) saturate(55%) hue-rotate(169deg) brightness(93%) contrast(85%);
  --transparent: transparent;
  --transparent-primary: rgba(22, 23, 24, 0.06);
  --transparent-primary-filter: invert(99%) sepia(89%) saturate(62%) hue-rotate(280deg) brightness(115%) contrast(89%);
  --transparent: transparent;
  --transparent-secondary: rgba(22, 23, 24, 0.12);
  --transparent-secondary-filter: invert(99%) sepia(89%) saturate(62%) hue-rotate(280deg) brightness(115%) contrast(89%);
  --transparent: transparent;
  --transparent-tertiary: rgba(22, 23, 24, 0.05);
  --transparent-tertiary-filter: invert(99%) sepia(89%) saturate(62%) hue-rotate(280deg) brightness(115%) contrast(89%);
  --transparent: transparent;
  --transparent-backdrop: rgba(228, 228, 228, 0.8);
  --transparent-backdrop-filter: invert(99%) sepia(1%) saturate(1795%) hue-rotate(105deg) brightness(116%) contrast(79%);
  --transparent: transparent;
  --surface-primary: rgb(241, 241, 241);
  --surface-primary-filter: invert(99%) sepia(89%) saturate(62%) hue-rotate(280deg) brightness(115%) contrast(89%);
  --transparent: transparent;
  --surface-secondary: rgb(228, 228, 228);
  --surface-secondary-filter: invert(99%) sepia(1%) saturate(1795%) hue-rotate(105deg) brightness(116%) contrast(79%);
  --transparent: transparent;
  --surface-tertiary: rgb(255, 255, 255);
  --surface-tertiary-filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(35deg) brightness(109%) contrast(103%);
  --transparent: transparent;
  --surface-info: rgba(94, 149, 231, 0.16);
  --surface-info-filter: invert(1%) sepia(30%) saturate(545%) hue-rotate(169deg) brightness(94%) contrast(84%);
  --transparent: transparent;
  --danger-light: rgba(232, 43, 55, 0.37);
  --danger-light-filter: invert(33%) sepia(52%) saturate(6647%) hue-rotate(341deg) brightness(92%) contrast(98%);
  --transparent: transparent;
  --primary-light: rgba(65, 148, 104, 0.16);
  --primary-light-filter: invert(53%) sepia(34%) saturate(562%) hue-rotate(96deg) brightness(87%) contrast(94%);
  --transparent: transparent;
  --primary-lighter: rgba(65, 148, 104, 0.08);
  --primary-lighter-filter: invert(53%) sepia(34%) saturate(562%) hue-rotate(96deg) brightness(87%) contrast(94%);
  --transparent: transparent;
  --surface-info-light: rgba(94, 149, 231, 0.25);
  --surface-info-light-filter: invert(1%) sepia(30%) saturate(545%) hue-rotate(169deg) brightness(94%) contrast(84%);
  --transparent: transparent;
  --font-family-light: SKODA Next Light;
  --font-family-bold: SKODA Next Bold;
  --font-family-regular: SKODA Next Regular;
  --header-height-phone: 55px;
  --header-height-tablet: 55px;
  --header-height-desktop: 65px;
  --release-info-label-top: 0px;
  --border-color-light: var(--surface-secondary);
  --body-backgroud: var(--white);
}

[hidden] {
  display: none !important;
}

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (min-width: 1080px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
  }
}
@media (min-width: 720px) and (max-width: 1079px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
  }
}
@media (min-width: 360px) and (max-width: 719px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
  }
}
@media (min-width: 1080px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
}
@media (min-width: 720px) and (max-width: 1079px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
}
@media (min-width: 360px) and (max-width: 719px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
@media (min-width: 1080px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.3333333333% - 24px);
    width: calc(8.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-desktop {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6666666667% - 24px);
    width: calc(16.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-desktop {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-desktop {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-desktop {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6666666667% - 24px);
    width: calc(41.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-desktop {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-desktop {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333333333% - 24px);
    width: calc(58.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-desktop {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6666666667% - 24px);
    width: calc(66.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-desktop {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-desktop {
      width: auto;
      grid-column-end: span 9;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333333333% - 24px);
    width: calc(83.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-desktop {
      width: auto;
      grid-column-end: span 10;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6666666667% - 24px);
    width: calc(91.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-desktop {
      width: auto;
      grid-column-end: span 11;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-desktop {
      width: auto;
      grid-column-end: span 12;
    }
  }
}
@media (min-width: 720px) and (max-width: 1079px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-tablet {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-tablet {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-tablet {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-tablet {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-tablet {
      width: auto;
      grid-column-end: span 5;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 6;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-tablet {
      width: auto;
      grid-column-end: span 7;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
}
@media (min-width: 360px) and (max-width: 719px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
  .mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
    .mdc-layout-grid__cell--span-1-phone {
      width: auto;
      grid-column-end: span 1;
    }
  }
  .mdc-layout-grid__cell--span-2,
  .mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
    .mdc-layout-grid__cell--span-2-phone {
      width: auto;
      grid-column-end: span 2;
    }
  }
  .mdc-layout-grid__cell--span-3,
  .mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
    .mdc-layout-grid__cell--span-3-phone {
      width: auto;
      grid-column-end: span 3;
    }
  }
  .mdc-layout-grid__cell--span-4,
  .mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
    .mdc-layout-grid__cell--span-4-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-5,
  .mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
    .mdc-layout-grid__cell--span-5-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-6,
  .mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
    .mdc-layout-grid__cell--span-6-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-7,
  .mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
    .mdc-layout-grid__cell--span-7-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-8,
  .mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
    .mdc-layout-grid__cell--span-8-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-9,
  .mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
    .mdc-layout-grid__cell--span-9-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-10,
  .mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
    .mdc-layout-grid__cell--span-10-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-11,
  .mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
    .mdc-layout-grid__cell--span-11-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
  .mdc-layout-grid__cell--span-12,
  .mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
    .mdc-layout-grid__cell--span-12-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
}
.mdc-layout-grid__cell--order-1 {
  order: 1;
}

.mdc-layout-grid__cell--order-2 {
  order: 2;
}

.mdc-layout-grid__cell--order-3 {
  order: 3;
}

.mdc-layout-grid__cell--order-4 {
  order: 4;
}

.mdc-layout-grid__cell--order-5 {
  order: 5;
}

.mdc-layout-grid__cell--order-6 {
  order: 6;
}

.mdc-layout-grid__cell--order-7 {
  order: 7;
}

.mdc-layout-grid__cell--order-8 {
  order: 8;
}

.mdc-layout-grid__cell--order-9 {
  order: 9;
}

.mdc-layout-grid__cell--order-10 {
  order: 10;
}

.mdc-layout-grid__cell--order-11 {
  order: 11;
}

.mdc-layout-grid__cell--order-12 {
  order: 12;
}

.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}

@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}
.mdc-layout-grid__cell--align-middle {
  align-self: center;
}

.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}

@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}
@media (min-width: 1080px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc(var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2);
  }
}
@media (min-width: 720px) and (max-width: 1079px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc(var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2);
  }
}
@media (min-width: 360px) and (max-width: 719px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc(var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2);
  }
}
.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0;
}

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto;
}

.text-primary {
  color: var(--primary);
}

.bg-primary {
  background-color: var(--primary);
}

.text-secondary {
  color: var(--secondary);
}

.bg-secondary {
  background-color: var(--secondary);
}

.text-tertiary {
  color: var(--tertiary);
}

.bg-tertiary {
  background-color: var(--tertiary);
}

.text-quarternary {
  color: var(--quarternary);
}

.bg-quarternary {
  background-color: var(--quarternary);
}

.text-gray-100 {
  color: var(--gray-100);
}

.bg-gray-100 {
  background-color: var(--gray-100);
}

.text-gray-200 {
  color: var(--gray-200);
}

.bg-gray-200 {
  background-color: var(--gray-200);
}

.text-gray-300 {
  color: var(--gray-300);
}

.bg-gray-300 {
  background-color: var(--gray-300);
}

.text-gray-500 {
  color: var(--gray-500);
}

.bg-gray-500 {
  background-color: var(--gray-500);
}

.text-gray-600 {
  color: var(--gray-600);
}

.bg-gray-600 {
  background-color: var(--gray-600);
}

.text-gray-700 {
  color: var(--gray-700);
}

.bg-gray-700 {
  background-color: var(--gray-700);
}

.text-gray-800 {
  color: var(--gray-800);
}

.bg-gray-800 {
  background-color: var(--gray-800);
}

.text-gray-900 {
  color: var(--gray-900);
}

.bg-gray-900 {
  background-color: var(--gray-900);
}

.text-white {
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
}

.text-black {
  color: var(--black);
}

.bg-black {
  background-color: var(--black);
}

.text-danger {
  color: var(--danger);
}

.bg-danger {
  background-color: var(--danger);
}

.text-danger-deadline {
  color: var(--danger-deadline);
}

.bg-danger-deadline {
  background-color: var(--danger-deadline);
}

.text-danger-deadline-secondary {
  color: var(--danger-deadline-secondary);
}

.bg-danger-deadline-secondary {
  background-color: var(--danger-deadline-secondary);
}

.text-warning {
  color: var(--warning);
}

.bg-warning {
  background-color: var(--warning);
}

.text-warning-light {
  color: var(--warning-light);
}

.bg-warning-light {
  background-color: var(--warning-light);
}

.text-warning-secondary {
  color: var(--warning-secondary);
}

.bg-warning-secondary {
  background-color: var(--warning-secondary);
}

.text-warning-deadline {
  color: var(--warning-deadline);
}

.bg-warning-deadline {
  background-color: var(--warning-deadline);
}

.text-warning-deadline-secondary {
  color: var(--warning-deadline-secondary);
}

.bg-warning-deadline-secondary {
  background-color: var(--warning-deadline-secondary);
}

.text-success {
  color: var(--success);
}

.bg-success {
  background-color: var(--success);
}

.text-info {
  color: var(--info);
}

.bg-info {
  background-color: var(--info);
}

.text-hover-primary {
  color: var(--hover-primary);
}

.bg-hover-primary {
  background-color: var(--hover-primary);
}

.text-hover-secondary {
  color: var(--hover-secondary);
}

.bg-hover-secondary {
  background-color: var(--hover-secondary);
}

.text-disabled-primary {
  color: var(--disabled-primary);
}

.bg-disabled-primary {
  background-color: var(--disabled-primary);
}

.text-disabled-secondary {
  color: var(--disabled-secondary);
}

.bg-disabled-secondary {
  background-color: var(--disabled-secondary);
}

.text-disabled-tertiary {
  color: var(--disabled-tertiary);
}

.bg-disabled-tertiary {
  background-color: var(--disabled-tertiary);
}

.text-transparent-primary {
  color: var(--transparent-primary);
}

.bg-transparent-primary {
  background-color: var(--transparent-primary);
}

.text-transparent-secondary {
  color: var(--transparent-secondary);
}

.bg-transparent-secondary {
  background-color: var(--transparent-secondary);
}

.text-transparent-tertiary {
  color: var(--transparent-tertiary);
}

.bg-transparent-tertiary {
  background-color: var(--transparent-tertiary);
}

.text-transparent-backdrop {
  color: var(--transparent-backdrop);
}

.bg-transparent-backdrop {
  background-color: var(--transparent-backdrop);
}

.text-surface-primary {
  color: var(--surface-primary);
}

.bg-surface-primary {
  background-color: var(--surface-primary);
}

.text-surface-secondary {
  color: var(--surface-secondary);
}

.bg-surface-secondary {
  background-color: var(--surface-secondary);
}

.text-surface-tertiary {
  color: var(--surface-tertiary);
}

.bg-surface-tertiary {
  background-color: var(--surface-tertiary);
}

.text-surface-info {
  color: var(--surface-info);
}

.bg-surface-info {
  background-color: var(--surface-info);
}

.text-danger-light {
  color: var(--danger-light);
}

.bg-danger-light {
  background-color: var(--danger-light);
}

.text-primary-light {
  color: var(--primary-light);
}

.bg-primary-light {
  background-color: var(--primary-light);
}

.text-primary-lighter {
  color: var(--primary-lighter);
}

.bg-primary-lighter {
  background-color: var(--primary-lighter);
}

.text-surface-info-light {
  color: var(--surface-info-light);
}

.bg-surface-info-light {
  background-color: var(--surface-info-light);
}

@media (min-width: 360px) and (max-width: 719.9px) {
  h1,
  .h1 {
    font-size: 33px;
    line-height: 40px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h2,
  .h2 {
    font-size: 28px;
    line-height: 36px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h3,
  .h3 {
    font-size: 23px;
    line-height: 28px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h4,
  .h4 {
    font-size: 20px;
    line-height: 24px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h5,
  .h5 {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h6,
  .h6 {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .subheadline {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .disclaimer {
    font-size: 12px;
    line-height: 16px;
    font-family: var(--font-family-light);
    font-weight: 300;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .caption1 {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .caption2 {
    font-size: 12px;
    line-height: 16px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
}
@media (min-width: 720px) and (max-width: 1079.9px) {
  h1,
  .h1 {
    font-size: 38px;
    line-height: 48px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h2,
  .h2 {
    font-size: 32px;
    line-height: 38px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h3,
  .h3 {
    font-size: 27px;
    line-height: 32px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h4,
  .h4 {
    font-size: 23px;
    line-height: 28px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h5,
  .h5 {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h6,
  .h6 {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .subheadline {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .disclaimer {
    font-size: 12px;
    line-height: 16px;
    font-family: var(--font-family-light);
    font-weight: 300;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .caption1 {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .caption2 {
    font-size: 12px;
    line-height: 16px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
}
@media (min-width: 1080px) {
  h1,
  .h1 {
    font-size: 44px;
    line-height: 52px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h2,
  .h2 {
    font-size: 36px;
    line-height: 44px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h3,
  .h3 {
    font-size: 30px;
    line-height: 36px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h4,
  .h4 {
    font-size: 24px;
    line-height: 28px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h5,
  .h5 {
    font-size: 18px;
    line-height: 28px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  h6,
  .h6 {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .subheadline {
    font-size: 18px;
    line-height: 28px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .disclaimer {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-family-light);
    font-weight: 300;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .caption1 {
    font-size: 16px;
    line-height: 24px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
  .caption2 {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--font-family-bold);
    font-weight: 700;
    letter-spacing: 0.02em;
    margin: 0;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 360px) {
  .flex-phone-row {
    flex-direction: row !important;
  }
  .flex-phone-column {
    flex-direction: column !important;
  }
  .flex-phone-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-phone-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-phone-wrap {
    flex-wrap: wrap !important;
  }
  .flex-phone-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-phone-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-phone-fill {
    flex: 1 1 auto !important;
  }
  .flex-phone-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-phone-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-phone-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-phone-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-phone-start {
    justify-content: flex-start !important;
  }
  .justify-content-phone-end {
    justify-content: flex-end !important;
  }
  .justify-content-phone-center {
    justify-content: center !important;
  }
  .justify-content-phone-between {
    justify-content: space-between !important;
  }
  .justify-content-phone-around {
    justify-content: space-around !important;
  }
  .align-items-phone-start {
    align-items: flex-start !important;
  }
  .align-items-phone-end {
    align-items: flex-end !important;
  }
  .align-items-phone-center {
    align-items: center !important;
  }
  .align-items-phone-baseline {
    align-items: baseline !important;
  }
  .align-items-phone-stretch {
    align-items: stretch !important;
  }
  .align-content-phone-start {
    align-content: flex-start !important;
  }
  .align-content-phone-end {
    align-content: flex-end !important;
  }
  .align-content-phone-center {
    align-content: center !important;
  }
  .align-content-phone-between {
    align-content: space-between !important;
  }
  .align-content-phone-around {
    align-content: space-around !important;
  }
  .align-content-phone-stretch {
    align-content: stretch !important;
  }
  .align-self-phone-auto {
    align-self: auto !important;
  }
  .align-self-phone-start {
    align-self: flex-start !important;
  }
  .align-self-phone-end {
    align-self: flex-end !important;
  }
  .align-self-phone-center {
    align-self: center !important;
  }
  .align-self-phone-baseline {
    align-self: baseline !important;
  }
  .align-self-phone-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 720px) {
  .flex-tablet-row {
    flex-direction: row !important;
  }
  .flex-tablet-column {
    flex-direction: column !important;
  }
  .flex-tablet-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-tablet-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-tablet-wrap {
    flex-wrap: wrap !important;
  }
  .flex-tablet-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-tablet-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-tablet-fill {
    flex: 1 1 auto !important;
  }
  .flex-tablet-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-tablet-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-tablet-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-tablet-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-tablet-start {
    justify-content: flex-start !important;
  }
  .justify-content-tablet-end {
    justify-content: flex-end !important;
  }
  .justify-content-tablet-center {
    justify-content: center !important;
  }
  .justify-content-tablet-between {
    justify-content: space-between !important;
  }
  .justify-content-tablet-around {
    justify-content: space-around !important;
  }
  .align-items-tablet-start {
    align-items: flex-start !important;
  }
  .align-items-tablet-end {
    align-items: flex-end !important;
  }
  .align-items-tablet-center {
    align-items: center !important;
  }
  .align-items-tablet-baseline {
    align-items: baseline !important;
  }
  .align-items-tablet-stretch {
    align-items: stretch !important;
  }
  .align-content-tablet-start {
    align-content: flex-start !important;
  }
  .align-content-tablet-end {
    align-content: flex-end !important;
  }
  .align-content-tablet-center {
    align-content: center !important;
  }
  .align-content-tablet-between {
    align-content: space-between !important;
  }
  .align-content-tablet-around {
    align-content: space-around !important;
  }
  .align-content-tablet-stretch {
    align-content: stretch !important;
  }
  .align-self-tablet-auto {
    align-self: auto !important;
  }
  .align-self-tablet-start {
    align-self: flex-start !important;
  }
  .align-self-tablet-end {
    align-self: flex-end !important;
  }
  .align-self-tablet-center {
    align-self: center !important;
  }
  .align-self-tablet-baseline {
    align-self: baseline !important;
  }
  .align-self-tablet-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1080px) {
  .flex-desktop-row {
    flex-direction: row !important;
  }
  .flex-desktop-column {
    flex-direction: column !important;
  }
  .flex-desktop-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-desktop-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-desktop-wrap {
    flex-wrap: wrap !important;
  }
  .flex-desktop-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-desktop-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-desktop-fill {
    flex: 1 1 auto !important;
  }
  .flex-desktop-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-desktop-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-desktop-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-desktop-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-desktop-start {
    justify-content: flex-start !important;
  }
  .justify-content-desktop-end {
    justify-content: flex-end !important;
  }
  .justify-content-desktop-center {
    justify-content: center !important;
  }
  .justify-content-desktop-between {
    justify-content: space-between !important;
  }
  .justify-content-desktop-around {
    justify-content: space-around !important;
  }
  .align-items-desktop-start {
    align-items: flex-start !important;
  }
  .align-items-desktop-end {
    align-items: flex-end !important;
  }
  .align-items-desktop-center {
    align-items: center !important;
  }
  .align-items-desktop-baseline {
    align-items: baseline !important;
  }
  .align-items-desktop-stretch {
    align-items: stretch !important;
  }
  .align-content-desktop-start {
    align-content: flex-start !important;
  }
  .align-content-desktop-end {
    align-content: flex-end !important;
  }
  .align-content-desktop-center {
    align-content: center !important;
  }
  .align-content-desktop-between {
    align-content: space-between !important;
  }
  .align-content-desktop-around {
    align-content: space-around !important;
  }
  .align-content-desktop-stretch {
    align-content: stretch !important;
  }
  .align-self-desktop-auto {
    align-self: auto !important;
  }
  .align-self-desktop-start {
    align-self: flex-start !important;
  }
  .align-self-desktop-end {
    align-self: flex-end !important;
  }
  .align-self-desktop-center {
    align-self: center !important;
  }
  .align-self-desktop-baseline {
    align-self: baseline !important;
  }
  .align-self-desktop-stretch {
    align-self: stretch !important;
  }
}
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 360px) {
  .d-phone-none {
    display: none !important;
  }
  .d-phone-inline {
    display: inline !important;
  }
  .d-phone-inline-block {
    display: inline-block !important;
  }
  .d-phone-block {
    display: block !important;
  }
  .d-phone-table {
    display: table !important;
  }
  .d-phone-table-row {
    display: table-row !important;
  }
  .d-phone-table-cell {
    display: table-cell !important;
  }
  .d-phone-flex {
    display: flex !important;
  }
  .d-phone-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 720px) {
  .d-tablet-none {
    display: none !important;
  }
  .d-tablet-inline {
    display: inline !important;
  }
  .d-tablet-inline-block {
    display: inline-block !important;
  }
  .d-tablet-block {
    display: block !important;
  }
  .d-tablet-table {
    display: table !important;
  }
  .d-tablet-table-row {
    display: table-row !important;
  }
  .d-tablet-table-cell {
    display: table-cell !important;
  }
  .d-tablet-flex {
    display: flex !important;
  }
  .d-tablet-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1080px) {
  .d-desktop-none {
    display: none !important;
  }
  .d-desktop-inline {
    display: inline !important;
  }
  .d-desktop-inline-block {
    display: inline-block !important;
  }
  .d-desktop-block {
    display: block !important;
  }
  .d-desktop-table {
    display: table !important;
  }
  .d-desktop-table-row {
    display: table-row !important;
  }
  .d-desktop-table-cell {
    display: table-cell !important;
  }
  .d-desktop-flex {
    display: flex !important;
  }
  .d-desktop-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 360px) {
  .m-0 {
    margin: 0 !important;
  }
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  .me-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  .ms-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  .m-phone-0 {
    margin: 0 !important;
  }
  .mt-phone-0,
  .my-phone-0 {
    margin-top: 0 !important;
  }
  .me-phone-0,
  .mx-phone-0 {
    margin-right: 0 !important;
  }
  .mb-phone-0,
  .my-phone-0 {
    margin-bottom: 0 !important;
  }
  .ms-phone-0,
  .mx-phone-0 {
    margin-left: 0 !important;
  }
  .m-1 {
    margin: 0.125rem !important;
  }
  .mt-1,
  .my-1 {
    margin-top: 0.125rem !important;
  }
  .me-1,
  .mx-1 {
    margin-right: 0.125rem !important;
  }
  .mb-1,
  .my-1 {
    margin-bottom: 0.125rem !important;
  }
  .ms-1,
  .mx-1 {
    margin-left: 0.125rem !important;
  }
  .m-phone-1 {
    margin: 0.125rem !important;
  }
  .mt-phone-1,
  .my-phone-1 {
    margin-top: 0.125rem !important;
  }
  .me-phone-1,
  .mx-phone-1 {
    margin-right: 0.125rem !important;
  }
  .mb-phone-1,
  .my-phone-1 {
    margin-bottom: 0.125rem !important;
  }
  .ms-phone-1,
  .mx-phone-1 {
    margin-left: 0.125rem !important;
  }
  .m-2 {
    margin: 0.25rem !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 0.25rem !important;
  }
  .me-2,
  .mx-2 {
    margin-right: 0.25rem !important;
  }
  .mb-2,
  .my-2 {
    margin-bottom: 0.25rem !important;
  }
  .ms-2,
  .mx-2 {
    margin-left: 0.25rem !important;
  }
  .m-phone-2 {
    margin: 0.25rem !important;
  }
  .mt-phone-2,
  .my-phone-2 {
    margin-top: 0.25rem !important;
  }
  .me-phone-2,
  .mx-phone-2 {
    margin-right: 0.25rem !important;
  }
  .mb-phone-2,
  .my-phone-2 {
    margin-bottom: 0.25rem !important;
  }
  .ms-phone-2,
  .mx-phone-2 {
    margin-left: 0.25rem !important;
  }
  .m-3 {
    margin: 0.5rem !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 0.5rem !important;
  }
  .me-3,
  .mx-3 {
    margin-right: 0.5rem !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0.5rem !important;
  }
  .ms-3,
  .mx-3 {
    margin-left: 0.5rem !important;
  }
  .m-phone-3 {
    margin: 0.5rem !important;
  }
  .mt-phone-3,
  .my-phone-3 {
    margin-top: 0.5rem !important;
  }
  .me-phone-3,
  .mx-phone-3 {
    margin-right: 0.5rem !important;
  }
  .mb-phone-3,
  .my-phone-3 {
    margin-bottom: 0.5rem !important;
  }
  .ms-phone-3,
  .mx-phone-3 {
    margin-left: 0.5rem !important;
  }
  .m-4 {
    margin: 1rem !important;
  }
  .mt-4,
  .my-4 {
    margin-top: 1rem !important;
  }
  .me-4,
  .mx-4 {
    margin-right: 1rem !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 1rem !important;
  }
  .ms-4,
  .mx-4 {
    margin-left: 1rem !important;
  }
  .m-phone-4 {
    margin: 1rem !important;
  }
  .mt-phone-4,
  .my-phone-4 {
    margin-top: 1rem !important;
  }
  .me-phone-4,
  .mx-phone-4 {
    margin-right: 1rem !important;
  }
  .mb-phone-4,
  .my-phone-4 {
    margin-bottom: 1rem !important;
  }
  .ms-phone-4,
  .mx-phone-4 {
    margin-left: 1rem !important;
  }
  .m-5 {
    margin: 1.25rem !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 1.25rem !important;
  }
  .me-5,
  .mx-5 {
    margin-right: 1.25rem !important;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 1.25rem !important;
  }
  .ms-5,
  .mx-5 {
    margin-left: 1.25rem !important;
  }
  .m-phone-5 {
    margin: 1.25rem !important;
  }
  .mt-phone-5,
  .my-phone-5 {
    margin-top: 1.25rem !important;
  }
  .me-phone-5,
  .mx-phone-5 {
    margin-right: 1.25rem !important;
  }
  .mb-phone-5,
  .my-phone-5 {
    margin-bottom: 1.25rem !important;
  }
  .ms-phone-5,
  .mx-phone-5 {
    margin-left: 1.25rem !important;
  }
  .m-6 {
    margin: 1.5rem !important;
  }
  .mt-6,
  .my-6 {
    margin-top: 1.5rem !important;
  }
  .me-6,
  .mx-6 {
    margin-right: 1.5rem !important;
  }
  .mb-6,
  .my-6 {
    margin-bottom: 1.5rem !important;
  }
  .ms-6,
  .mx-6 {
    margin-left: 1.5rem !important;
  }
  .m-phone-6 {
    margin: 1.5rem !important;
  }
  .mt-phone-6,
  .my-phone-6 {
    margin-top: 1.5rem !important;
  }
  .me-phone-6,
  .mx-phone-6 {
    margin-right: 1.5rem !important;
  }
  .mb-phone-6,
  .my-phone-6 {
    margin-bottom: 1.5rem !important;
  }
  .ms-phone-6,
  .mx-phone-6 {
    margin-left: 1.5rem !important;
  }
  .m-7 {
    margin: 2rem !important;
  }
  .mt-7,
  .my-7 {
    margin-top: 2rem !important;
  }
  .me-7,
  .mx-7 {
    margin-right: 2rem !important;
  }
  .mb-7,
  .my-7 {
    margin-bottom: 2rem !important;
  }
  .ms-7,
  .mx-7 {
    margin-left: 2rem !important;
  }
  .m-phone-7 {
    margin: 2rem !important;
  }
  .mt-phone-7,
  .my-phone-7 {
    margin-top: 2rem !important;
  }
  .me-phone-7,
  .mx-phone-7 {
    margin-right: 2rem !important;
  }
  .mb-phone-7,
  .my-phone-7 {
    margin-bottom: 2rem !important;
  }
  .ms-phone-7,
  .mx-phone-7 {
    margin-left: 2rem !important;
  }
  .m-8 {
    margin: 2.5rem !important;
  }
  .mt-8,
  .my-8 {
    margin-top: 2.5rem !important;
  }
  .me-8,
  .mx-8 {
    margin-right: 2.5rem !important;
  }
  .mb-8,
  .my-8 {
    margin-bottom: 2.5rem !important;
  }
  .ms-8,
  .mx-8 {
    margin-left: 2.5rem !important;
  }
  .m-phone-8 {
    margin: 2.5rem !important;
  }
  .mt-phone-8,
  .my-phone-8 {
    margin-top: 2.5rem !important;
  }
  .me-phone-8,
  .mx-phone-8 {
    margin-right: 2.5rem !important;
  }
  .mb-phone-8,
  .my-phone-8 {
    margin-bottom: 2.5rem !important;
  }
  .ms-phone-8,
  .mx-phone-8 {
    margin-left: 2.5rem !important;
  }
  .m-9 {
    margin: 3rem !important;
  }
  .mt-9,
  .my-9 {
    margin-top: 3rem !important;
  }
  .me-9,
  .mx-9 {
    margin-right: 3rem !important;
  }
  .mb-9,
  .my-9 {
    margin-bottom: 3rem !important;
  }
  .ms-9,
  .mx-9 {
    margin-left: 3rem !important;
  }
  .m-phone-9 {
    margin: 3rem !important;
  }
  .mt-phone-9,
  .my-phone-9 {
    margin-top: 3rem !important;
  }
  .me-phone-9,
  .mx-phone-9 {
    margin-right: 3rem !important;
  }
  .mb-phone-9,
  .my-phone-9 {
    margin-bottom: 3rem !important;
  }
  .ms-phone-9,
  .mx-phone-9 {
    margin-left: 3rem !important;
  }
  .m-10 {
    margin: 4rem !important;
  }
  .mt-10,
  .my-10 {
    margin-top: 4rem !important;
  }
  .me-10,
  .mx-10 {
    margin-right: 4rem !important;
  }
  .mb-10,
  .my-10 {
    margin-bottom: 4rem !important;
  }
  .ms-10,
  .mx-10 {
    margin-left: 4rem !important;
  }
  .m-phone-10 {
    margin: 4rem !important;
  }
  .mt-phone-10,
  .my-phone-10 {
    margin-top: 4rem !important;
  }
  .me-phone-10,
  .mx-phone-10 {
    margin-right: 4rem !important;
  }
  .mb-phone-10,
  .my-phone-10 {
    margin-bottom: 4rem !important;
  }
  .ms-phone-10,
  .mx-phone-10 {
    margin-left: 4rem !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  .pe-0,
  .px-0 {
    padding-right: 0 !important;
  }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  .ps-0,
  .px-0 {
    padding-left: 0 !important;
  }
  .p-phone-0 {
    padding: 0 !important;
  }
  .pt-phone-0,
  .py-phone-0 {
    padding-top: 0 !important;
  }
  .pe-phone-0,
  .px-phone-0 {
    padding-right: 0 !important;
  }
  .pb-phone-0,
  .py-phone-0 {
    padding-bottom: 0 !important;
  }
  .ps-phone-0,
  .px-phone-0 {
    padding-left: 0 !important;
  }
  .p-1 {
    padding: 0.125rem !important;
  }
  .pt-1,
  .py-1 {
    padding-top: 0.125rem !important;
  }
  .pe-1,
  .px-1 {
    padding-right: 0.125rem !important;
  }
  .pb-1,
  .py-1 {
    padding-bottom: 0.125rem !important;
  }
  .ps-1,
  .px-1 {
    padding-left: 0.125rem !important;
  }
  .p-phone-1 {
    padding: 0.125rem !important;
  }
  .pt-phone-1,
  .py-phone-1 {
    padding-top: 0.125rem !important;
  }
  .pe-phone-1,
  .px-phone-1 {
    padding-right: 0.125rem !important;
  }
  .pb-phone-1,
  .py-phone-1 {
    padding-bottom: 0.125rem !important;
  }
  .ps-phone-1,
  .px-phone-1 {
    padding-left: 0.125rem !important;
  }
  .p-2 {
    padding: 0.25rem !important;
  }
  .pt-2,
  .py-2 {
    padding-top: 0.25rem !important;
  }
  .pe-2,
  .px-2 {
    padding-right: 0.25rem !important;
  }
  .pb-2,
  .py-2 {
    padding-bottom: 0.25rem !important;
  }
  .ps-2,
  .px-2 {
    padding-left: 0.25rem !important;
  }
  .p-phone-2 {
    padding: 0.25rem !important;
  }
  .pt-phone-2,
  .py-phone-2 {
    padding-top: 0.25rem !important;
  }
  .pe-phone-2,
  .px-phone-2 {
    padding-right: 0.25rem !important;
  }
  .pb-phone-2,
  .py-phone-2 {
    padding-bottom: 0.25rem !important;
  }
  .ps-phone-2,
  .px-phone-2 {
    padding-left: 0.25rem !important;
  }
  .p-3 {
    padding: 0.5rem !important;
  }
  .pt-3,
  .py-3 {
    padding-top: 0.5rem !important;
  }
  .pe-3,
  .px-3 {
    padding-right: 0.5rem !important;
  }
  .pb-3,
  .py-3 {
    padding-bottom: 0.5rem !important;
  }
  .ps-3,
  .px-3 {
    padding-left: 0.5rem !important;
  }
  .p-phone-3 {
    padding: 0.5rem !important;
  }
  .pt-phone-3,
  .py-phone-3 {
    padding-top: 0.5rem !important;
  }
  .pe-phone-3,
  .px-phone-3 {
    padding-right: 0.5rem !important;
  }
  .pb-phone-3,
  .py-phone-3 {
    padding-bottom: 0.5rem !important;
  }
  .ps-phone-3,
  .px-phone-3 {
    padding-left: 0.5rem !important;
  }
  .p-4 {
    padding: 1rem !important;
  }
  .pt-4,
  .py-4 {
    padding-top: 1rem !important;
  }
  .pe-4,
  .px-4 {
    padding-right: 1rem !important;
  }
  .pb-4,
  .py-4 {
    padding-bottom: 1rem !important;
  }
  .ps-4,
  .px-4 {
    padding-left: 1rem !important;
  }
  .p-phone-4 {
    padding: 1rem !important;
  }
  .pt-phone-4,
  .py-phone-4 {
    padding-top: 1rem !important;
  }
  .pe-phone-4,
  .px-phone-4 {
    padding-right: 1rem !important;
  }
  .pb-phone-4,
  .py-phone-4 {
    padding-bottom: 1rem !important;
  }
  .ps-phone-4,
  .px-phone-4 {
    padding-left: 1rem !important;
  }
  .p-5 {
    padding: 1.25rem !important;
  }
  .pt-5,
  .py-5 {
    padding-top: 1.25rem !important;
  }
  .pe-5,
  .px-5 {
    padding-right: 1.25rem !important;
  }
  .pb-5,
  .py-5 {
    padding-bottom: 1.25rem !important;
  }
  .ps-5,
  .px-5 {
    padding-left: 1.25rem !important;
  }
  .p-phone-5 {
    padding: 1.25rem !important;
  }
  .pt-phone-5,
  .py-phone-5 {
    padding-top: 1.25rem !important;
  }
  .pe-phone-5,
  .px-phone-5 {
    padding-right: 1.25rem !important;
  }
  .pb-phone-5,
  .py-phone-5 {
    padding-bottom: 1.25rem !important;
  }
  .ps-phone-5,
  .px-phone-5 {
    padding-left: 1.25rem !important;
  }
  .p-6 {
    padding: 1.5rem !important;
  }
  .pt-6,
  .py-6 {
    padding-top: 1.5rem !important;
  }
  .pe-6,
  .px-6 {
    padding-right: 1.5rem !important;
  }
  .pb-6,
  .py-6 {
    padding-bottom: 1.5rem !important;
  }
  .ps-6,
  .px-6 {
    padding-left: 1.5rem !important;
  }
  .p-phone-6 {
    padding: 1.5rem !important;
  }
  .pt-phone-6,
  .py-phone-6 {
    padding-top: 1.5rem !important;
  }
  .pe-phone-6,
  .px-phone-6 {
    padding-right: 1.5rem !important;
  }
  .pb-phone-6,
  .py-phone-6 {
    padding-bottom: 1.5rem !important;
  }
  .ps-phone-6,
  .px-phone-6 {
    padding-left: 1.5rem !important;
  }
  .p-7 {
    padding: 2rem !important;
  }
  .pt-7,
  .py-7 {
    padding-top: 2rem !important;
  }
  .pe-7,
  .px-7 {
    padding-right: 2rem !important;
  }
  .pb-7,
  .py-7 {
    padding-bottom: 2rem !important;
  }
  .ps-7,
  .px-7 {
    padding-left: 2rem !important;
  }
  .p-phone-7 {
    padding: 2rem !important;
  }
  .pt-phone-7,
  .py-phone-7 {
    padding-top: 2rem !important;
  }
  .pe-phone-7,
  .px-phone-7 {
    padding-right: 2rem !important;
  }
  .pb-phone-7,
  .py-phone-7 {
    padding-bottom: 2rem !important;
  }
  .ps-phone-7,
  .px-phone-7 {
    padding-left: 2rem !important;
  }
  .p-8 {
    padding: 2.5rem !important;
  }
  .pt-8,
  .py-8 {
    padding-top: 2.5rem !important;
  }
  .pe-8,
  .px-8 {
    padding-right: 2.5rem !important;
  }
  .pb-8,
  .py-8 {
    padding-bottom: 2.5rem !important;
  }
  .ps-8,
  .px-8 {
    padding-left: 2.5rem !important;
  }
  .p-phone-8 {
    padding: 2.5rem !important;
  }
  .pt-phone-8,
  .py-phone-8 {
    padding-top: 2.5rem !important;
  }
  .pe-phone-8,
  .px-phone-8 {
    padding-right: 2.5rem !important;
  }
  .pb-phone-8,
  .py-phone-8 {
    padding-bottom: 2.5rem !important;
  }
  .ps-phone-8,
  .px-phone-8 {
    padding-left: 2.5rem !important;
  }
  .p-9 {
    padding: 3rem !important;
  }
  .pt-9,
  .py-9 {
    padding-top: 3rem !important;
  }
  .pe-9,
  .px-9 {
    padding-right: 3rem !important;
  }
  .pb-9,
  .py-9 {
    padding-bottom: 3rem !important;
  }
  .ps-9,
  .px-9 {
    padding-left: 3rem !important;
  }
  .p-phone-9 {
    padding: 3rem !important;
  }
  .pt-phone-9,
  .py-phone-9 {
    padding-top: 3rem !important;
  }
  .pe-phone-9,
  .px-phone-9 {
    padding-right: 3rem !important;
  }
  .pb-phone-9,
  .py-phone-9 {
    padding-bottom: 3rem !important;
  }
  .ps-phone-9,
  .px-phone-9 {
    padding-left: 3rem !important;
  }
  .p-10 {
    padding: 4rem !important;
  }
  .pt-10,
  .py-10 {
    padding-top: 4rem !important;
  }
  .pe-10,
  .px-10 {
    padding-right: 4rem !important;
  }
  .pb-10,
  .py-10 {
    padding-bottom: 4rem !important;
  }
  .ps-10,
  .px-10 {
    padding-left: 4rem !important;
  }
  .p-phone-10 {
    padding: 4rem !important;
  }
  .pt-phone-10,
  .py-phone-10 {
    padding-top: 4rem !important;
  }
  .pe-phone-10,
  .px-phone-10 {
    padding-right: 4rem !important;
  }
  .pb-phone-10,
  .py-phone-10 {
    padding-bottom: 4rem !important;
  }
  .ps-phone-10,
  .px-phone-10 {
    padding-left: 4rem !important;
  }
  .m-n1 {
    margin: -0.125rem !important;
  }
  .mt-n1,
  .my-n1 {
    margin-top: -0.125rem !important;
  }
  .me-n1,
  .mx-n1 {
    margin-right: -0.125rem !important;
  }
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.125rem !important;
  }
  .ms-n1,
  .mx-n1 {
    margin-left: -0.125rem !important;
  }
  .m-phone-n1 {
    margin: -0.125rem !important;
  }
  .mt-phone-n1,
  .my-phone-n1 {
    margin-top: -0.125rem !important;
  }
  .me-phone-n1,
  .mx-phone-n1 {
    margin-right: -0.125rem !important;
  }
  .mb-phone-n1,
  .my-phone-n1 {
    margin-bottom: -0.125rem !important;
  }
  .ms-phone-n1,
  .mx-phone-n1 {
    margin-left: -0.125rem !important;
  }
  .m-n2 {
    margin: -0.25rem !important;
  }
  .mt-n2,
  .my-n2 {
    margin-top: -0.25rem !important;
  }
  .me-n2,
  .mx-n2 {
    margin-right: -0.25rem !important;
  }
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.25rem !important;
  }
  .ms-n2,
  .mx-n2 {
    margin-left: -0.25rem !important;
  }
  .m-phone-n2 {
    margin: -0.25rem !important;
  }
  .mt-phone-n2,
  .my-phone-n2 {
    margin-top: -0.25rem !important;
  }
  .me-phone-n2,
  .mx-phone-n2 {
    margin-right: -0.25rem !important;
  }
  .mb-phone-n2,
  .my-phone-n2 {
    margin-bottom: -0.25rem !important;
  }
  .ms-phone-n2,
  .mx-phone-n2 {
    margin-left: -0.25rem !important;
  }
  .m-n3 {
    margin: -0.5rem !important;
  }
  .mt-n3,
  .my-n3 {
    margin-top: -0.5rem !important;
  }
  .me-n3,
  .mx-n3 {
    margin-right: -0.5rem !important;
  }
  .mb-n3,
  .my-n3 {
    margin-bottom: -0.5rem !important;
  }
  .ms-n3,
  .mx-n3 {
    margin-left: -0.5rem !important;
  }
  .m-phone-n3 {
    margin: -0.5rem !important;
  }
  .mt-phone-n3,
  .my-phone-n3 {
    margin-top: -0.5rem !important;
  }
  .me-phone-n3,
  .mx-phone-n3 {
    margin-right: -0.5rem !important;
  }
  .mb-phone-n3,
  .my-phone-n3 {
    margin-bottom: -0.5rem !important;
  }
  .ms-phone-n3,
  .mx-phone-n3 {
    margin-left: -0.5rem !important;
  }
  .m-n4 {
    margin: -1rem !important;
  }
  .mt-n4,
  .my-n4 {
    margin-top: -1rem !important;
  }
  .me-n4,
  .mx-n4 {
    margin-right: -1rem !important;
  }
  .mb-n4,
  .my-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-n4,
  .mx-n4 {
    margin-left: -1rem !important;
  }
  .m-phone-n4 {
    margin: -1rem !important;
  }
  .mt-phone-n4,
  .my-phone-n4 {
    margin-top: -1rem !important;
  }
  .me-phone-n4,
  .mx-phone-n4 {
    margin-right: -1rem !important;
  }
  .mb-phone-n4,
  .my-phone-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-phone-n4,
  .mx-phone-n4 {
    margin-left: -1rem !important;
  }
  .m-n5 {
    margin: -1.25rem !important;
  }
  .mt-n5,
  .my-n5 {
    margin-top: -1.25rem !important;
  }
  .me-n5,
  .mx-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-n5,
  .my-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ms-n5,
  .mx-n5 {
    margin-left: -1.25rem !important;
  }
  .m-phone-n5 {
    margin: -1.25rem !important;
  }
  .mt-phone-n5,
  .my-phone-n5 {
    margin-top: -1.25rem !important;
  }
  .me-phone-n5,
  .mx-phone-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-phone-n5,
  .my-phone-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ms-phone-n5,
  .mx-phone-n5 {
    margin-left: -1.25rem !important;
  }
  .m-n6 {
    margin: -1.5rem !important;
  }
  .mt-n6,
  .my-n6 {
    margin-top: -1.5rem !important;
  }
  .me-n6,
  .mx-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-n6,
  .my-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ms-n6,
  .mx-n6 {
    margin-left: -1.5rem !important;
  }
  .m-phone-n6 {
    margin: -1.5rem !important;
  }
  .mt-phone-n6,
  .my-phone-n6 {
    margin-top: -1.5rem !important;
  }
  .me-phone-n6,
  .mx-phone-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-phone-n6,
  .my-phone-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ms-phone-n6,
  .mx-phone-n6 {
    margin-left: -1.5rem !important;
  }
  .m-n7 {
    margin: -2rem !important;
  }
  .mt-n7,
  .my-n7 {
    margin-top: -2rem !important;
  }
  .me-n7,
  .mx-n7 {
    margin-right: -2rem !important;
  }
  .mb-n7,
  .my-n7 {
    margin-bottom: -2rem !important;
  }
  .ms-n7,
  .mx-n7 {
    margin-left: -2rem !important;
  }
  .m-phone-n7 {
    margin: -2rem !important;
  }
  .mt-phone-n7,
  .my-phone-n7 {
    margin-top: -2rem !important;
  }
  .me-phone-n7,
  .mx-phone-n7 {
    margin-right: -2rem !important;
  }
  .mb-phone-n7,
  .my-phone-n7 {
    margin-bottom: -2rem !important;
  }
  .ms-phone-n7,
  .mx-phone-n7 {
    margin-left: -2rem !important;
  }
  .m-n8 {
    margin: -2.5rem !important;
  }
  .mt-n8,
  .my-n8 {
    margin-top: -2.5rem !important;
  }
  .me-n8,
  .mx-n8 {
    margin-right: -2.5rem !important;
  }
  .mb-n8,
  .my-n8 {
    margin-bottom: -2.5rem !important;
  }
  .ms-n8,
  .mx-n8 {
    margin-left: -2.5rem !important;
  }
  .m-phone-n8 {
    margin: -2.5rem !important;
  }
  .mt-phone-n8,
  .my-phone-n8 {
    margin-top: -2.5rem !important;
  }
  .me-phone-n8,
  .mx-phone-n8 {
    margin-right: -2.5rem !important;
  }
  .mb-phone-n8,
  .my-phone-n8 {
    margin-bottom: -2.5rem !important;
  }
  .ms-phone-n8,
  .mx-phone-n8 {
    margin-left: -2.5rem !important;
  }
  .m-n9 {
    margin: -3rem !important;
  }
  .mt-n9,
  .my-n9 {
    margin-top: -3rem !important;
  }
  .me-n9,
  .mx-n9 {
    margin-right: -3rem !important;
  }
  .mb-n9,
  .my-n9 {
    margin-bottom: -3rem !important;
  }
  .ms-n9,
  .mx-n9 {
    margin-left: -3rem !important;
  }
  .m-phone-n9 {
    margin: -3rem !important;
  }
  .mt-phone-n9,
  .my-phone-n9 {
    margin-top: -3rem !important;
  }
  .me-phone-n9,
  .mx-phone-n9 {
    margin-right: -3rem !important;
  }
  .mb-phone-n9,
  .my-phone-n9 {
    margin-bottom: -3rem !important;
  }
  .ms-phone-n9,
  .mx-phone-n9 {
    margin-left: -3rem !important;
  }
  .m-n10 {
    margin: -4rem !important;
  }
  .mt-n10,
  .my-n10 {
    margin-top: -4rem !important;
  }
  .me-n10,
  .mx-n10 {
    margin-right: -4rem !important;
  }
  .mb-n10,
  .my-n10 {
    margin-bottom: -4rem !important;
  }
  .ms-n10,
  .mx-n10 {
    margin-left: -4rem !important;
  }
  .m-phone-n10 {
    margin: -4rem !important;
  }
  .mt-phone-n10,
  .my-phone-n10 {
    margin-top: -4rem !important;
  }
  .me-phone-n10,
  .mx-phone-n10 {
    margin-right: -4rem !important;
  }
  .mb-phone-n10,
  .my-phone-n10 {
    margin-bottom: -4rem !important;
  }
  .ms-phone-n10,
  .mx-phone-n10 {
    margin-left: -4rem !important;
  }
  .m-phone-auto {
    margin: auto !important;
  }
  .mt-phone-auto,
  .my-phone-auto {
    margin-top: auto !important;
  }
  .me-phone-auto,
  .mx-phone-auto {
    margin-right: auto !important;
  }
  .mb-phone-auto,
  .my-phone-auto {
    margin-bottom: auto !important;
  }
  .ms-phone-auto,
  .mx-phone-auto {
    margin-left: auto !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  .me-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  .ms-auto,
  .mx-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 720px) {
  .m-0 {
    margin: 0 !important;
  }
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  .me-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  .ms-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  .m-tablet-0 {
    margin: 0 !important;
  }
  .mt-tablet-0,
  .my-tablet-0 {
    margin-top: 0 !important;
  }
  .me-tablet-0,
  .mx-tablet-0 {
    margin-right: 0 !important;
  }
  .mb-tablet-0,
  .my-tablet-0 {
    margin-bottom: 0 !important;
  }
  .ms-tablet-0,
  .mx-tablet-0 {
    margin-left: 0 !important;
  }
  .m-1 {
    margin: 0.125rem !important;
  }
  .mt-1,
  .my-1 {
    margin-top: 0.125rem !important;
  }
  .me-1,
  .mx-1 {
    margin-right: 0.125rem !important;
  }
  .mb-1,
  .my-1 {
    margin-bottom: 0.125rem !important;
  }
  .ms-1,
  .mx-1 {
    margin-left: 0.125rem !important;
  }
  .m-tablet-1 {
    margin: 0.125rem !important;
  }
  .mt-tablet-1,
  .my-tablet-1 {
    margin-top: 0.125rem !important;
  }
  .me-tablet-1,
  .mx-tablet-1 {
    margin-right: 0.125rem !important;
  }
  .mb-tablet-1,
  .my-tablet-1 {
    margin-bottom: 0.125rem !important;
  }
  .ms-tablet-1,
  .mx-tablet-1 {
    margin-left: 0.125rem !important;
  }
  .m-2 {
    margin: 0.25rem !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 0.25rem !important;
  }
  .me-2,
  .mx-2 {
    margin-right: 0.25rem !important;
  }
  .mb-2,
  .my-2 {
    margin-bottom: 0.25rem !important;
  }
  .ms-2,
  .mx-2 {
    margin-left: 0.25rem !important;
  }
  .m-tablet-2 {
    margin: 0.25rem !important;
  }
  .mt-tablet-2,
  .my-tablet-2 {
    margin-top: 0.25rem !important;
  }
  .me-tablet-2,
  .mx-tablet-2 {
    margin-right: 0.25rem !important;
  }
  .mb-tablet-2,
  .my-tablet-2 {
    margin-bottom: 0.25rem !important;
  }
  .ms-tablet-2,
  .mx-tablet-2 {
    margin-left: 0.25rem !important;
  }
  .m-3 {
    margin: 0.5rem !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 0.5rem !important;
  }
  .me-3,
  .mx-3 {
    margin-right: 0.5rem !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0.5rem !important;
  }
  .ms-3,
  .mx-3 {
    margin-left: 0.5rem !important;
  }
  .m-tablet-3 {
    margin: 0.5rem !important;
  }
  .mt-tablet-3,
  .my-tablet-3 {
    margin-top: 0.5rem !important;
  }
  .me-tablet-3,
  .mx-tablet-3 {
    margin-right: 0.5rem !important;
  }
  .mb-tablet-3,
  .my-tablet-3 {
    margin-bottom: 0.5rem !important;
  }
  .ms-tablet-3,
  .mx-tablet-3 {
    margin-left: 0.5rem !important;
  }
  .m-4 {
    margin: 1rem !important;
  }
  .mt-4,
  .my-4 {
    margin-top: 1rem !important;
  }
  .me-4,
  .mx-4 {
    margin-right: 1rem !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 1rem !important;
  }
  .ms-4,
  .mx-4 {
    margin-left: 1rem !important;
  }
  .m-tablet-4 {
    margin: 1rem !important;
  }
  .mt-tablet-4,
  .my-tablet-4 {
    margin-top: 1rem !important;
  }
  .me-tablet-4,
  .mx-tablet-4 {
    margin-right: 1rem !important;
  }
  .mb-tablet-4,
  .my-tablet-4 {
    margin-bottom: 1rem !important;
  }
  .ms-tablet-4,
  .mx-tablet-4 {
    margin-left: 1rem !important;
  }
  .m-5 {
    margin: 1.25rem !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 1.25rem !important;
  }
  .me-5,
  .mx-5 {
    margin-right: 1.25rem !important;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 1.25rem !important;
  }
  .ms-5,
  .mx-5 {
    margin-left: 1.25rem !important;
  }
  .m-tablet-5 {
    margin: 1.25rem !important;
  }
  .mt-tablet-5,
  .my-tablet-5 {
    margin-top: 1.25rem !important;
  }
  .me-tablet-5,
  .mx-tablet-5 {
    margin-right: 1.25rem !important;
  }
  .mb-tablet-5,
  .my-tablet-5 {
    margin-bottom: 1.25rem !important;
  }
  .ms-tablet-5,
  .mx-tablet-5 {
    margin-left: 1.25rem !important;
  }
  .m-6 {
    margin: 1.5rem !important;
  }
  .mt-6,
  .my-6 {
    margin-top: 1.5rem !important;
  }
  .me-6,
  .mx-6 {
    margin-right: 1.5rem !important;
  }
  .mb-6,
  .my-6 {
    margin-bottom: 1.5rem !important;
  }
  .ms-6,
  .mx-6 {
    margin-left: 1.5rem !important;
  }
  .m-tablet-6 {
    margin: 1.5rem !important;
  }
  .mt-tablet-6,
  .my-tablet-6 {
    margin-top: 1.5rem !important;
  }
  .me-tablet-6,
  .mx-tablet-6 {
    margin-right: 1.5rem !important;
  }
  .mb-tablet-6,
  .my-tablet-6 {
    margin-bottom: 1.5rem !important;
  }
  .ms-tablet-6,
  .mx-tablet-6 {
    margin-left: 1.5rem !important;
  }
  .m-7 {
    margin: 2rem !important;
  }
  .mt-7,
  .my-7 {
    margin-top: 2rem !important;
  }
  .me-7,
  .mx-7 {
    margin-right: 2rem !important;
  }
  .mb-7,
  .my-7 {
    margin-bottom: 2rem !important;
  }
  .ms-7,
  .mx-7 {
    margin-left: 2rem !important;
  }
  .m-tablet-7 {
    margin: 2rem !important;
  }
  .mt-tablet-7,
  .my-tablet-7 {
    margin-top: 2rem !important;
  }
  .me-tablet-7,
  .mx-tablet-7 {
    margin-right: 2rem !important;
  }
  .mb-tablet-7,
  .my-tablet-7 {
    margin-bottom: 2rem !important;
  }
  .ms-tablet-7,
  .mx-tablet-7 {
    margin-left: 2rem !important;
  }
  .m-8 {
    margin: 2.5rem !important;
  }
  .mt-8,
  .my-8 {
    margin-top: 2.5rem !important;
  }
  .me-8,
  .mx-8 {
    margin-right: 2.5rem !important;
  }
  .mb-8,
  .my-8 {
    margin-bottom: 2.5rem !important;
  }
  .ms-8,
  .mx-8 {
    margin-left: 2.5rem !important;
  }
  .m-tablet-8 {
    margin: 2.5rem !important;
  }
  .mt-tablet-8,
  .my-tablet-8 {
    margin-top: 2.5rem !important;
  }
  .me-tablet-8,
  .mx-tablet-8 {
    margin-right: 2.5rem !important;
  }
  .mb-tablet-8,
  .my-tablet-8 {
    margin-bottom: 2.5rem !important;
  }
  .ms-tablet-8,
  .mx-tablet-8 {
    margin-left: 2.5rem !important;
  }
  .m-9 {
    margin: 3rem !important;
  }
  .mt-9,
  .my-9 {
    margin-top: 3rem !important;
  }
  .me-9,
  .mx-9 {
    margin-right: 3rem !important;
  }
  .mb-9,
  .my-9 {
    margin-bottom: 3rem !important;
  }
  .ms-9,
  .mx-9 {
    margin-left: 3rem !important;
  }
  .m-tablet-9 {
    margin: 3rem !important;
  }
  .mt-tablet-9,
  .my-tablet-9 {
    margin-top: 3rem !important;
  }
  .me-tablet-9,
  .mx-tablet-9 {
    margin-right: 3rem !important;
  }
  .mb-tablet-9,
  .my-tablet-9 {
    margin-bottom: 3rem !important;
  }
  .ms-tablet-9,
  .mx-tablet-9 {
    margin-left: 3rem !important;
  }
  .m-10 {
    margin: 4rem !important;
  }
  .mt-10,
  .my-10 {
    margin-top: 4rem !important;
  }
  .me-10,
  .mx-10 {
    margin-right: 4rem !important;
  }
  .mb-10,
  .my-10 {
    margin-bottom: 4rem !important;
  }
  .ms-10,
  .mx-10 {
    margin-left: 4rem !important;
  }
  .m-tablet-10 {
    margin: 4rem !important;
  }
  .mt-tablet-10,
  .my-tablet-10 {
    margin-top: 4rem !important;
  }
  .me-tablet-10,
  .mx-tablet-10 {
    margin-right: 4rem !important;
  }
  .mb-tablet-10,
  .my-tablet-10 {
    margin-bottom: 4rem !important;
  }
  .ms-tablet-10,
  .mx-tablet-10 {
    margin-left: 4rem !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  .pe-0,
  .px-0 {
    padding-right: 0 !important;
  }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  .ps-0,
  .px-0 {
    padding-left: 0 !important;
  }
  .p-tablet-0 {
    padding: 0 !important;
  }
  .pt-tablet-0,
  .py-tablet-0 {
    padding-top: 0 !important;
  }
  .pe-tablet-0,
  .px-tablet-0 {
    padding-right: 0 !important;
  }
  .pb-tablet-0,
  .py-tablet-0 {
    padding-bottom: 0 !important;
  }
  .ps-tablet-0,
  .px-tablet-0 {
    padding-left: 0 !important;
  }
  .p-1 {
    padding: 0.125rem !important;
  }
  .pt-1,
  .py-1 {
    padding-top: 0.125rem !important;
  }
  .pe-1,
  .px-1 {
    padding-right: 0.125rem !important;
  }
  .pb-1,
  .py-1 {
    padding-bottom: 0.125rem !important;
  }
  .ps-1,
  .px-1 {
    padding-left: 0.125rem !important;
  }
  .p-tablet-1 {
    padding: 0.125rem !important;
  }
  .pt-tablet-1,
  .py-tablet-1 {
    padding-top: 0.125rem !important;
  }
  .pe-tablet-1,
  .px-tablet-1 {
    padding-right: 0.125rem !important;
  }
  .pb-tablet-1,
  .py-tablet-1 {
    padding-bottom: 0.125rem !important;
  }
  .ps-tablet-1,
  .px-tablet-1 {
    padding-left: 0.125rem !important;
  }
  .p-2 {
    padding: 0.25rem !important;
  }
  .pt-2,
  .py-2 {
    padding-top: 0.25rem !important;
  }
  .pe-2,
  .px-2 {
    padding-right: 0.25rem !important;
  }
  .pb-2,
  .py-2 {
    padding-bottom: 0.25rem !important;
  }
  .ps-2,
  .px-2 {
    padding-left: 0.25rem !important;
  }
  .p-tablet-2 {
    padding: 0.25rem !important;
  }
  .pt-tablet-2,
  .py-tablet-2 {
    padding-top: 0.25rem !important;
  }
  .pe-tablet-2,
  .px-tablet-2 {
    padding-right: 0.25rem !important;
  }
  .pb-tablet-2,
  .py-tablet-2 {
    padding-bottom: 0.25rem !important;
  }
  .ps-tablet-2,
  .px-tablet-2 {
    padding-left: 0.25rem !important;
  }
  .p-3 {
    padding: 0.5rem !important;
  }
  .pt-3,
  .py-3 {
    padding-top: 0.5rem !important;
  }
  .pe-3,
  .px-3 {
    padding-right: 0.5rem !important;
  }
  .pb-3,
  .py-3 {
    padding-bottom: 0.5rem !important;
  }
  .ps-3,
  .px-3 {
    padding-left: 0.5rem !important;
  }
  .p-tablet-3 {
    padding: 0.5rem !important;
  }
  .pt-tablet-3,
  .py-tablet-3 {
    padding-top: 0.5rem !important;
  }
  .pe-tablet-3,
  .px-tablet-3 {
    padding-right: 0.5rem !important;
  }
  .pb-tablet-3,
  .py-tablet-3 {
    padding-bottom: 0.5rem !important;
  }
  .ps-tablet-3,
  .px-tablet-3 {
    padding-left: 0.5rem !important;
  }
  .p-4 {
    padding: 1rem !important;
  }
  .pt-4,
  .py-4 {
    padding-top: 1rem !important;
  }
  .pe-4,
  .px-4 {
    padding-right: 1rem !important;
  }
  .pb-4,
  .py-4 {
    padding-bottom: 1rem !important;
  }
  .ps-4,
  .px-4 {
    padding-left: 1rem !important;
  }
  .p-tablet-4 {
    padding: 1rem !important;
  }
  .pt-tablet-4,
  .py-tablet-4 {
    padding-top: 1rem !important;
  }
  .pe-tablet-4,
  .px-tablet-4 {
    padding-right: 1rem !important;
  }
  .pb-tablet-4,
  .py-tablet-4 {
    padding-bottom: 1rem !important;
  }
  .ps-tablet-4,
  .px-tablet-4 {
    padding-left: 1rem !important;
  }
  .p-5 {
    padding: 1.25rem !important;
  }
  .pt-5,
  .py-5 {
    padding-top: 1.25rem !important;
  }
  .pe-5,
  .px-5 {
    padding-right: 1.25rem !important;
  }
  .pb-5,
  .py-5 {
    padding-bottom: 1.25rem !important;
  }
  .ps-5,
  .px-5 {
    padding-left: 1.25rem !important;
  }
  .p-tablet-5 {
    padding: 1.25rem !important;
  }
  .pt-tablet-5,
  .py-tablet-5 {
    padding-top: 1.25rem !important;
  }
  .pe-tablet-5,
  .px-tablet-5 {
    padding-right: 1.25rem !important;
  }
  .pb-tablet-5,
  .py-tablet-5 {
    padding-bottom: 1.25rem !important;
  }
  .ps-tablet-5,
  .px-tablet-5 {
    padding-left: 1.25rem !important;
  }
  .p-6 {
    padding: 1.5rem !important;
  }
  .pt-6,
  .py-6 {
    padding-top: 1.5rem !important;
  }
  .pe-6,
  .px-6 {
    padding-right: 1.5rem !important;
  }
  .pb-6,
  .py-6 {
    padding-bottom: 1.5rem !important;
  }
  .ps-6,
  .px-6 {
    padding-left: 1.5rem !important;
  }
  .p-tablet-6 {
    padding: 1.5rem !important;
  }
  .pt-tablet-6,
  .py-tablet-6 {
    padding-top: 1.5rem !important;
  }
  .pe-tablet-6,
  .px-tablet-6 {
    padding-right: 1.5rem !important;
  }
  .pb-tablet-6,
  .py-tablet-6 {
    padding-bottom: 1.5rem !important;
  }
  .ps-tablet-6,
  .px-tablet-6 {
    padding-left: 1.5rem !important;
  }
  .p-7 {
    padding: 2rem !important;
  }
  .pt-7,
  .py-7 {
    padding-top: 2rem !important;
  }
  .pe-7,
  .px-7 {
    padding-right: 2rem !important;
  }
  .pb-7,
  .py-7 {
    padding-bottom: 2rem !important;
  }
  .ps-7,
  .px-7 {
    padding-left: 2rem !important;
  }
  .p-tablet-7 {
    padding: 2rem !important;
  }
  .pt-tablet-7,
  .py-tablet-7 {
    padding-top: 2rem !important;
  }
  .pe-tablet-7,
  .px-tablet-7 {
    padding-right: 2rem !important;
  }
  .pb-tablet-7,
  .py-tablet-7 {
    padding-bottom: 2rem !important;
  }
  .ps-tablet-7,
  .px-tablet-7 {
    padding-left: 2rem !important;
  }
  .p-8 {
    padding: 2.5rem !important;
  }
  .pt-8,
  .py-8 {
    padding-top: 2.5rem !important;
  }
  .pe-8,
  .px-8 {
    padding-right: 2.5rem !important;
  }
  .pb-8,
  .py-8 {
    padding-bottom: 2.5rem !important;
  }
  .ps-8,
  .px-8 {
    padding-left: 2.5rem !important;
  }
  .p-tablet-8 {
    padding: 2.5rem !important;
  }
  .pt-tablet-8,
  .py-tablet-8 {
    padding-top: 2.5rem !important;
  }
  .pe-tablet-8,
  .px-tablet-8 {
    padding-right: 2.5rem !important;
  }
  .pb-tablet-8,
  .py-tablet-8 {
    padding-bottom: 2.5rem !important;
  }
  .ps-tablet-8,
  .px-tablet-8 {
    padding-left: 2.5rem !important;
  }
  .p-9 {
    padding: 3rem !important;
  }
  .pt-9,
  .py-9 {
    padding-top: 3rem !important;
  }
  .pe-9,
  .px-9 {
    padding-right: 3rem !important;
  }
  .pb-9,
  .py-9 {
    padding-bottom: 3rem !important;
  }
  .ps-9,
  .px-9 {
    padding-left: 3rem !important;
  }
  .p-tablet-9 {
    padding: 3rem !important;
  }
  .pt-tablet-9,
  .py-tablet-9 {
    padding-top: 3rem !important;
  }
  .pe-tablet-9,
  .px-tablet-9 {
    padding-right: 3rem !important;
  }
  .pb-tablet-9,
  .py-tablet-9 {
    padding-bottom: 3rem !important;
  }
  .ps-tablet-9,
  .px-tablet-9 {
    padding-left: 3rem !important;
  }
  .p-10 {
    padding: 4rem !important;
  }
  .pt-10,
  .py-10 {
    padding-top: 4rem !important;
  }
  .pe-10,
  .px-10 {
    padding-right: 4rem !important;
  }
  .pb-10,
  .py-10 {
    padding-bottom: 4rem !important;
  }
  .ps-10,
  .px-10 {
    padding-left: 4rem !important;
  }
  .p-tablet-10 {
    padding: 4rem !important;
  }
  .pt-tablet-10,
  .py-tablet-10 {
    padding-top: 4rem !important;
  }
  .pe-tablet-10,
  .px-tablet-10 {
    padding-right: 4rem !important;
  }
  .pb-tablet-10,
  .py-tablet-10 {
    padding-bottom: 4rem !important;
  }
  .ps-tablet-10,
  .px-tablet-10 {
    padding-left: 4rem !important;
  }
  .m-n1 {
    margin: -0.125rem !important;
  }
  .mt-n1,
  .my-n1 {
    margin-top: -0.125rem !important;
  }
  .me-n1,
  .mx-n1 {
    margin-right: -0.125rem !important;
  }
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.125rem !important;
  }
  .ms-n1,
  .mx-n1 {
    margin-left: -0.125rem !important;
  }
  .m-tablet-n1 {
    margin: -0.125rem !important;
  }
  .mt-tablet-n1,
  .my-tablet-n1 {
    margin-top: -0.125rem !important;
  }
  .me-tablet-n1,
  .mx-tablet-n1 {
    margin-right: -0.125rem !important;
  }
  .mb-tablet-n1,
  .my-tablet-n1 {
    margin-bottom: -0.125rem !important;
  }
  .ms-tablet-n1,
  .mx-tablet-n1 {
    margin-left: -0.125rem !important;
  }
  .m-n2 {
    margin: -0.25rem !important;
  }
  .mt-n2,
  .my-n2 {
    margin-top: -0.25rem !important;
  }
  .me-n2,
  .mx-n2 {
    margin-right: -0.25rem !important;
  }
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.25rem !important;
  }
  .ms-n2,
  .mx-n2 {
    margin-left: -0.25rem !important;
  }
  .m-tablet-n2 {
    margin: -0.25rem !important;
  }
  .mt-tablet-n2,
  .my-tablet-n2 {
    margin-top: -0.25rem !important;
  }
  .me-tablet-n2,
  .mx-tablet-n2 {
    margin-right: -0.25rem !important;
  }
  .mb-tablet-n2,
  .my-tablet-n2 {
    margin-bottom: -0.25rem !important;
  }
  .ms-tablet-n2,
  .mx-tablet-n2 {
    margin-left: -0.25rem !important;
  }
  .m-n3 {
    margin: -0.5rem !important;
  }
  .mt-n3,
  .my-n3 {
    margin-top: -0.5rem !important;
  }
  .me-n3,
  .mx-n3 {
    margin-right: -0.5rem !important;
  }
  .mb-n3,
  .my-n3 {
    margin-bottom: -0.5rem !important;
  }
  .ms-n3,
  .mx-n3 {
    margin-left: -0.5rem !important;
  }
  .m-tablet-n3 {
    margin: -0.5rem !important;
  }
  .mt-tablet-n3,
  .my-tablet-n3 {
    margin-top: -0.5rem !important;
  }
  .me-tablet-n3,
  .mx-tablet-n3 {
    margin-right: -0.5rem !important;
  }
  .mb-tablet-n3,
  .my-tablet-n3 {
    margin-bottom: -0.5rem !important;
  }
  .ms-tablet-n3,
  .mx-tablet-n3 {
    margin-left: -0.5rem !important;
  }
  .m-n4 {
    margin: -1rem !important;
  }
  .mt-n4,
  .my-n4 {
    margin-top: -1rem !important;
  }
  .me-n4,
  .mx-n4 {
    margin-right: -1rem !important;
  }
  .mb-n4,
  .my-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-n4,
  .mx-n4 {
    margin-left: -1rem !important;
  }
  .m-tablet-n4 {
    margin: -1rem !important;
  }
  .mt-tablet-n4,
  .my-tablet-n4 {
    margin-top: -1rem !important;
  }
  .me-tablet-n4,
  .mx-tablet-n4 {
    margin-right: -1rem !important;
  }
  .mb-tablet-n4,
  .my-tablet-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-tablet-n4,
  .mx-tablet-n4 {
    margin-left: -1rem !important;
  }
  .m-n5 {
    margin: -1.25rem !important;
  }
  .mt-n5,
  .my-n5 {
    margin-top: -1.25rem !important;
  }
  .me-n5,
  .mx-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-n5,
  .my-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ms-n5,
  .mx-n5 {
    margin-left: -1.25rem !important;
  }
  .m-tablet-n5 {
    margin: -1.25rem !important;
  }
  .mt-tablet-n5,
  .my-tablet-n5 {
    margin-top: -1.25rem !important;
  }
  .me-tablet-n5,
  .mx-tablet-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-tablet-n5,
  .my-tablet-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ms-tablet-n5,
  .mx-tablet-n5 {
    margin-left: -1.25rem !important;
  }
  .m-n6 {
    margin: -1.5rem !important;
  }
  .mt-n6,
  .my-n6 {
    margin-top: -1.5rem !important;
  }
  .me-n6,
  .mx-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-n6,
  .my-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ms-n6,
  .mx-n6 {
    margin-left: -1.5rem !important;
  }
  .m-tablet-n6 {
    margin: -1.5rem !important;
  }
  .mt-tablet-n6,
  .my-tablet-n6 {
    margin-top: -1.5rem !important;
  }
  .me-tablet-n6,
  .mx-tablet-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-tablet-n6,
  .my-tablet-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ms-tablet-n6,
  .mx-tablet-n6 {
    margin-left: -1.5rem !important;
  }
  .m-n7 {
    margin: -2rem !important;
  }
  .mt-n7,
  .my-n7 {
    margin-top: -2rem !important;
  }
  .me-n7,
  .mx-n7 {
    margin-right: -2rem !important;
  }
  .mb-n7,
  .my-n7 {
    margin-bottom: -2rem !important;
  }
  .ms-n7,
  .mx-n7 {
    margin-left: -2rem !important;
  }
  .m-tablet-n7 {
    margin: -2rem !important;
  }
  .mt-tablet-n7,
  .my-tablet-n7 {
    margin-top: -2rem !important;
  }
  .me-tablet-n7,
  .mx-tablet-n7 {
    margin-right: -2rem !important;
  }
  .mb-tablet-n7,
  .my-tablet-n7 {
    margin-bottom: -2rem !important;
  }
  .ms-tablet-n7,
  .mx-tablet-n7 {
    margin-left: -2rem !important;
  }
  .m-n8 {
    margin: -2.5rem !important;
  }
  .mt-n8,
  .my-n8 {
    margin-top: -2.5rem !important;
  }
  .me-n8,
  .mx-n8 {
    margin-right: -2.5rem !important;
  }
  .mb-n8,
  .my-n8 {
    margin-bottom: -2.5rem !important;
  }
  .ms-n8,
  .mx-n8 {
    margin-left: -2.5rem !important;
  }
  .m-tablet-n8 {
    margin: -2.5rem !important;
  }
  .mt-tablet-n8,
  .my-tablet-n8 {
    margin-top: -2.5rem !important;
  }
  .me-tablet-n8,
  .mx-tablet-n8 {
    margin-right: -2.5rem !important;
  }
  .mb-tablet-n8,
  .my-tablet-n8 {
    margin-bottom: -2.5rem !important;
  }
  .ms-tablet-n8,
  .mx-tablet-n8 {
    margin-left: -2.5rem !important;
  }
  .m-n9 {
    margin: -3rem !important;
  }
  .mt-n9,
  .my-n9 {
    margin-top: -3rem !important;
  }
  .me-n9,
  .mx-n9 {
    margin-right: -3rem !important;
  }
  .mb-n9,
  .my-n9 {
    margin-bottom: -3rem !important;
  }
  .ms-n9,
  .mx-n9 {
    margin-left: -3rem !important;
  }
  .m-tablet-n9 {
    margin: -3rem !important;
  }
  .mt-tablet-n9,
  .my-tablet-n9 {
    margin-top: -3rem !important;
  }
  .me-tablet-n9,
  .mx-tablet-n9 {
    margin-right: -3rem !important;
  }
  .mb-tablet-n9,
  .my-tablet-n9 {
    margin-bottom: -3rem !important;
  }
  .ms-tablet-n9,
  .mx-tablet-n9 {
    margin-left: -3rem !important;
  }
  .m-n10 {
    margin: -4rem !important;
  }
  .mt-n10,
  .my-n10 {
    margin-top: -4rem !important;
  }
  .me-n10,
  .mx-n10 {
    margin-right: -4rem !important;
  }
  .mb-n10,
  .my-n10 {
    margin-bottom: -4rem !important;
  }
  .ms-n10,
  .mx-n10 {
    margin-left: -4rem !important;
  }
  .m-tablet-n10 {
    margin: -4rem !important;
  }
  .mt-tablet-n10,
  .my-tablet-n10 {
    margin-top: -4rem !important;
  }
  .me-tablet-n10,
  .mx-tablet-n10 {
    margin-right: -4rem !important;
  }
  .mb-tablet-n10,
  .my-tablet-n10 {
    margin-bottom: -4rem !important;
  }
  .ms-tablet-n10,
  .mx-tablet-n10 {
    margin-left: -4rem !important;
  }
  .m-tablet-auto {
    margin: auto !important;
  }
  .mt-tablet-auto,
  .my-tablet-auto {
    margin-top: auto !important;
  }
  .me-tablet-auto,
  .mx-tablet-auto {
    margin-right: auto !important;
  }
  .mb-tablet-auto,
  .my-tablet-auto {
    margin-bottom: auto !important;
  }
  .ms-tablet-auto,
  .mx-tablet-auto {
    margin-left: auto !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  .me-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  .ms-auto,
  .mx-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1080px) {
  .m-0 {
    margin: 0 !important;
  }
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  .me-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  .ms-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  .m-desktop-0 {
    margin: 0 !important;
  }
  .mt-desktop-0,
  .my-desktop-0 {
    margin-top: 0 !important;
  }
  .me-desktop-0,
  .mx-desktop-0 {
    margin-right: 0 !important;
  }
  .mb-desktop-0,
  .my-desktop-0 {
    margin-bottom: 0 !important;
  }
  .ms-desktop-0,
  .mx-desktop-0 {
    margin-left: 0 !important;
  }
  .m-1 {
    margin: 0.125rem !important;
  }
  .mt-1,
  .my-1 {
    margin-top: 0.125rem !important;
  }
  .me-1,
  .mx-1 {
    margin-right: 0.125rem !important;
  }
  .mb-1,
  .my-1 {
    margin-bottom: 0.125rem !important;
  }
  .ms-1,
  .mx-1 {
    margin-left: 0.125rem !important;
  }
  .m-desktop-1 {
    margin: 0.125rem !important;
  }
  .mt-desktop-1,
  .my-desktop-1 {
    margin-top: 0.125rem !important;
  }
  .me-desktop-1,
  .mx-desktop-1 {
    margin-right: 0.125rem !important;
  }
  .mb-desktop-1,
  .my-desktop-1 {
    margin-bottom: 0.125rem !important;
  }
  .ms-desktop-1,
  .mx-desktop-1 {
    margin-left: 0.125rem !important;
  }
  .m-2 {
    margin: 0.25rem !important;
  }
  .mt-2,
  .my-2 {
    margin-top: 0.25rem !important;
  }
  .me-2,
  .mx-2 {
    margin-right: 0.25rem !important;
  }
  .mb-2,
  .my-2 {
    margin-bottom: 0.25rem !important;
  }
  .ms-2,
  .mx-2 {
    margin-left: 0.25rem !important;
  }
  .m-desktop-2 {
    margin: 0.25rem !important;
  }
  .mt-desktop-2,
  .my-desktop-2 {
    margin-top: 0.25rem !important;
  }
  .me-desktop-2,
  .mx-desktop-2 {
    margin-right: 0.25rem !important;
  }
  .mb-desktop-2,
  .my-desktop-2 {
    margin-bottom: 0.25rem !important;
  }
  .ms-desktop-2,
  .mx-desktop-2 {
    margin-left: 0.25rem !important;
  }
  .m-3 {
    margin: 0.5rem !important;
  }
  .mt-3,
  .my-3 {
    margin-top: 0.5rem !important;
  }
  .me-3,
  .mx-3 {
    margin-right: 0.5rem !important;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0.5rem !important;
  }
  .ms-3,
  .mx-3 {
    margin-left: 0.5rem !important;
  }
  .m-desktop-3 {
    margin: 0.5rem !important;
  }
  .mt-desktop-3,
  .my-desktop-3 {
    margin-top: 0.5rem !important;
  }
  .me-desktop-3,
  .mx-desktop-3 {
    margin-right: 0.5rem !important;
  }
  .mb-desktop-3,
  .my-desktop-3 {
    margin-bottom: 0.5rem !important;
  }
  .ms-desktop-3,
  .mx-desktop-3 {
    margin-left: 0.5rem !important;
  }
  .m-4 {
    margin: 1rem !important;
  }
  .mt-4,
  .my-4 {
    margin-top: 1rem !important;
  }
  .me-4,
  .mx-4 {
    margin-right: 1rem !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 1rem !important;
  }
  .ms-4,
  .mx-4 {
    margin-left: 1rem !important;
  }
  .m-desktop-4 {
    margin: 1rem !important;
  }
  .mt-desktop-4,
  .my-desktop-4 {
    margin-top: 1rem !important;
  }
  .me-desktop-4,
  .mx-desktop-4 {
    margin-right: 1rem !important;
  }
  .mb-desktop-4,
  .my-desktop-4 {
    margin-bottom: 1rem !important;
  }
  .ms-desktop-4,
  .mx-desktop-4 {
    margin-left: 1rem !important;
  }
  .m-5 {
    margin: 1.25rem !important;
  }
  .mt-5,
  .my-5 {
    margin-top: 1.25rem !important;
  }
  .me-5,
  .mx-5 {
    margin-right: 1.25rem !important;
  }
  .mb-5,
  .my-5 {
    margin-bottom: 1.25rem !important;
  }
  .ms-5,
  .mx-5 {
    margin-left: 1.25rem !important;
  }
  .m-desktop-5 {
    margin: 1.25rem !important;
  }
  .mt-desktop-5,
  .my-desktop-5 {
    margin-top: 1.25rem !important;
  }
  .me-desktop-5,
  .mx-desktop-5 {
    margin-right: 1.25rem !important;
  }
  .mb-desktop-5,
  .my-desktop-5 {
    margin-bottom: 1.25rem !important;
  }
  .ms-desktop-5,
  .mx-desktop-5 {
    margin-left: 1.25rem !important;
  }
  .m-6 {
    margin: 1.5rem !important;
  }
  .mt-6,
  .my-6 {
    margin-top: 1.5rem !important;
  }
  .me-6,
  .mx-6 {
    margin-right: 1.5rem !important;
  }
  .mb-6,
  .my-6 {
    margin-bottom: 1.5rem !important;
  }
  .ms-6,
  .mx-6 {
    margin-left: 1.5rem !important;
  }
  .m-desktop-6 {
    margin: 1.5rem !important;
  }
  .mt-desktop-6,
  .my-desktop-6 {
    margin-top: 1.5rem !important;
  }
  .me-desktop-6,
  .mx-desktop-6 {
    margin-right: 1.5rem !important;
  }
  .mb-desktop-6,
  .my-desktop-6 {
    margin-bottom: 1.5rem !important;
  }
  .ms-desktop-6,
  .mx-desktop-6 {
    margin-left: 1.5rem !important;
  }
  .m-7 {
    margin: 2rem !important;
  }
  .mt-7,
  .my-7 {
    margin-top: 2rem !important;
  }
  .me-7,
  .mx-7 {
    margin-right: 2rem !important;
  }
  .mb-7,
  .my-7 {
    margin-bottom: 2rem !important;
  }
  .ms-7,
  .mx-7 {
    margin-left: 2rem !important;
  }
  .m-desktop-7 {
    margin: 2rem !important;
  }
  .mt-desktop-7,
  .my-desktop-7 {
    margin-top: 2rem !important;
  }
  .me-desktop-7,
  .mx-desktop-7 {
    margin-right: 2rem !important;
  }
  .mb-desktop-7,
  .my-desktop-7 {
    margin-bottom: 2rem !important;
  }
  .ms-desktop-7,
  .mx-desktop-7 {
    margin-left: 2rem !important;
  }
  .m-8 {
    margin: 2.5rem !important;
  }
  .mt-8,
  .my-8 {
    margin-top: 2.5rem !important;
  }
  .me-8,
  .mx-8 {
    margin-right: 2.5rem !important;
  }
  .mb-8,
  .my-8 {
    margin-bottom: 2.5rem !important;
  }
  .ms-8,
  .mx-8 {
    margin-left: 2.5rem !important;
  }
  .m-desktop-8 {
    margin: 2.5rem !important;
  }
  .mt-desktop-8,
  .my-desktop-8 {
    margin-top: 2.5rem !important;
  }
  .me-desktop-8,
  .mx-desktop-8 {
    margin-right: 2.5rem !important;
  }
  .mb-desktop-8,
  .my-desktop-8 {
    margin-bottom: 2.5rem !important;
  }
  .ms-desktop-8,
  .mx-desktop-8 {
    margin-left: 2.5rem !important;
  }
  .m-9 {
    margin: 3rem !important;
  }
  .mt-9,
  .my-9 {
    margin-top: 3rem !important;
  }
  .me-9,
  .mx-9 {
    margin-right: 3rem !important;
  }
  .mb-9,
  .my-9 {
    margin-bottom: 3rem !important;
  }
  .ms-9,
  .mx-9 {
    margin-left: 3rem !important;
  }
  .m-desktop-9 {
    margin: 3rem !important;
  }
  .mt-desktop-9,
  .my-desktop-9 {
    margin-top: 3rem !important;
  }
  .me-desktop-9,
  .mx-desktop-9 {
    margin-right: 3rem !important;
  }
  .mb-desktop-9,
  .my-desktop-9 {
    margin-bottom: 3rem !important;
  }
  .ms-desktop-9,
  .mx-desktop-9 {
    margin-left: 3rem !important;
  }
  .m-10 {
    margin: 4rem !important;
  }
  .mt-10,
  .my-10 {
    margin-top: 4rem !important;
  }
  .me-10,
  .mx-10 {
    margin-right: 4rem !important;
  }
  .mb-10,
  .my-10 {
    margin-bottom: 4rem !important;
  }
  .ms-10,
  .mx-10 {
    margin-left: 4rem !important;
  }
  .m-desktop-10 {
    margin: 4rem !important;
  }
  .mt-desktop-10,
  .my-desktop-10 {
    margin-top: 4rem !important;
  }
  .me-desktop-10,
  .mx-desktop-10 {
    margin-right: 4rem !important;
  }
  .mb-desktop-10,
  .my-desktop-10 {
    margin-bottom: 4rem !important;
  }
  .ms-desktop-10,
  .mx-desktop-10 {
    margin-left: 4rem !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  .pe-0,
  .px-0 {
    padding-right: 0 !important;
  }
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  .ps-0,
  .px-0 {
    padding-left: 0 !important;
  }
  .p-desktop-0 {
    padding: 0 !important;
  }
  .pt-desktop-0,
  .py-desktop-0 {
    padding-top: 0 !important;
  }
  .pe-desktop-0,
  .px-desktop-0 {
    padding-right: 0 !important;
  }
  .pb-desktop-0,
  .py-desktop-0 {
    padding-bottom: 0 !important;
  }
  .ps-desktop-0,
  .px-desktop-0 {
    padding-left: 0 !important;
  }
  .p-1 {
    padding: 0.125rem !important;
  }
  .pt-1,
  .py-1 {
    padding-top: 0.125rem !important;
  }
  .pe-1,
  .px-1 {
    padding-right: 0.125rem !important;
  }
  .pb-1,
  .py-1 {
    padding-bottom: 0.125rem !important;
  }
  .ps-1,
  .px-1 {
    padding-left: 0.125rem !important;
  }
  .p-desktop-1 {
    padding: 0.125rem !important;
  }
  .pt-desktop-1,
  .py-desktop-1 {
    padding-top: 0.125rem !important;
  }
  .pe-desktop-1,
  .px-desktop-1 {
    padding-right: 0.125rem !important;
  }
  .pb-desktop-1,
  .py-desktop-1 {
    padding-bottom: 0.125rem !important;
  }
  .ps-desktop-1,
  .px-desktop-1 {
    padding-left: 0.125rem !important;
  }
  .p-2 {
    padding: 0.25rem !important;
  }
  .pt-2,
  .py-2 {
    padding-top: 0.25rem !important;
  }
  .pe-2,
  .px-2 {
    padding-right: 0.25rem !important;
  }
  .pb-2,
  .py-2 {
    padding-bottom: 0.25rem !important;
  }
  .ps-2,
  .px-2 {
    padding-left: 0.25rem !important;
  }
  .p-desktop-2 {
    padding: 0.25rem !important;
  }
  .pt-desktop-2,
  .py-desktop-2 {
    padding-top: 0.25rem !important;
  }
  .pe-desktop-2,
  .px-desktop-2 {
    padding-right: 0.25rem !important;
  }
  .pb-desktop-2,
  .py-desktop-2 {
    padding-bottom: 0.25rem !important;
  }
  .ps-desktop-2,
  .px-desktop-2 {
    padding-left: 0.25rem !important;
  }
  .p-3 {
    padding: 0.5rem !important;
  }
  .pt-3,
  .py-3 {
    padding-top: 0.5rem !important;
  }
  .pe-3,
  .px-3 {
    padding-right: 0.5rem !important;
  }
  .pb-3,
  .py-3 {
    padding-bottom: 0.5rem !important;
  }
  .ps-3,
  .px-3 {
    padding-left: 0.5rem !important;
  }
  .p-desktop-3 {
    padding: 0.5rem !important;
  }
  .pt-desktop-3,
  .py-desktop-3 {
    padding-top: 0.5rem !important;
  }
  .pe-desktop-3,
  .px-desktop-3 {
    padding-right: 0.5rem !important;
  }
  .pb-desktop-3,
  .py-desktop-3 {
    padding-bottom: 0.5rem !important;
  }
  .ps-desktop-3,
  .px-desktop-3 {
    padding-left: 0.5rem !important;
  }
  .p-4 {
    padding: 1rem !important;
  }
  .pt-4,
  .py-4 {
    padding-top: 1rem !important;
  }
  .pe-4,
  .px-4 {
    padding-right: 1rem !important;
  }
  .pb-4,
  .py-4 {
    padding-bottom: 1rem !important;
  }
  .ps-4,
  .px-4 {
    padding-left: 1rem !important;
  }
  .p-desktop-4 {
    padding: 1rem !important;
  }
  .pt-desktop-4,
  .py-desktop-4 {
    padding-top: 1rem !important;
  }
  .pe-desktop-4,
  .px-desktop-4 {
    padding-right: 1rem !important;
  }
  .pb-desktop-4,
  .py-desktop-4 {
    padding-bottom: 1rem !important;
  }
  .ps-desktop-4,
  .px-desktop-4 {
    padding-left: 1rem !important;
  }
  .p-5 {
    padding: 1.25rem !important;
  }
  .pt-5,
  .py-5 {
    padding-top: 1.25rem !important;
  }
  .pe-5,
  .px-5 {
    padding-right: 1.25rem !important;
  }
  .pb-5,
  .py-5 {
    padding-bottom: 1.25rem !important;
  }
  .ps-5,
  .px-5 {
    padding-left: 1.25rem !important;
  }
  .p-desktop-5 {
    padding: 1.25rem !important;
  }
  .pt-desktop-5,
  .py-desktop-5 {
    padding-top: 1.25rem !important;
  }
  .pe-desktop-5,
  .px-desktop-5 {
    padding-right: 1.25rem !important;
  }
  .pb-desktop-5,
  .py-desktop-5 {
    padding-bottom: 1.25rem !important;
  }
  .ps-desktop-5,
  .px-desktop-5 {
    padding-left: 1.25rem !important;
  }
  .p-6 {
    padding: 1.5rem !important;
  }
  .pt-6,
  .py-6 {
    padding-top: 1.5rem !important;
  }
  .pe-6,
  .px-6 {
    padding-right: 1.5rem !important;
  }
  .pb-6,
  .py-6 {
    padding-bottom: 1.5rem !important;
  }
  .ps-6,
  .px-6 {
    padding-left: 1.5rem !important;
  }
  .p-desktop-6 {
    padding: 1.5rem !important;
  }
  .pt-desktop-6,
  .py-desktop-6 {
    padding-top: 1.5rem !important;
  }
  .pe-desktop-6,
  .px-desktop-6 {
    padding-right: 1.5rem !important;
  }
  .pb-desktop-6,
  .py-desktop-6 {
    padding-bottom: 1.5rem !important;
  }
  .ps-desktop-6,
  .px-desktop-6 {
    padding-left: 1.5rem !important;
  }
  .p-7 {
    padding: 2rem !important;
  }
  .pt-7,
  .py-7 {
    padding-top: 2rem !important;
  }
  .pe-7,
  .px-7 {
    padding-right: 2rem !important;
  }
  .pb-7,
  .py-7 {
    padding-bottom: 2rem !important;
  }
  .ps-7,
  .px-7 {
    padding-left: 2rem !important;
  }
  .p-desktop-7 {
    padding: 2rem !important;
  }
  .pt-desktop-7,
  .py-desktop-7 {
    padding-top: 2rem !important;
  }
  .pe-desktop-7,
  .px-desktop-7 {
    padding-right: 2rem !important;
  }
  .pb-desktop-7,
  .py-desktop-7 {
    padding-bottom: 2rem !important;
  }
  .ps-desktop-7,
  .px-desktop-7 {
    padding-left: 2rem !important;
  }
  .p-8 {
    padding: 2.5rem !important;
  }
  .pt-8,
  .py-8 {
    padding-top: 2.5rem !important;
  }
  .pe-8,
  .px-8 {
    padding-right: 2.5rem !important;
  }
  .pb-8,
  .py-8 {
    padding-bottom: 2.5rem !important;
  }
  .ps-8,
  .px-8 {
    padding-left: 2.5rem !important;
  }
  .p-desktop-8 {
    padding: 2.5rem !important;
  }
  .pt-desktop-8,
  .py-desktop-8 {
    padding-top: 2.5rem !important;
  }
  .pe-desktop-8,
  .px-desktop-8 {
    padding-right: 2.5rem !important;
  }
  .pb-desktop-8,
  .py-desktop-8 {
    padding-bottom: 2.5rem !important;
  }
  .ps-desktop-8,
  .px-desktop-8 {
    padding-left: 2.5rem !important;
  }
  .p-9 {
    padding: 3rem !important;
  }
  .pt-9,
  .py-9 {
    padding-top: 3rem !important;
  }
  .pe-9,
  .px-9 {
    padding-right: 3rem !important;
  }
  .pb-9,
  .py-9 {
    padding-bottom: 3rem !important;
  }
  .ps-9,
  .px-9 {
    padding-left: 3rem !important;
  }
  .p-desktop-9 {
    padding: 3rem !important;
  }
  .pt-desktop-9,
  .py-desktop-9 {
    padding-top: 3rem !important;
  }
  .pe-desktop-9,
  .px-desktop-9 {
    padding-right: 3rem !important;
  }
  .pb-desktop-9,
  .py-desktop-9 {
    padding-bottom: 3rem !important;
  }
  .ps-desktop-9,
  .px-desktop-9 {
    padding-left: 3rem !important;
  }
  .p-10 {
    padding: 4rem !important;
  }
  .pt-10,
  .py-10 {
    padding-top: 4rem !important;
  }
  .pe-10,
  .px-10 {
    padding-right: 4rem !important;
  }
  .pb-10,
  .py-10 {
    padding-bottom: 4rem !important;
  }
  .ps-10,
  .px-10 {
    padding-left: 4rem !important;
  }
  .p-desktop-10 {
    padding: 4rem !important;
  }
  .pt-desktop-10,
  .py-desktop-10 {
    padding-top: 4rem !important;
  }
  .pe-desktop-10,
  .px-desktop-10 {
    padding-right: 4rem !important;
  }
  .pb-desktop-10,
  .py-desktop-10 {
    padding-bottom: 4rem !important;
  }
  .ps-desktop-10,
  .px-desktop-10 {
    padding-left: 4rem !important;
  }
  .m-n1 {
    margin: -0.125rem !important;
  }
  .mt-n1,
  .my-n1 {
    margin-top: -0.125rem !important;
  }
  .me-n1,
  .mx-n1 {
    margin-right: -0.125rem !important;
  }
  .mb-n1,
  .my-n1 {
    margin-bottom: -0.125rem !important;
  }
  .ms-n1,
  .mx-n1 {
    margin-left: -0.125rem !important;
  }
  .m-desktop-n1 {
    margin: -0.125rem !important;
  }
  .mt-desktop-n1,
  .my-desktop-n1 {
    margin-top: -0.125rem !important;
  }
  .me-desktop-n1,
  .mx-desktop-n1 {
    margin-right: -0.125rem !important;
  }
  .mb-desktop-n1,
  .my-desktop-n1 {
    margin-bottom: -0.125rem !important;
  }
  .ms-desktop-n1,
  .mx-desktop-n1 {
    margin-left: -0.125rem !important;
  }
  .m-n2 {
    margin: -0.25rem !important;
  }
  .mt-n2,
  .my-n2 {
    margin-top: -0.25rem !important;
  }
  .me-n2,
  .mx-n2 {
    margin-right: -0.25rem !important;
  }
  .mb-n2,
  .my-n2 {
    margin-bottom: -0.25rem !important;
  }
  .ms-n2,
  .mx-n2 {
    margin-left: -0.25rem !important;
  }
  .m-desktop-n2 {
    margin: -0.25rem !important;
  }
  .mt-desktop-n2,
  .my-desktop-n2 {
    margin-top: -0.25rem !important;
  }
  .me-desktop-n2,
  .mx-desktop-n2 {
    margin-right: -0.25rem !important;
  }
  .mb-desktop-n2,
  .my-desktop-n2 {
    margin-bottom: -0.25rem !important;
  }
  .ms-desktop-n2,
  .mx-desktop-n2 {
    margin-left: -0.25rem !important;
  }
  .m-n3 {
    margin: -0.5rem !important;
  }
  .mt-n3,
  .my-n3 {
    margin-top: -0.5rem !important;
  }
  .me-n3,
  .mx-n3 {
    margin-right: -0.5rem !important;
  }
  .mb-n3,
  .my-n3 {
    margin-bottom: -0.5rem !important;
  }
  .ms-n3,
  .mx-n3 {
    margin-left: -0.5rem !important;
  }
  .m-desktop-n3 {
    margin: -0.5rem !important;
  }
  .mt-desktop-n3,
  .my-desktop-n3 {
    margin-top: -0.5rem !important;
  }
  .me-desktop-n3,
  .mx-desktop-n3 {
    margin-right: -0.5rem !important;
  }
  .mb-desktop-n3,
  .my-desktop-n3 {
    margin-bottom: -0.5rem !important;
  }
  .ms-desktop-n3,
  .mx-desktop-n3 {
    margin-left: -0.5rem !important;
  }
  .m-n4 {
    margin: -1rem !important;
  }
  .mt-n4,
  .my-n4 {
    margin-top: -1rem !important;
  }
  .me-n4,
  .mx-n4 {
    margin-right: -1rem !important;
  }
  .mb-n4,
  .my-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-n4,
  .mx-n4 {
    margin-left: -1rem !important;
  }
  .m-desktop-n4 {
    margin: -1rem !important;
  }
  .mt-desktop-n4,
  .my-desktop-n4 {
    margin-top: -1rem !important;
  }
  .me-desktop-n4,
  .mx-desktop-n4 {
    margin-right: -1rem !important;
  }
  .mb-desktop-n4,
  .my-desktop-n4 {
    margin-bottom: -1rem !important;
  }
  .ms-desktop-n4,
  .mx-desktop-n4 {
    margin-left: -1rem !important;
  }
  .m-n5 {
    margin: -1.25rem !important;
  }
  .mt-n5,
  .my-n5 {
    margin-top: -1.25rem !important;
  }
  .me-n5,
  .mx-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-n5,
  .my-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ms-n5,
  .mx-n5 {
    margin-left: -1.25rem !important;
  }
  .m-desktop-n5 {
    margin: -1.25rem !important;
  }
  .mt-desktop-n5,
  .my-desktop-n5 {
    margin-top: -1.25rem !important;
  }
  .me-desktop-n5,
  .mx-desktop-n5 {
    margin-right: -1.25rem !important;
  }
  .mb-desktop-n5,
  .my-desktop-n5 {
    margin-bottom: -1.25rem !important;
  }
  .ms-desktop-n5,
  .mx-desktop-n5 {
    margin-left: -1.25rem !important;
  }
  .m-n6 {
    margin: -1.5rem !important;
  }
  .mt-n6,
  .my-n6 {
    margin-top: -1.5rem !important;
  }
  .me-n6,
  .mx-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-n6,
  .my-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ms-n6,
  .mx-n6 {
    margin-left: -1.5rem !important;
  }
  .m-desktop-n6 {
    margin: -1.5rem !important;
  }
  .mt-desktop-n6,
  .my-desktop-n6 {
    margin-top: -1.5rem !important;
  }
  .me-desktop-n6,
  .mx-desktop-n6 {
    margin-right: -1.5rem !important;
  }
  .mb-desktop-n6,
  .my-desktop-n6 {
    margin-bottom: -1.5rem !important;
  }
  .ms-desktop-n6,
  .mx-desktop-n6 {
    margin-left: -1.5rem !important;
  }
  .m-n7 {
    margin: -2rem !important;
  }
  .mt-n7,
  .my-n7 {
    margin-top: -2rem !important;
  }
  .me-n7,
  .mx-n7 {
    margin-right: -2rem !important;
  }
  .mb-n7,
  .my-n7 {
    margin-bottom: -2rem !important;
  }
  .ms-n7,
  .mx-n7 {
    margin-left: -2rem !important;
  }
  .m-desktop-n7 {
    margin: -2rem !important;
  }
  .mt-desktop-n7,
  .my-desktop-n7 {
    margin-top: -2rem !important;
  }
  .me-desktop-n7,
  .mx-desktop-n7 {
    margin-right: -2rem !important;
  }
  .mb-desktop-n7,
  .my-desktop-n7 {
    margin-bottom: -2rem !important;
  }
  .ms-desktop-n7,
  .mx-desktop-n7 {
    margin-left: -2rem !important;
  }
  .m-n8 {
    margin: -2.5rem !important;
  }
  .mt-n8,
  .my-n8 {
    margin-top: -2.5rem !important;
  }
  .me-n8,
  .mx-n8 {
    margin-right: -2.5rem !important;
  }
  .mb-n8,
  .my-n8 {
    margin-bottom: -2.5rem !important;
  }
  .ms-n8,
  .mx-n8 {
    margin-left: -2.5rem !important;
  }
  .m-desktop-n8 {
    margin: -2.5rem !important;
  }
  .mt-desktop-n8,
  .my-desktop-n8 {
    margin-top: -2.5rem !important;
  }
  .me-desktop-n8,
  .mx-desktop-n8 {
    margin-right: -2.5rem !important;
  }
  .mb-desktop-n8,
  .my-desktop-n8 {
    margin-bottom: -2.5rem !important;
  }
  .ms-desktop-n8,
  .mx-desktop-n8 {
    margin-left: -2.5rem !important;
  }
  .m-n9 {
    margin: -3rem !important;
  }
  .mt-n9,
  .my-n9 {
    margin-top: -3rem !important;
  }
  .me-n9,
  .mx-n9 {
    margin-right: -3rem !important;
  }
  .mb-n9,
  .my-n9 {
    margin-bottom: -3rem !important;
  }
  .ms-n9,
  .mx-n9 {
    margin-left: -3rem !important;
  }
  .m-desktop-n9 {
    margin: -3rem !important;
  }
  .mt-desktop-n9,
  .my-desktop-n9 {
    margin-top: -3rem !important;
  }
  .me-desktop-n9,
  .mx-desktop-n9 {
    margin-right: -3rem !important;
  }
  .mb-desktop-n9,
  .my-desktop-n9 {
    margin-bottom: -3rem !important;
  }
  .ms-desktop-n9,
  .mx-desktop-n9 {
    margin-left: -3rem !important;
  }
  .m-n10 {
    margin: -4rem !important;
  }
  .mt-n10,
  .my-n10 {
    margin-top: -4rem !important;
  }
  .me-n10,
  .mx-n10 {
    margin-right: -4rem !important;
  }
  .mb-n10,
  .my-n10 {
    margin-bottom: -4rem !important;
  }
  .ms-n10,
  .mx-n10 {
    margin-left: -4rem !important;
  }
  .m-desktop-n10 {
    margin: -4rem !important;
  }
  .mt-desktop-n10,
  .my-desktop-n10 {
    margin-top: -4rem !important;
  }
  .me-desktop-n10,
  .mx-desktop-n10 {
    margin-right: -4rem !important;
  }
  .mb-desktop-n10,
  .my-desktop-n10 {
    margin-bottom: -4rem !important;
  }
  .ms-desktop-n10,
  .mx-desktop-n10 {
    margin-left: -4rem !important;
  }
  .m-desktop-auto {
    margin: auto !important;
  }
  .mt-desktop-auto,
  .my-desktop-auto {
    margin-top: auto !important;
  }
  .me-desktop-auto,
  .mx-desktop-auto {
    margin-right: auto !important;
  }
  .mb-desktop-auto,
  .my-desktop-auto {
    margin-bottom: auto !important;
  }
  .ms-desktop-auto,
  .mx-desktop-auto {
    margin-left: auto !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  .me-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  .ms-auto,
  .mx-auto {
    margin-left: auto !important;
  }
}
.svg-icon-default {
  filter: var(--gray-900-filter);
}

.svg-icon-primary {
  filter: var(--primary-filter);
}

.svg-icon-secondary {
  filter: var(--secondary-filter);
}

.svg-icon-tertiary {
  filter: var(--tertiary-filter);
}

.svg-icon-info {
  filter: var(--info-filter);
}

.svg-icon-danger {
  filter: var(--danger-filter);
}

.svg-icon-white {
  filter: var(--white-filter);
}

.svg-icon-colorful {
  filter: transparent;
}

.svg-icon-warning {
  filter: var(--warning-filter);
}

.svg-icon-black {
  filter: var(--black-filter);
}

.svg-icon-small {
  width: 20px;
  height: 20px;
}

.svg-icon-medium {
  width: 24px;
  height: 24px;
}

.svg-icon-large {
  width: 32px;
  height: 32px;
}

.svg-icon-extralarge {
  width: 40px;
  height: 40px;
}

.text-nowrap {
  white-space: nowrap;
}

.font-bold {
  font-family: var(--font-family-bold);
}

.font-light {
  font-family: var(--font-family-light);
}

.font-regular {
  font-family: var(--font-family-regular);
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary);
}

.w-25 {
  width: 25% !important;
}

.mw-25 {
  max-width: 25% !important;
}

.h-25 {
  height: 25% !important;
}

.mh-25 {
  max-height: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.mw-50 {
  max-width: 50% !important;
}

.h-50 {
  height: 50% !important;
}

.mh-50 {
  max-height: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.h-75 {
  height: 75% !important;
}

.mh-75 {
  max-height: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-auto {
  max-width: auto !important;
}

.h-auto {
  height: auto !important;
}

.mh-auto {
  max-height: auto !important;
}

@media (min-width: 360px) {
  .w-phone-25 {
    width: 25% !important;
  }
  .mw-phone-25 {
    max-width: 25% !important;
  }
  .h-phone-25 {
    height: 25% !important;
  }
  .mh-phone-25 {
    max-height: 25% !important;
  }
  .w-phone-50 {
    width: 50% !important;
  }
  .mw-phone-50 {
    max-width: 50% !important;
  }
  .h-phone-50 {
    height: 50% !important;
  }
  .mh-phone-50 {
    max-height: 50% !important;
  }
  .w-phone-75 {
    width: 75% !important;
  }
  .mw-phone-75 {
    max-width: 75% !important;
  }
  .h-phone-75 {
    height: 75% !important;
  }
  .mh-phone-75 {
    max-height: 75% !important;
  }
  .w-phone-100 {
    width: 100% !important;
  }
  .mw-phone-100 {
    max-width: 100% !important;
  }
  .h-phone-100 {
    height: 100% !important;
  }
  .mh-phone-100 {
    max-height: 100% !important;
  }
  .w-phone-auto {
    width: auto !important;
  }
  .mw-phone-auto {
    max-width: auto !important;
  }
  .h-phone-auto {
    height: auto !important;
  }
  .mh-phone-auto {
    max-height: auto !important;
  }
}
@media (min-width: 720px) {
  .w-tablet-25 {
    width: 25% !important;
  }
  .mw-tablet-25 {
    max-width: 25% !important;
  }
  .h-tablet-25 {
    height: 25% !important;
  }
  .mh-tablet-25 {
    max-height: 25% !important;
  }
  .w-tablet-50 {
    width: 50% !important;
  }
  .mw-tablet-50 {
    max-width: 50% !important;
  }
  .h-tablet-50 {
    height: 50% !important;
  }
  .mh-tablet-50 {
    max-height: 50% !important;
  }
  .w-tablet-75 {
    width: 75% !important;
  }
  .mw-tablet-75 {
    max-width: 75% !important;
  }
  .h-tablet-75 {
    height: 75% !important;
  }
  .mh-tablet-75 {
    max-height: 75% !important;
  }
  .w-tablet-100 {
    width: 100% !important;
  }
  .mw-tablet-100 {
    max-width: 100% !important;
  }
  .h-tablet-100 {
    height: 100% !important;
  }
  .mh-tablet-100 {
    max-height: 100% !important;
  }
  .w-tablet-auto {
    width: auto !important;
  }
  .mw-tablet-auto {
    max-width: auto !important;
  }
  .h-tablet-auto {
    height: auto !important;
  }
  .mh-tablet-auto {
    max-height: auto !important;
  }
}
@media (min-width: 1080px) {
  .w-desktop-25 {
    width: 25% !important;
  }
  .mw-desktop-25 {
    max-width: 25% !important;
  }
  .h-desktop-25 {
    height: 25% !important;
  }
  .mh-desktop-25 {
    max-height: 25% !important;
  }
  .w-desktop-50 {
    width: 50% !important;
  }
  .mw-desktop-50 {
    max-width: 50% !important;
  }
  .h-desktop-50 {
    height: 50% !important;
  }
  .mh-desktop-50 {
    max-height: 50% !important;
  }
  .w-desktop-75 {
    width: 75% !important;
  }
  .mw-desktop-75 {
    max-width: 75% !important;
  }
  .h-desktop-75 {
    height: 75% !important;
  }
  .mh-desktop-75 {
    max-height: 75% !important;
  }
  .w-desktop-100 {
    width: 100% !important;
  }
  .mw-desktop-100 {
    max-width: 100% !important;
  }
  .h-desktop-100 {
    height: 100% !important;
  }
  .mh-desktop-100 {
    max-height: 100% !important;
  }
  .w-desktop-auto {
    width: auto !important;
  }
  .mw-desktop-auto {
    max-width: auto !important;
  }
  .h-desktop-auto {
    height: auto !important;
  }
  .mh-desktop-auto {
    max-height: auto !important;
  }
}
.badge-primary {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  border-radius: 50px;
  min-width: 24px;
  box-sizing: border-box;
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  background-color: var(--secondary);
  color: var(--gray-900);
}

.badge-info {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  border-radius: 50px;
  min-width: 24px;
  box-sizing: border-box;
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  background-color: var(--info);
  color: var(--white);
}

.badge-green {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  border-radius: 50px;
  min-width: 24px;
  box-sizing: border-box;
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  background-color: var(--primary);
  color: var(--white);
}

.badge-disabled {
  text-align: center;
  vertical-align: middle;
  padding: 4px;
  border-radius: 50px;
  min-width: 24px;
  box-sizing: border-box;
  font-family: var(--font-family-bold);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  background-color: var(--disabled-primary);
  color: var(--white);
}

.border-light {
  border: 1px solid var(--border-color-light);
}

.border-top-light {
  border-top: 1px solid var(--border-color-light);
}

.border-bottom-light {
  border-bottom: 1px solid var(--border-color-light);
}

.border-right-light {
  border-right: 1px solid var(--border-color-light);
}

.border-left-light {
  border-left: 1px solid var(--border-color-light);
}

.pointer-event-none {
  pointer-events: none;
}

.pointer-event-auto {
  pointer-events: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

body {
  margin: 0;
  font-family: var(--font-family-light);
  font-weight: 300;
  color: var(--gray-900);
  background-color: var(--white);
  letter-spacing: 0.02em;
  overflow-y: hidden;
}

@media (min-width: 360px) and (max-width: 719.9px) {
  body {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 720px) and (max-width: 1079.9px) {
  body {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 1080px) {
  body {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (min-width: 1080px) {
  .mdc-layout-grid__inner {
    grid-gap: 12px 24px;
  }
}
@media only screen and (max-width: 360px) {
  body {
    overflow-y: auto;
  }
}
.environment-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.environment-info.environment-info-top-label {
  height: 30px;
  background-color: var(--warning);
  color: var(--white);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 97;
}
.environment-info.environment-info-label {
  height: 54px;
  background-color: var(--warning);
  color: var(--white);
}

.header-breadcrumb {
  margin: 0 8px;
}

.header-breadcrumb-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
}

.breadcrumb-item {
  white-space: nowrap;
  padding-left: 0 !important;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 6px;
}
.breadcrumb-item:after {
  content: "/";
  font-family: var(--font-family-regular);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: var(--gray-900);
  display: flex;
  justify-content: center;
  flex: 1;
  margin-left: 12px;
}
.breadcrumb-item:before {
  display: none;
}
.breadcrumb-item:last-child:after {
  display: none;
}

.breadcrumbs-full {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.breadcrumbs-compact {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.breadcrumb-item-text {
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: var(--gray-900);
  display: flex;
  justify-content: center;
  flex: 1;
}

@media (max-width: 1079.9px) {
  .header-breadcrumb {
    display: none;
  }
  .breadcrumbs-compact {
    display: flex;
  }
  .breadcrumbs-full {
    display: none;
  }
}
@media (min-width: 1080px) {
  .breadcrumbs-full {
    display: flex;
  }
  .breadcrumbs-compact {
    display: none;
  }
}
.drawer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.25);
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
}
.drawer__form {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
}
.drawer__content {
  flex-grow: 1;
  overflow: hidden;
  padding: 0px 19px 19px 19px;
}
.drawer__header {
  padding: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.drawer.drawer-right {
  left: auto;
}
.drawer.drawer-right.drawer-on {
  transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  right: 0;
}
.drawer__footer {
  border-top: 1px solid var(--border-color-light);
  height: max-content;
  padding: 19px;
}
.drawer__footer .footer-actions > *:not(:last-child) {
  margin-right: 20px;
}

.drawer-overlay {
  background-color: var(--transparent-backdrop);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  animation: animation-offcanvas-fade-in 0.6s ease 1;
}

@media (min-width: 360px) and (max-width: 719.9px) {
  .drawer {
    width: var(--pps-drawer--width-phone);
  }
  .drawer.drawer-right {
    right: calc(var(--pps-drawer--width-phone) * -1);
  }
  .drawer__form {
    width: var(--pps-drawer--width-phone);
  }
}
@media (min-width: 720px) and (max-width: 1079.9px) {
  .drawer {
    width: var(--pps-drawer--width-tablet);
  }
  .drawer.drawer-right {
    right: calc(var(--pps-drawer--width-tablet) * -1);
  }
  .drawer__form {
    width: var(--pps-drawer--width-tablet);
  }
}
@media (min-width: 1080px) {
  .drawer {
    width: var(--pps-drawer--width-desktop);
  }
  .drawer.drawer-right {
    right: calc(var(--pps-drawer--width-desktop) * -1);
  }
  .drawer__form {
    width: var(--pps-drawer--width-desktop);
  }
}
.pps-tooltip {
  position: relative;
}
.pps-tooltip:before, .pps-tooltip:after {
  position: absolute;
  left: calc(100% + 8px);
  opacity: 0;
  pointer-events: none;
  z-index: 100;
  bottom: -2px;
  transition: opacity 400ms;
}
.pps-tooltip:after {
  background: #616161;
  border-radius: 6px;
  box-shadow: none;
  color: var(--white);
  content: attr(data-pps-tooltip);
  font-size: 0.75rem;
  line-height: 1;
  padding: 6px 8px;
  max-width: 320px;
  min-width: 34px;
  text-align: center;
  text-transform: none;
  white-space: pre;
}
.pps-tooltip:hover:before, .pps-tooltip:hover:after, .pps-tooltip:focus:before, .pps-tooltip:focus:after {
  opacity: 1;
}

.main-menu-wrapper {
  height: 100%;
  overflow-x: hidden;
}

.main-menu {
  background-color: var(--surface-primary);
  width: 270px;
  flex: 0 0 auto;
  flex-direction: column !important;
  display: flex !important;
  border-top: 1px solid var(--surface-secondary);
  border-right: 1px solid var(--surface-secondary);
}

.minimalize-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 16px 16px 16px 11px;
  height: 56px;
  box-sizing: border-box;
  border-top: 1px solid var(--surface-secondary);
}
.minimalize-wrapper dx-button {
  border: none !important;
  padding: 0;
  min-width: 24px !important;
}
.minimalize-wrapper .minimalize-icon {
  width: 24px;
  height: 24px;
}
.minimalize-wrapper .minimalize-text > span {
  font-family: var(--font-family-regular);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.menu-minimize:not(.menu-minimize-hover) .minimalize-wrapper {
  padding: 0;
  justify-content: center;
}
.menu-minimize .minimalize-wrapper .minimalize-icon {
  transform: rotate(180deg);
}
.menu-minimize.menu-minimize-hover .minimalize-wrapper .minimalize-icon {
  transform: rotate(180deg);
}
.minimalize-wrapper .minimalize-text {
  display: flex;
  justify-content: flex-begin;
}
.menu-minimize:not(.menu-minimize-hover) .minimalize-wrapper .minimalize-text {
  display: none;
}

@media (min-width: 1080px) {
  .main-menu {
    position: fixed;
    top: calc(65px + 30px + var(--release-info-label-top));
    bottom: 0;
    left: 0;
    z-index: 100;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu {
    width: 56px;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav {
    padding: 8px 0;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav > .menu-item > .menu-link {
    padding: 16px 16px 16px 11px;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav > .menu-item > .menu-link .menu-icon {
    flex: 0 0 0;
    width: 100%;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav > .menu-item > .menu-link .menu-text {
    display: none;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav > .menu-item > .menu-link .menu-arrow {
    display: none;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav > .menu-item > .menu-link .menu-badge {
    height: 0px;
    min-width: 0px;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav > .menu-item > .menu-link .menu-badge .badge-text {
    display: none;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav > .menu-item > .menu-submenu {
    display: none !important;
  }
  .menu-minimize:not(.menu-minimize-hover) .main-menu .menu-nav > .menu-item.menu-item-here > .menu-arrow {
    display: inline-block;
  }
  .main-menu .menu-minimize.menu-minimize-hover {
    width: 270px;
  }
}
@media (max-width: 1079.9px) {
  .main-menu {
    border-top: 0px;
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -270px;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
  }
  .main-menu.main-menu-on {
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    left: 0;
  }
  .minimalize-wrapper {
    display: none;
  }
}
.menu-nav {
  margin: 0;
  list-style: none;
  padding: 8px 0;
}
.menu-nav .menu-scroll {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.menu-nav .menu-subnav {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  margin: 0;
  list-style: none !important;
}
.menu-nav .menu-submenu {
  display: none;
  float: none;
  margin: 0;
  padding: 0;
}
.menu-nav .menu-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  float: none;
  padding: 0;
}
.menu-nav .menu-item > .menu-link {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin: 0;
  padding: 0;
  text-decoration: none;
  position: relative;
  outline: none;
}
.menu-nav .menu-item > .menu-link:hover {
  text-decoration: none;
  cursor: pointer;
}
.menu-nav .menu-item > .menu-link .menu-text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0;
}
.menu-nav .menu-item > .menu-link .menu-label {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.menu-nav .menu-item > .menu-link .menu-icon {
  display: flex;
  align-items: center;
  line-height: 0;
}
.menu-nav .menu-item > .menu-link .menu-badge .badge-text {
  display: block;
}
.menu-nav .menu-item > .menu-link .menu-arrow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: 0;
  transform: translate3d(0, 0, 0);
}
.menu-nav .menu-item.menu-item-open > .menu-link > .menu-arrow {
  transform: rotateZ(90deg);
}
.menu-nav .menu-item.menu-item-open > .menu-submenu {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.menu-nav .menu-item .menu-submenu .menu-item > .menu-link {
  padding: 16px 25px;
}
.menu-nav .menu-item .menu-submenu .menu-item-parent {
  display: none;
}
.menu-nav .menu-section {
  display: flex;
  padding: 0 25px;
}
.menu-nav .menu-section.menu-section-first {
  margin-top: 0 !important;
}
.menu-nav .menu-section .menu-text {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}
.menu-nav .menu-section .menu-icon {
  display: none;
  align-items: center;
  justify-content: center;
}
.menu-nav .menu-separator {
  height: 0;
  overflow: hidden;
}
.menu-nav > .menu-item {
  position: relative;
  margin: 0;
  cursor: pointer;
  min-height: 56px;
  margin: margin 0;
  padding: padding 0;
}
.menu-nav > .menu-item .menu-text {
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 24px;
  margin-left: 16px;
  text-transform: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}
.menu-nav > .menu-item .menu-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50px;
  min-width: 24px;
  box-sizing: border-box;
}
.menu-nav > .menu-item .badge-text {
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: var(--gray-900);
  display: flex;
  justify-content: center;
  flex: 1;
}
.menu-nav > .menu-item .menu-icon {
  flex: 0 0 24px;
}
.menu-nav > .menu-item .menu-icon-svg {
  width: 24px;
  height: 24px;
}
.menu-nav > .menu-item .submenu-icon-svg {
  width: 8px;
  height: 8px;
}
.menu-nav > .menu-item .menu-arrow {
  flex: 0 0 20px;
  font-size: 0.8rem;
  margin-left: 8px;
  transition: all 0.3s ease;
}
.menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow {
  transition: all 0.3s ease;
}
.menu-nav > .menu-item .menu-submenu .menu-subnav {
  margin: 0;
}
.menu-nav > .menu-item .menu-submenu .menu-item {
  cursor: pointer;
  min-height: 56px;
  margin: margin 0;
  padding: padding 16px 25px;
}
.menu-nav > .menu-item .menu-submenu .menu-item .menu-text {
  font-family: var(--font-family-regular);
  font-size: 16px;
  line-height: 24px;
  margin-left: 16px;
  text-transform: initial;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}
.menu-nav > .menu-item .menu-submenu .menu-item .menu-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50px;
  min-width: 24px;
  box-sizing: border-box;
}
.menu-nav > .menu-item .menu-submenu .menu-item .badge-text {
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: var(--gray-900);
  display: flex;
  justify-content: center;
  flex: 1;
}
.menu-nav > .menu-item .menu-submenu .menu-item .menu-icon {
  flex: 0 0 24px;
}
.menu-nav > .menu-item .menu-submenu .menu-item .menu-icon-svg {
  width: 24px;
  height: 24px;
}
.menu-nav > .menu-item .menu-submenu .menu-item .submenu-icon-svg {
  width: 8px;
  height: 8px;
}
.menu-nav > .menu-item .menu-submenu .menu-item .menu-arrow {
  flex: 0 0 20px;
  font-size: 0.8rem;
  margin-left: 8px;
  transition: all 0.3s ease;
}
.menu-nav > .menu-item .menu-submenu .menu-section {
  margin: margin 20px 0 0 0;
  height: padding 0 25px;
}
.menu-nav > .menu-item .menu-submenu .menu-section .menu-text {
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.menu-nav > .menu-item .menu-submenu .menu-separator {
  margin: 0;
}
.menu-nav > .menu-section {
  margin: margin 20px 0 0 0;
  height: padding 0 25px;
}
.menu-nav > .menu-section .menu-text {
  font-size: 0.8rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}
.menu-nav > .menu-item > .menu-link {
  padding: 16px 12px 16px 8px;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-heading {
  padding: 16px 12px 16px 16px;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-inner {
  padding: 0;
  margin: 0;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav .menu-content .menu-inner .menu-link {
  padding: 16px 12px 16px 16px;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
  padding: 16px 12px 16px 16px;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
  padding: 0;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav {
  padding: 0;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
  padding: 16px 12px 16px 16px;
  padding-left: 24px;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
  padding: 0;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-link {
  padding: 16px 12px 16px 16px;
  padding-left: 32px;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
  padding: 0 25px;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
  padding: 0;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
  padding: 0 25px;
  padding-left: 16px;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu {
  padding: 0;
}
.menu-nav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-item > .menu-submenu .menu-subnav > .menu-section {
  padding: 0 25px;
  padding-left: 24px;
}

.menu-nav > .menu-item > .menu-link:hover {
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item > .menu-link:hover .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item > .menu-link:hover .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item > .menu-link:hover .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-item > .menu-link .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item > .menu-link .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item > .menu-link .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-item.menu-item-open {
  transition: background-color 0.3s;
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item.menu-item-open > .menu-link .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item.menu-item-open > .menu-link .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item.menu-item-open > .menu-link .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-item.menu-item-active {
  transition: background-color 0.3s;
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item.menu-item-active > .menu-link {
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item.menu-item-active > .menu-link .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item.menu-item-active > .menu-link .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-item > .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-section .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-section .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-separator {
  border-bottom: 1px solid var(--surface-secondary);
}
.menu-nav > .menu-item .menu-submenu .menu-subnav {
  background-color: var(--surface-primary);
}
.menu-nav > .menu-item .menu-submenu .menu-item > .menu-link:hover {
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item .menu-submenu .menu-item > .menu-link:hover .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item > .menu-link:hover .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item > .menu-link:hover .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item > .menu-link .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open {
  transition: background-color 0.3s;
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link {
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-open > .menu-link .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active {
  transition: background-color 0.3s;
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link {
  background-color: var(--surface-secondary);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-arrow {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-section .menu-text {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-section .menu-icon {
  color: var(--gray-900);
}
.menu-nav > .menu-item .menu-submenu .menu-separator {
  border-bottom: 1px solid var(--surface-secondary);
}

@media (min-width: 1080px) {
  .header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    background-color: var(--body-backgroud);
    display: flex;
    justify-content: space-between;
    height: 65px;
    position: fixed;
    top: calc(30px + var(--release-info-label-top));
    right: 0;
    left: 0;
    z-index: 97;
  }
  .header .header-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 0;
    width: 100%;
    padding: 0 8px 0 8px;
    margin-right: auto;
    margin-left: auto;
  }
  .header .header-brand {
    height: 65px;
    display: flex;
  }
  .header .header-brand .header-logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 65px;
    min-width: 65px;
    padding: 0 8px;
  }
  .header .header-brand .header-logo .pps-g2-logo {
    height: 65px;
  }
  .header .header-brand .header-logo .brand-logo {
    height: 16px;
  }
  .header .header-brand .header-logo::after {
    content: "";
    position: absolute;
    right: 0;
    top: 16px;
    bottom: 16px;
    border-right: 1px solid var(--gray-100);
  }
}
@media (max-width: 1079.9px) {
  .header {
    display: none;
  }
  .header-brand {
    display: none;
  }
}
@media (min-width: 1080px) {
  .header-mobile {
    display: none;
  }
}
@media (max-width: 1079.9px) {
  .header-mobile {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 30px;
    right: 0;
    left: 0;
    z-index: 97;
    height: 55px;
    min-height: 55px;
    background-color: var(--body-backgroud);
  }
  .header-mobile .header-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding-left: 0;
    width: 100%;
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
  }
  .header-mobile .header-brand-mobile {
    height: 55px;
    display: flex;
  }
  .header-mobile .header-brand-mobile .header-logo-mobile {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 55px;
    min-width: 55px;
    padding: 0 10px;
  }
  .header-mobile .header-brand-mobile .header-logo-mobile .pps-g2-logo-mobile {
    height: 55px;
  }
  .header-mobile .header-brand-mobile .header-logo-mobile .brand-logo-mobile {
    height: 16px;
  }
  .header-mobile .header-brand-mobile .header-logo-mobile::after {
    content: "";
    position: absolute;
    right: 0;
    top: 16px;
    bottom: 16px;
    border-right: 1px solid var(--gray-100);
  }
}
.topbar {
  display: flex;
  align-self: end;
  align-items: stretch;
  padding: 0;
  height: 65px;
}
.topbar .topbar-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0.25rem;
  min-width: 65px;
  justify-content: center;
  padding: 10px 8px 10px 8px;
  box-sizing: border-box;
}
.topbar .topbar-item.disabled {
  user-select: none;
  pointer-events: none;
}
.topbar .topbar-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 16px;
  bottom: 16px;
  border-right: 1px solid var(--gray-100);
}

@media (max-width: 1079.9px) {
  .topbar {
    height: 55px;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
    justify-content: flex-end;
    padding: 0 15px;
    border-top: 1px solid transparent;
  }
  .topbar-item .user-info__name {
    display: none;
  }
}
.header-loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
  z-index: 1000;
}
.header-loading-bar .dx-progressbar-wrapper {
  position: absolute;
}
.header-loading-bar .dx-progressbar-container {
  height: 3px;
}

.form-field-denied {
  display: block;
  pointer-events: none;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid var(--transparent-secondary);
}
.form-field-denied .form-field__value {
  display: none;
}
.form-field-denied .form-field:after {
  content: "";
  filter: var(--gray-700-filter);
  position: absolute;
  display: block;
  background: url("../../media/svg/icons/hide.svg") no-repeat center;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overflow-visible .form-field__value {
  overflow: visible !important;
}

.release-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.release-info.release-info-top-label {
  height: var(--release-info-label-top);
  background-color: var(--primary);
  color: var(--white);
  position: fixed;
  top: 30px;
  right: 0;
  left: 0;
  z-index: 97;
}

.release-info-link {
  text-decoration: underline;
  cursor: pointer;
}

.dx-accordion-item-opened {
  margin: unset;
}

.dx-accordion {
  background-color: transparent !important;
  border-top: 1px solid var(--surface-secondary);
  border-bottom: 1px solid var(--surface-secondary);
  margin: 0px;
}
.dx-accordion.hidden-expander .dx-accordion-item-title {
  cursor: auto;
}
.dx-accordion.hidden-expander .dx-accordion-item-title::before {
  content: "" !important;
  right: 0 !important;
  background: unset !important;
}

.dx-accordion-item {
  box-shadow: none !important;
}

.dx-accordion-item > .dx-accordion-item-title,
.dx-accordion-item.dx-state-active > .dx-accordion-item-title,
.dx-accordion-item.dx-state-focused > .dx-accordion-item-title,
.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: transparent !important;
}

.dx-accordion-item > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-active > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-focused > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: transparent !important;
}

.dx-accordion-item-opened > .dx-accordion-item-title {
  height: 48px;
}

.dx-accordion-item-title::before {
  right: 22px;
  top: 2px !important;
  font-size: 35px;
  color: var(--gray-300);
}

.dx-accordion-wrapper .dx-empty-message {
  font-size: 16px;
}

pps-new-file .dx-accordion-wrapper .dx-empty-message {
  font-size: 16px;
  padding: 10px 0;
  font-family: var(--font-family-light);
}

.dx-accordion-item-title::before {
  content: "";
  background: url("../../media/svg/icons/chevronDown.svg") no-repeat center !important;
  background-size: 24px 24px !important;
  width: 24px;
  height: 24px;
  display: inline-block;
  top: 50%;
  transform: translateY(50%);
}

.dx-accordion-item-opened > .dx-accordion-item-title::before {
  content: "";
  background: url("../../media/svg/icons/chevronUp.svg") no-repeat center !important;
  background-size: 24px 24px !important;
  width: 24px;
  height: 24px;
  display: inline-block;
  top: 50%;
  transform: translateY(50%);
}

.dx-accordion-item-title {
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--gray-900);
}

.dx-accordion-item.dx-accordion-item-opened > .dx-accordion-item-title {
  background-color: var(--transparent-tertiary) !important;
}

.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: var(--transparent-secondary) !important;
}

.dx-accordion .dx-accordion-item.dx-accordion-item-opened {
  border-left: 1px solid var(--transparent-secondary) !important;
  border-right: 1px solid var(--transparent-secondary) !important;
  border-bottom: 2px solid var(--gray-500) !important;
}

.dx-template-wrapper.dx-item-content.dx-accordion-item-body {
  padding-bottom: 32px;
}

.grid-toolbar-wrapper {
  height: 55px;
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: 1px solid var(--surface-secondary);
  border-left: 1px solid var(--surface-secondary);
  border-right: 1px solid var(--surface-secondary);
}
.grid-toolbar-wrapper .dx-tabs {
  margin-left: 5px;
}

.grid-no-pager .dx-datagrid-borders > .dx-datagrid-rowsview {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.grid-row-size-extra-small .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
.grid-row-size-extra-small .dx-datagrid .dx-datagrid-revert-tooltip .dx-revert-button {
  height: 33px;
  padding-top: 0;
  padding-bottom: 0;
}
.grid-row-size-extra-small .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 33px;
}

.grid-row-size-small .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
.grid-row-size-small .dx-datagrid .dx-datagrid-revert-tooltip .dx-revert-button {
  height: 48px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.grid-row-size-small .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 48px;
}

.grid-row-size-medium .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
.grid-row-size-medium .dx-datagrid .dx-datagrid-revert-tooltip .dx-revert-button {
  height: 56px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.grid-row-size-medium .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 56px;
}

.grid-row-size-large .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
.grid-row-size-large .dx-datagrid .dx-datagrid-revert-tooltip .dx-revert-button {
  height: 64px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.grid-row-size-large .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 64px;
}

.dx-datagrid .dx-datagrid-rowsview .dx-row > td.dx-editor-cell {
  padding-top: 0;
  padding-bottom: 0;
}

.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row > td,
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-virtual-row > td {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.linked-documents-icon {
  font-size: 18px;
}

.dx-datagrid .dx-header-filter {
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.dx-datagrid .dx-header-filter.dx-header-filter-empty::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/filter.svg") no-repeat center;
  filter: none;
  background-size: 20px 20px;
}
.dx-datagrid .dx-header-filter::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/filterFull.svg") no-repeat center;
  filter: invert(50%) sepia(70%) saturate(310%) hue-rotate(96deg) brightness(87%) contrast(89%);
  background-size: 20px 20px;
}

.dx-datagrid {
  border-radius: 8px 8px 0 0;
  background-color: transparent;
  font-family: var(--font-family-regular);
  font-weight: 400;
}

.dx-datagrid .dx-header-row {
  height: 40px !important;
}

.dx-datagrid .dx-row > td {
  padding: 6px 15px;
}

.dx-datagrid .dx-datagrid-table .dx-header-row > td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dx-datagrid .dx-datagrid-filter-row .dx-editor-cell .dx-texteditor {
  height: 40px;
  line-height: 40px;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 40px;
}

.dx-datagrid-focus-overlay {
  max-height: 40px !important;
}

.dx-datagrid .dx-datagrid-filter-row {
  height: 40px;
}

.dx-row .dx-datagrid-filter-row {
  height: 40px;
}

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {
  padding-top: 0px;
}

.dx-datagrid .dx-header-row > .dx-command-edit,
.dx-datagrid .dx-header-row > td .dx-datagrid-text-content {
  font-family: var(--font-family-bold);
  font-weight: 700 !important;
  font-style: normal;
  font-size: 14px !important;
  line-height: 17px !important;
}

.dx-datagrid-rowsview .dx-row {
  border: 0;
}

.dx-datagrid-borders > .dx-datagrid-headers {
  border-radius: 8px 8px 0 0;
  background-color: var(--white);
}

.dx-datagrid-borders > .dx-datagrid-total-footer,
.dx-datagrid-borders > .dx-datagrid-rowsview {
  background-color: var(--white);
}

.dx-datagrid-borders > .dx-datagrid-total-footer {
  background-color: var(--primary-light);
}

.dx-datagrid-headers .dx-datagrid-content {
  border-radius: 8px 8px 0 0;
}

.dx-datagrid .dx-datagrid-headers {
  box-shadow: 0px 10px 8px -13px var(--black) !important;
  z-index: 3;
}

.dx-datagrid-borders > .dx-datagrid-pager {
  border: 1px solid var(--surface-secondary);
  border-top: 1px solid transparent;
  border-radius: 0 0 8px 8px;
}

.dx-pager .dx-page,
.dx-pager .dx-page-size {
  border-radius: 8px;
}

.dx-datagrid .dx-pager {
  background-color: transparent;
}

.dx-invalid.dx-template-wrapper {
  position: relative;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  font-size: 12px;
}

.dx-invalid.dx-template-wrapper::after {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 23px;
  font-size: 14px;
  font-weight: 500;
  background-color: #b00020;
  color: #fff;
  content: "!";
  border-radius: 50%;
  right: 8px;
}

.dx-datagrid-filter-row {
  background-color: transparent;
}

.dx-datagrid .dx-editor-cell .dx-texteditor {
  background-color: transparent !important;
}

.dx-datagrid-filter-row .dx-filter-modified {
  background-color: transparent;
}

.dx-datagrid .dx-invalid-message {
  display: block;
  padding: 0px !important;
}

.dx-datagrid .dx-column-lines > td {
  border-left: 1px solid var(--surface-secondary);
  border-right: none;
}

.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
  border-left: 1px solid var(--surface-secondary);
  border-right: 1px solid var(--surface-secondary);
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
  background-color: var(--surface-secondary) !important;
}

.dx-data-row.row-background-gray {
  background-color: var(--gray-200) !important;
}

.dx-data-row.dx-selection {
  background-color: var(--gray-200) !important;
  /* technology - selectable tree component*/
}
.dx-data-row.dx-selection.row-selection-error {
  background-color: var(--danger-light) !important;
}
.dx-data-row.dx-selection.row-selection-success {
  background-color: var(--hover-primary) !important;
}

.technology-alignment-grid-cell-valid {
  background-color: rgba(55, 208, 2, 0.16) !important;
}

.technology-alignment-grid-cell-invalid {
  background-color: rgba(235, 43, 55, 0.16) !important;
}

.devexpress-grid .dx-row[data-pps-selected],
.devexpress-grid .dx-row[data-pps-selected] td {
  background-color: var(--surface-primary);
}

.devexpress-grid .dx-row.dx-row-alt[data-pps-selected],
.devexpress-grid .dx-row.dx-row-alt[data-pps-selected] td {
  background-color: var(--surface-primary);
}

.devexpress-grid .dx-row td[grid-cell-editable] {
  background-color: var(--quarternary);
}

.devexpress-grid .dx-row.dx-row-alt td[grid-cell-editable] {
  background-color: var(--quarternary);
}

.devexpress-grid .dx-row td[grid-cell-warning] {
  background-color: var(--warning);
}

.devexpress-grid .dx-row.dx-row-alt td[grid-cell-warning] {
  background-color: var(--warning);
}

.devexpress-grid .dx-row td[grid-cell-error] {
  background-color: var(--danger);
}

.devexpress-grid .dx-row.dx-row-alt td[grid-cell-error] {
  background-color: var(--danger);
}

.devexpress-grid .dx-row td[grid-cell-disabled],
.devexpress-grid .dx-row.dx-row-alt td[grid-cell-disabled] {
  background-color: var(--surface-primary);
  opacity: 0.33;
}

.devexpress-grid .dx-row.dx-selection td.bg-primary,
.devexpress-grid .dx-row.dx-row-alt td.bg-primary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-primary {
  background-color: var(--primary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-secondary,
.devexpress-grid .dx-row.dx-row-alt td.bg-secondary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-secondary {
  background-color: var(--secondary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-tertiary,
.devexpress-grid .dx-row.dx-row-alt td.bg-tertiary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-tertiary {
  background-color: var(--tertiary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-quarternary,
.devexpress-grid .dx-row.dx-row-alt td.bg-quarternary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-quarternary {
  background-color: var(--quarternary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-gray-100,
.devexpress-grid .dx-row.dx-row-alt td.bg-gray-100,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-gray-100 {
  background-color: var(--gray-100) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-gray-200,
.devexpress-grid .dx-row.dx-row-alt td.bg-gray-200,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-gray-200 {
  background-color: var(--gray-200) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-gray-300,
.devexpress-grid .dx-row.dx-row-alt td.bg-gray-300,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-gray-300 {
  background-color: var(--gray-300) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-gray-500,
.devexpress-grid .dx-row.dx-row-alt td.bg-gray-500,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-gray-500 {
  background-color: var(--gray-500) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-gray-600,
.devexpress-grid .dx-row.dx-row-alt td.bg-gray-600,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-gray-600 {
  background-color: var(--gray-600) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-gray-700,
.devexpress-grid .dx-row.dx-row-alt td.bg-gray-700,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-gray-700 {
  background-color: var(--gray-700) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-gray-800,
.devexpress-grid .dx-row.dx-row-alt td.bg-gray-800,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-gray-800 {
  background-color: var(--gray-800) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-gray-900,
.devexpress-grid .dx-row.dx-row-alt td.bg-gray-900,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-white,
.devexpress-grid .dx-row.dx-row-alt td.bg-white,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-white {
  background-color: var(--white) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-black,
.devexpress-grid .dx-row.dx-row-alt td.bg-black,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-black {
  background-color: var(--black) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-danger,
.devexpress-grid .dx-row.dx-row-alt td.bg-danger,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-danger {
  background-color: var(--danger) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-danger-deadline,
.devexpress-grid .dx-row.dx-row-alt td.bg-danger-deadline,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-danger-deadline {
  background-color: var(--danger-deadline) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-danger-deadline-secondary,
.devexpress-grid .dx-row.dx-row-alt td.bg-danger-deadline-secondary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-danger-deadline-secondary {
  background-color: var(--danger-deadline-secondary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-warning,
.devexpress-grid .dx-row.dx-row-alt td.bg-warning,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-warning {
  background-color: var(--warning) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-warning-light,
.devexpress-grid .dx-row.dx-row-alt td.bg-warning-light,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-warning-light {
  background-color: var(--warning-light) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-warning-secondary,
.devexpress-grid .dx-row.dx-row-alt td.bg-warning-secondary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-warning-secondary {
  background-color: var(--warning-secondary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-warning-deadline,
.devexpress-grid .dx-row.dx-row-alt td.bg-warning-deadline,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-warning-deadline {
  background-color: var(--warning-deadline) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-warning-deadline-secondary,
.devexpress-grid .dx-row.dx-row-alt td.bg-warning-deadline-secondary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-warning-deadline-secondary {
  background-color: var(--warning-deadline-secondary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-success,
.devexpress-grid .dx-row.dx-row-alt td.bg-success,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-success {
  background-color: var(--success) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-info,
.devexpress-grid .dx-row.dx-row-alt td.bg-info,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-info {
  background-color: var(--info) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-hover-primary,
.devexpress-grid .dx-row.dx-row-alt td.bg-hover-primary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-hover-primary {
  background-color: var(--hover-primary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-hover-secondary,
.devexpress-grid .dx-row.dx-row-alt td.bg-hover-secondary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-hover-secondary {
  background-color: var(--hover-secondary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-disabled-primary,
.devexpress-grid .dx-row.dx-row-alt td.bg-disabled-primary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-disabled-primary {
  background-color: var(--disabled-primary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-disabled-secondary,
.devexpress-grid .dx-row.dx-row-alt td.bg-disabled-secondary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-disabled-secondary {
  background-color: var(--disabled-secondary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-disabled-tertiary,
.devexpress-grid .dx-row.dx-row-alt td.bg-disabled-tertiary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-disabled-tertiary {
  background-color: var(--disabled-tertiary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-transparent-primary,
.devexpress-grid .dx-row.dx-row-alt td.bg-transparent-primary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-transparent-primary {
  background-color: var(--transparent-primary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-transparent-secondary,
.devexpress-grid .dx-row.dx-row-alt td.bg-transparent-secondary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-transparent-secondary {
  background-color: var(--transparent-secondary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-transparent-tertiary,
.devexpress-grid .dx-row.dx-row-alt td.bg-transparent-tertiary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-transparent-tertiary {
  background-color: var(--transparent-tertiary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-transparent-backdrop,
.devexpress-grid .dx-row.dx-row-alt td.bg-transparent-backdrop,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-transparent-backdrop {
  background-color: var(--transparent-backdrop) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-surface-primary,
.devexpress-grid .dx-row.dx-row-alt td.bg-surface-primary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-surface-primary {
  background-color: var(--surface-primary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-surface-secondary,
.devexpress-grid .dx-row.dx-row-alt td.bg-surface-secondary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-surface-secondary {
  background-color: var(--surface-secondary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-surface-tertiary,
.devexpress-grid .dx-row.dx-row-alt td.bg-surface-tertiary,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-surface-tertiary {
  background-color: var(--surface-tertiary) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-surface-info,
.devexpress-grid .dx-row.dx-row-alt td.bg-surface-info,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-surface-info {
  background-color: var(--surface-info) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-danger-light,
.devexpress-grid .dx-row.dx-row-alt td.bg-danger-light,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-danger-light {
  background-color: var(--danger-light) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-primary-light,
.devexpress-grid .dx-row.dx-row-alt td.bg-primary-light,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-primary-light {
  background-color: var(--primary-light) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-primary-lighter,
.devexpress-grid .dx-row.dx-row-alt td.bg-primary-lighter,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-primary-lighter {
  background-color: var(--primary-lighter) !important;
}

.devexpress-grid .dx-row.dx-selection td.bg-surface-info-light,
.devexpress-grid .dx-row.dx-row-alt td.bg-surface-info-light,
.devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-surface-info-light {
  background-color: var(--surface-info-light) !important;
}

.devexpress-grid .dx-row.dx-footer-row td[grid-total-footer-success] {
  background-color: var(--quarternary);
}

.devexpress-grid .dx-row.dx-footer-row td[grid-total-footer-error] {
  background-color: var(--danger-light);
}

.devexpress-grid {
  width: 100%;
}

.grid-with-toolbar .devexpress-grid .dx-datagrid-borders > .dx-datagrid-headers {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 360px) and (max-width: 719.9px) {
  .page-variable-height .devexpress-grid {
    height: calc(100vh - var(--pps-devexpress-grid--height-phone, 0));
    max-height: calc(100vh - var(--pps-devexpress-grid--max-height-phone, 0));
  }
  .page-default-height .devexpress-grid {
    height: calc(100vh - var(--header-height-phone) - var(--mdc-layout-grid-margin-phone) * 2 - var(--release-info-label-top) - 30px - var(--pps-devexpress-grid--height-phone, 0));
    max-height: calc(100vh - var(--header-height-phone) - var(--mdc-layout-grid-margin-phone) * 2 - var(--release-info-label-top) - 30px - var(--pps-devexpress-grid--max-height-phone, 0));
  }
}
@media (min-width: 720px) and (max-width: 1079.9px) {
  .page-variable-height .devexpress-grid {
    height: calc(100vh - var(--pps-devexpress-grid--height-tablet, 0));
    max-height: calc(100vh - var(--pps-devexpress-grid--max-height-tablet, 0));
  }
  .page-default-height .devexpress-grid {
    height: calc(100vh - var(--header-height-tablet) - var(--mdc-layout-grid-margin-tablet) * 2 - var(--release-info-label-top) - 30px - var(--pps-devexpress-grid--height-tablet, 0));
    max-height: calc(100vh - var(--header-height-tablet) - var(--mdc-layout-grid-margin-tablet) * 2 - var(--release-info-label-top) - 30px - var(--pps-devexpress-grid--max-height-tablet, 0));
  }
}
@media (min-width: 1080px) {
  .page-variable-height .devexpress-grid {
    height: calc(100vh - var(--pps-devexpress-grid--height-desktop, 0));
    max-height: calc(100vh - var(--pps-devexpress-grid--max-height-desktop, 0));
  }
  .page-default-height .devexpress-grid {
    height: calc(100vh - var(--header-height-desktop) - var(--mdc-layout-grid-margin-desktop) * 2 - var(--release-info-label-top) - 30px - var(--pps-devexpress-grid--height-desktop, 0));
    max-height: calc(100vh - var(--header-height-desktop) - var(--mdc-layout-grid-margin-desktop) * 2 - var(--release-info-label-top) - 30px - var(--pps-devexpress-grid--max-height-desktop, 0));
  }
}
.dx-icon-filter-operation-equals::before {
  content: "" !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/equalsign.svg") no-repeat center center;
  background-size: contain;
}

.dx-icon-filter-operation-default::before {
  content: "" !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/search.svg") no-repeat center center;
  background-size: contain;
}

.dx-icon-filter-operation-not-equals::before {
  color: #161718;
  content: "\f045";
}

.dx-icon-filter-operation-less::before {
  content: "" !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/chevronLeft.svg") no-repeat center center;
  background-size: contain;
}

.dx-icon-filter-operation-less-equal::before {
  color: #161718;
  content: "\f048";
}

.dx-icon-filter-operation-greater::before {
  content: "" !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/chevronRight.svg") no-repeat center center;
  background-size: contain;
}

.dx-icon-filter-operation-greater-equal::before {
  color: #161718;
  content: "\f049";
}

.dx-icon-filter-operation-contains::before {
  color: #161718;
  content: "\f063";
}

.dx-icon-filter-operation-not-contains::before {
  color: #161718;
  content: "\f066";
}

.dx-icon-filter-operation-starts-with::before {
  color: #161718;
  content: "\f064";
}

.dx-icon-filter-operation-ends-with::before {
  color: #161718;
  content: "\f065";
}

.dx-icon-filter-operation-between::before {
  color: #161718;
  content: "\f06a";
}

.devexpress-grid .dx-datebox .dx-dropdowneditor-button .dx-dropdowneditor-icon::before {
  content: "" !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/calendar.svg") no-repeat center center;
  background-size: contain;
}

.dx-sort.dx-sort-up::before {
  content: "" !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/sortUp.svg") no-repeat center center;
  background-size: contain;
}

.dx-sort.dx-sort-down::before {
  content: "" !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/sortDown.svg") no-repeat center center;
  background-size: contain;
}

.dx-sort.dx-sort-up,
.dx-sort.dx-sort-down {
  padding-right: 5px;
}

.dx-header-row > td:not(:last-child):after,
.dx-datagrid-filter-row > td:not(:last-child):after {
  content: "";
  position: absolute;
  bottom: 15px;
  top: 15px;
  right: 0;
  border-right: 1px solid var(--surface-secondary);
}

.dx-header-row > td:has(+ .dx-pointer-events-none):after,
.dx-header-row > td:has(+ .dx-hidden-cell):after,
.dx-header-row > td.dx-hidden-cell:after,
.dx-header-row > td.dx-command-drag:after,
.dx-header-row > td:has(+ .dx-command-edit):after,
.dx-datagrid-filter-row > td:has(+ .dx-pointer-events-none):after,
.dx-datagrid-filter-row > td:has(+ .dx-hidden-cell):after,
.dx-datagrid-filter-row > td.dx-command-drag:after,
.dx-datagrid-filter-row > td:has(+ .dx-command-edit):after {
  border-right: 1px solid transparent;
}

.dx-header-row > td,
.dx-datagrid-filter-row > td {
  position: relative;
}

.dx-toolbar-label .dx-toolbar-item-content > div {
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
}

.dx-datagrid-headers.dx-header-multi-row .dx-header-row > td {
  border-right: 1px solid var(--white) !important;
}

.dx-datagrid-filter-range-overlay .dx-overlay-content {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.dx-datagrid-filter-range-overlay .dx-editor-container {
  margin-bottom: 2px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);
}
.dx-datagrid-filter-range-overlay .dx-editor-container.dx-datagrid-filter-range-end {
  margin-bottom: 0;
}
.dx-datagrid-filter-range-overlay .dx-texteditor.dx-editor-filled::after {
  border-bottom: none;
}
.dx-datagrid-filter-range-overlay .dx-dropdowneditor-input-wrapper,
.dx-datagrid-filter-range-overlay .dx-texteditor-input-container {
  background: var(--white);
  border-radius: 4px;
}

.inline-filter-multiple {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  background: #f0f6f3;
  width: fit-content;
  margin-left: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.inline-filter-multiple::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url("../../media/svg/icons/filterFull.svg") no-repeat center;
  filter: invert(50%) sepia(70%) saturate(310%) hue-rotate(96deg) brightness(87%) contrast(89%);
  background-size: 20px 20px;
  vertical-align: middle;
}

.inline-filter-multiple-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: var(--gray-900);
  min-width: 16px;
}

.inline-filter-active-icon {
  background-color: var(--primary-lighter);
  border-radius: 4px;
}

.inline-filter-active-icon::before {
  filter: invert(50%) sepia(70%) saturate(310%) hue-rotate(96deg) brightness(87%) contrast(89%);
}

.dx-datagrid-focus-overlay:after {
  visibility: hidden;
}

.dx-datagrid .dx-editor-cell.dx-focused .dx-editor-container .dx-texteditor:not(.dx-invalid)::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--primary);
  left: 0;
  right: 1px;
}

.dx-datagrid .dx-editor-cell .dx-editor-container .dx-texteditor.dx-invalid::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--danger);
  left: 0;
  right: 1px;
}

.dx-datagrid .dx-datagrid-rowsview .dx-data-row .dx-command-drag {
  cursor: grab;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused):has(.pps-expired-deadline-warning) {
  background-color: var(--warning-deadline) !important;
}

.devexpress-grid .dx-row.dx-state-hover td:has(.pps-expired-deadline-warning),
.devexpress-grid .dx-row td:has(.pps-expired-deadline-warning) {
  background-color: var(--warning-deadline-secondary) !important;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused):has(.pps-expired-deadline-danger) {
  background-color: var(--danger-deadline) !important;
}

.devexpress-grid .dx-row.dx-state-hover td:has(.pps-expired-deadline-danger),
.devexpress-grid .dx-row td:has(.pps-expired-deadline-danger) {
  background-color: var(--danger-deadline-secondary) !important;
}

.devexpress-grid .dx-scrollable-scrollbar.dx-scrollbar-horizontal {
  height: 8px;
}
.devexpress-grid .dx-scrollable-scrollbar.dx-scrollbar-horizontal .dx-scrollable-scroll {
  height: 8px;
  padding: 0px;
}
.devexpress-grid .dx-scrollable-scrollbar.dx-scrollbar-horizontal .dx-scrollable-scroll-content {
  border-radius: 8px;
}
.devexpress-grid .dx-scrollable-scrollbar.dx-scrollable-scrollbar-active {
  background-color: var(--surface-primary);
}
.devexpress-grid .dx-scrollable-scrollbar.dx-scrollable-scrollbar-active .dx-scrollable-scroll-content {
  background-color: var(--gray-300);
}

.inline-grid {
  overflow-x: auto;
  width: 100%;
}

.dx-datagrid-columns-separator {
  z-index: 1001;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll .dx-scrollable-scroll-content {
  border-radius: 8px;
}

.dx-tabs {
  max-width: 100%;
}

.dx-tab.invalid {
  background-color: var(--danger-deadline);
}

.dx-tab {
  height: 55px;
  padding: 0px 28px;
}
.dx-tab .dx-tab-content {
  display: inline-block;
  box-sizing: border-box;
  padding: 8px;
  font-family: var(--font-family-bold);
  font-size: 16px;
  line-height: 16px;
  color: var(--gray-700);
  text-transform: unset;
  vertical-align: top;
}
.dx-tab .dx-tab-content:hover {
  color: var(--gray-900);
}
.dx-tab.dx-tab-selected::before {
  width: calc(100% - 72px);
  background-color: var(--primary);
  left: 36px;
}
.dx-tab.dx-tab-selected .dx-tab-content {
  color: var(--gray-900);
  text-transform: unset;
}
.dx-tab.dx-state-focused {
  background-color: var(--transparent);
}
.dx-tab.dx-state-focused:not(.dx-tab-selected) {
  color: var(--gray-700);
}
.dx-tab.dx-state-active {
  background-color: var(--transparent);
}
.dx-tab.dx-state-disabled .dx-tab-content {
  opacity: 1;
  color: var(--gray-300);
}
.dx-tab .dx-inkripple {
  display: none;
}

.invalid-message {
  font-family: var(--font-family-regular) !important;
  font-weight: 400 !important;
  width: 100%;
  pointer-events: none;
  display: inline-block;
  border-width: 0;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  word-wrap: break-word;
  background-color: transparent;
  color: var(--danger);
  padding: 6px 0 0;
}

.form-control-required .dx-texteditor:not(.dx-state-readonly) .dx-label-after::after {
  content: "*";
  margin-left: 3px;
  color: var(--danger);
}
.form-control-required.caption1::after {
  content: "*";
  margin-left: 3px;
  color: var(--danger);
}

.dx-texteditor {
  background-color: var(--transparent-primary) !important;
  border-radius: 4px 4px 0px 0px !important;
}
.dx-texteditor .dx-texteditor-input {
  font-family: var(--font-family-regular) !important;
  font-weight: 400 !important;
  color: var(--gray-600) !important;
  font-size: 13px !important;
  line-height: 20px !important;
}
.dx-texteditor .dx-texteditor-label {
  font-family: var(--font-family-regular) !important;
  font-weight: 400 !important;
  color: var(--gray-600) !important;
}
.dx-texteditor .dx-texteditor-label .dx-label {
  max-width: 100% !important;
}
.dx-texteditor:not(.dx-texteditor-empty) .dx-texteditor-label {
  height: 13px !important;
  line-height: 13px !important;
  font-size: 10px !important;
}
.dx-texteditor.dx-texteditor-empty.dx-state-readonly .dx-texteditor-label {
  height: 20px !important;
  line-height: 20px !important;
  font-size: 13.2px !important;
}
.dx-texteditor.dx-texteditor-empty .dx-texteditor-label {
  height: 20px !important;
  line-height: 20px !important;
  font-size: 13.2px !important;
}
.dx-texteditor.dx-texteditor-empty:after {
  border-color: var(--gray-600) !important;
}
.dx-texteditor.dx-state-hover .dx-texteditor-label {
  color: var(--gray-900) !important;
}
.dx-texteditor.dx-state-hover:after {
  border-color: var(--gray-900) !important;
}
.dx-texteditor.dx-state-hover.dx-invalid:not(.dx-state-readonly) .dx-texteditor-label {
  color: var(--danger) !important;
}
.dx-texteditor.dx-state-focused .dx-texteditor-label {
  height: 13px !important;
  line-height: 13px !important;
  font-size: 10px !important;
  color: var(--primary) !important;
}
.dx-texteditor.dx-state-focused:after:not(.dx-invalid) {
  border-color: var(--primary) !important;
}
.dx-texteditor.dx-state-focused.dx-invalid .dx-texteditor-label {
  color: var(--danger) !important;
}
.dx-texteditor.dx-invalid:after {
  border-color: var(--danger) !important;
}
.dx-texteditor.dx-invalid .dx-texteditor-input-container:after {
  display: none;
}
.dx-texteditor.dx-state-disabled {
  opacity: unset;
  background-color: var(--white) !important;
  border-radius: 4px 4px 4px 4px !important;
  border: 1px solid var(--transparent-secondary) !important;
}
.dx-texteditor.dx-state-disabled .dx-texteditor-buttons-container,
.dx-texteditor.dx-state-disabled .dx-placeholder,
.dx-texteditor.dx-state-disabled .dx-label-after {
  display: none;
}
.dx-texteditor.dx-state-disabled .dx-texteditor-label {
  color: var(--gray-600) !important;
}
.dx-texteditor.dx-state-disabled .dx-texteditor-input {
  color: var(--gray-900) !important;
}
.dx-texteditor.dx-state-disabled:after {
  display: none;
}
.dx-texteditor.dx-state-disabled:not(.dx-texteditor-empty) .dx-texteditor-input {
  cursor: text;
  pointer-events: auto;
}
.dx-texteditor.dx-state-invisible {
  height: 48px;
  display: block !important;
  pointer-events: none;
  background-color: var(--white) !important;
  border-radius: 4px 4px 4px 4px !important;
  border: 1px solid var(--transparent-secondary) !important;
}
.dx-texteditor.dx-state-invisible .dx-texteditor-label {
  color: var(--gray-600) !important;
}
.dx-texteditor.dx-state-invisible .dx-texteditor-input,
.dx-texteditor.dx-state-invisible .dx-placeholder,
.dx-texteditor.dx-state-invisible .dx-texteditor-buttons-container,
.dx-texteditor.dx-state-invisible .dx-label-after {
  display: none;
}
.dx-texteditor.dx-state-invisible:after {
  filter: var(--gray-700-filter);
  border: unset;
  display: block !important;
  content: "";
  background: url("../../media/svg/icons/hide.svg") no-repeat center !important;
  background-size: 24px 24px !important;
  top: 50%;
  left: 50%;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%);
}
.dx-texteditor.dx-state-readonly {
  background-color: var(--white) !important;
  border-radius: 4px 4px 4px 4px !important;
  border: 1px solid var(--transparent-secondary) !important;
}
.dx-texteditor.dx-state-readonly .dx-texteditor-label {
  color: var(--gray-600) !important;
}
.dx-texteditor.dx-state-readonly .dx-texteditor-input {
  color: var(--gray-900) !important;
}
.dx-texteditor.dx-state-readonly .dx-texteditor-buttons-container,
.dx-texteditor.dx-state-readonly .dx-placeholder,
.dx-texteditor.dx-state-readonly .dx-label-after {
  display: none;
}
.dx-texteditor.dx-state-readonly:after {
  display: none;
}
.dx-texteditor.dx-state-readonly:before {
  display: none;
}
.dx-texteditor.form-control-required:not(.dx-state-readonly) .dx-label-after::after {
  content: "*";
  margin-left: 3px;
  color: var(--danger);
}
.dx-texteditor.form-control-modified:not(.dx-state-readonly) {
  background-color: var(--surface-info) !important;
}
.dx-texteditor.bg-danger {
  background-color: var(--danger-light) !important;
}

.dx-texteditor-label .dx-label {
  text-overflow: ellipsis;
  overflow: hidden;
}

.dx-textarea.dx-texteditor-empty:not(.dx-state-focused) .dx-texteditor-label {
  top: 18px !important;
}

.dx-dropdownbutton,
.dx-button {
  height: 32px;
  min-width: 24px !important;
  border-radius: 50px;
  box-shadow: unset !important;
  border-width: 2px;
  border-style: solid;
  font-family: var(font-family-bold);
  opacity: unset;
  font-size: 14px;
  line-height: 16px;
}
.dx-dropdownbutton .dx-button-mode-outlined,
.dx-button .dx-button-mode-outlined {
  height: 28px !important;
  border-radius: 50px !important;
  border: none;
}
.dx-dropdownbutton.dx-button-has-text .dx-button-content,
.dx-button.dx-button-has-text .dx-button-content {
  padding: 8px 16px;
}
.dx-dropdownbutton.dx-button-has-text .dx-button-text,
.dx-button.dx-button-has-text .dx-button-text {
  opacity: unset;
  font-family: var(font-family-bold);
  font-weight: 700;
  letter-spacing: 0.75px;
  text-transform: unset;
}
.dx-dropdownbutton .dx-icon,
.dx-button .dx-icon {
  opacity: unset;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton .dx-inkripple,
.dx-button .dx-inkripple {
  display: none !important;
}
.dx-dropdownbutton.dx-has-icon:not(.dx-button-has-text),
.dx-button.dx-has-icon:not(.dx-button-has-text) {
  height: 32px !important;
  width: 32px !important;
}
.dx-dropdownbutton.dx-has-icon:not(.dx-button-has-text) .dx-icon,
.dx-button.dx-has-icon:not(.dx-button-has-text) .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.dx-dropdownbutton.btn-primary,
.dx-button.btn-primary {
  background: var(--secondary);
  border-color: var(--secondary);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary .dx-button-text,
.dx-button.btn-primary .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary .dx-icon,
.dx-button.btn-primary .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-primary:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-primary.dx-state-focused,
.dx-button.btn-primary.dx-state-focused {
  background: var(--secondary);
  border-color: var(--secondary);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-state-focused .dx-icon,
.dx-button.btn-primary.dx-state-focused .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-state-focused:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-primary.dx-state-focused:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-primary.dx-state-focused .dx-button-text,
.dx-button.btn-primary.dx-state-focused .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-state-active,
.dx-button.btn-primary.dx-state-active {
  background: var(--secondary);
  border-color: var(--secondary);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-state-active .dx-icon,
.dx-button.btn-primary.dx-state-active .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-state-active:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-primary.dx-state-active:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-primary.dx-state-active .dx-button-text,
.dx-button.btn-primary.dx-state-active .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-state-disabled,
.dx-button.btn-primary.dx-state-disabled {
  background: var(--disabled-secondary);
  border-color: var(--disabled-secondary);
  color: var(--disabled-tertiary);
  opacity: unset;
}
.dx-dropdownbutton.btn-primary.dx-state-disabled .dx-button-content,
.dx-button.btn-primary.dx-state-disabled .dx-button-content {
  opacity: 1 !important;
}
.dx-dropdownbutton.btn-primary.dx-state-disabled .dx-icon,
.dx-button.btn-primary.dx-state-disabled .dx-icon {
  color: var(--disabled-tertiary);
}
.dx-dropdownbutton.btn-primary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-primary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-tertiary-filter);
}
.dx-dropdownbutton.btn-primary.dx-state-disabled .dx-button-text,
.dx-button.btn-primary.dx-state-disabled .dx-button-text {
  color: var(--disabled-tertiary);
}
.dx-dropdownbutton.btn-primary.dx-state-hover,
.dx-button.btn-primary.dx-state-hover {
  background: var(--hover-secondary);
  border-color: var(--hover-secondary);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-state-hover .dx-icon,
.dx-button.btn-primary.dx-state-hover .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-state-hover:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-primary.dx-state-hover:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-primary.dx-state-hover .dx-button-text,
.dx-button.btn-primary.dx-state-hover .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-primary.dx-button-has-icon:not(.dx-button-has-text),
.dx-button.btn-primary.dx-button-has-icon:not(.dx-button-has-text) {
  height: 32px !important;
  width: 32px !important;
}
.dx-dropdownbutton.btn-primary.dx-button-has-icon:not(.dx-button-has-text) .dx-icon,
.dx-button.btn-primary.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.dx-dropdownbutton.btn-secondary,
.dx-button.btn-secondary {
  background: var(--gray-700);
  border-color: var(--gray-700);
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary .dx-button-text,
.dx-button.btn-secondary .dx-button-text {
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary .dx-icon,
.dx-button.btn-secondary .dx-icon {
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-secondary:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--white-filter);
}
.dx-dropdownbutton.btn-secondary.dx-state-focused,
.dx-button.btn-secondary.dx-state-focused {
  background: var(--gray-700);
  border-color: var(--gray-700);
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-state-focused .dx-icon,
.dx-button.btn-secondary.dx-state-focused .dx-icon {
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-state-focused:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-secondary.dx-state-focused:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--white-filter);
}
.dx-dropdownbutton.btn-secondary.dx-state-focused .dx-button-text,
.dx-button.btn-secondary.dx-state-focused .dx-button-text {
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-state-active,
.dx-button.btn-secondary.dx-state-active {
  background: var(--gray-700);
  border-color: var(--gray-700);
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-state-active .dx-icon,
.dx-button.btn-secondary.dx-state-active .dx-icon {
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-state-active:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-secondary.dx-state-active:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--white-filter);
}
.dx-dropdownbutton.btn-secondary.dx-state-active .dx-button-text,
.dx-button.btn-secondary.dx-state-active .dx-button-text {
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-state-disabled,
.dx-button.btn-secondary.dx-state-disabled {
  background: var(--disabled-secondary);
  border-color: var(--disabled-secondary);
  color: var(--disabled-tertiary);
  opacity: unset;
}
.dx-dropdownbutton.btn-secondary.dx-state-disabled .dx-button-content,
.dx-button.btn-secondary.dx-state-disabled .dx-button-content {
  opacity: 1 !important;
}
.dx-dropdownbutton.btn-secondary.dx-state-disabled .dx-icon,
.dx-button.btn-secondary.dx-state-disabled .dx-icon {
  color: var(--disabled-tertiary);
}
.dx-dropdownbutton.btn-secondary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-secondary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-tertiary-filter);
}
.dx-dropdownbutton.btn-secondary.dx-state-disabled .dx-button-text,
.dx-button.btn-secondary.dx-state-disabled .dx-button-text {
  color: var(--disabled-tertiary);
}
.dx-dropdownbutton.btn-secondary.dx-state-hover,
.dx-button.btn-secondary.dx-state-hover {
  background: var(--gray-600);
  border-color: var(--gray-600);
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-state-hover .dx-icon,
.dx-button.btn-secondary.dx-state-hover .dx-icon {
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-state-hover:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-secondary.dx-state-hover:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--white-filter);
}
.dx-dropdownbutton.btn-secondary.dx-state-hover .dx-button-text,
.dx-button.btn-secondary.dx-state-hover .dx-button-text {
  color: var(--white);
}
.dx-dropdownbutton.btn-secondary.dx-button-has-icon:not(.dx-button-has-text),
.dx-button.btn-secondary.dx-button-has-icon:not(.dx-button-has-text) {
  height: 32px !important;
  width: 32px !important;
}
.dx-dropdownbutton.btn-secondary.dx-button-has-icon:not(.dx-button-has-text) .dx-icon,
.dx-button.btn-secondary.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.dx-dropdownbutton.btn-tertiary,
.dx-button.btn-tertiary {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary .dx-button-text,
.dx-button.btn-tertiary .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary .dx-icon,
.dx-button.btn-tertiary .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-tertiary:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-tertiary.dx-state-focused,
.dx-button.btn-tertiary.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-state-focused .dx-icon,
.dx-button.btn-tertiary.dx-state-focused .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-state-focused:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-tertiary.dx-state-focused:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-tertiary.dx-state-focused .dx-button-text,
.dx-button.btn-tertiary.dx-state-focused .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-state-active,
.dx-button.btn-tertiary.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-state-active .dx-icon,
.dx-button.btn-tertiary.dx-state-active .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-state-active:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-tertiary.dx-state-active:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-tertiary.dx-state-active .dx-button-text,
.dx-button.btn-tertiary.dx-state-active .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-state-disabled,
.dx-button.btn-tertiary.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-tertiary.dx-state-disabled .dx-button-content,
.dx-button.btn-tertiary.dx-state-disabled .dx-button-content {
  opacity: 1 !important;
}
.dx-dropdownbutton.btn-tertiary.dx-state-disabled .dx-icon,
.dx-button.btn-tertiary.dx-state-disabled .dx-icon {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-tertiary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-tertiary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-tertiary.dx-state-disabled .dx-button-text,
.dx-button.btn-tertiary.dx-state-disabled .dx-button-text {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-tertiary.dx-state-hover,
.dx-button.btn-tertiary.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-state-hover .dx-icon,
.dx-button.btn-tertiary.dx-state-hover .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-state-hover:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-tertiary.dx-state-hover:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-tertiary.dx-state-hover .dx-button-text,
.dx-button.btn-tertiary.dx-state-hover .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-tertiary.dx-button-has-icon:not(.dx-button-has-text),
.dx-button.btn-tertiary.dx-button-has-icon:not(.dx-button-has-text) {
  height: 32px !important;
  width: 32px !important;
}
.dx-dropdownbutton.btn-tertiary.dx-button-has-icon:not(.dx-button-has-text) .dx-icon,
.dx-button.btn-tertiary.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.dx-dropdownbutton.btn-ghost,
.dx-button.btn-ghost {
  background: var(--white);
  border-color: var(--gray-900);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost .dx-button-text,
.dx-button.btn-ghost .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost .dx-icon,
.dx-button.btn-ghost .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-ghost:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-ghost.dx-state-focused,
.dx-button.btn-ghost.dx-state-focused {
  background: var(--white);
  border-color: var(--gray-900);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-state-focused .dx-icon,
.dx-button.btn-ghost.dx-state-focused .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-state-focused:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-ghost.dx-state-focused:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-ghost.dx-state-focused .dx-button-text,
.dx-button.btn-ghost.dx-state-focused .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-state-active,
.dx-button.btn-ghost.dx-state-active {
  background: var(--white);
  border-color: var(--gray-900);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-state-active .dx-icon,
.dx-button.btn-ghost.dx-state-active .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-state-active:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-ghost.dx-state-active:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-ghost.dx-state-active .dx-button-text,
.dx-button.btn-ghost.dx-state-active .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-state-disabled,
.dx-button.btn-ghost.dx-state-disabled {
  background: var(--white);
  border-color: var(--disabled-primary);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-ghost.dx-state-disabled .dx-button-content,
.dx-button.btn-ghost.dx-state-disabled .dx-button-content {
  opacity: 1 !important;
}
.dx-dropdownbutton.btn-ghost.dx-state-disabled .dx-icon,
.dx-button.btn-ghost.dx-state-disabled .dx-icon {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-ghost.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-ghost.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-ghost.dx-state-disabled .dx-button-text,
.dx-button.btn-ghost.dx-state-disabled .dx-button-text {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-ghost.dx-state-hover,
.dx-button.btn-ghost.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-900);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-state-hover .dx-icon,
.dx-button.btn-ghost.dx-state-hover .dx-icon {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-state-hover:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-ghost.dx-state-hover:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter);
}
.dx-dropdownbutton.btn-ghost.dx-state-hover .dx-button-text,
.dx-button.btn-ghost.dx-state-hover .dx-button-text {
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-ghost.dx-button-has-icon:not(.dx-button-has-text),
.dx-button.btn-ghost.dx-button-has-icon:not(.dx-button-has-text) {
  height: 32px !important;
  width: 32px !important;
}
.dx-dropdownbutton.btn-ghost.dx-button-has-icon:not(.dx-button-has-text) .dx-icon,
.dx-button.btn-ghost.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.dx-dropdownbutton.btn-danger,
.dx-button.btn-danger {
  background: var(--white);
  border-color: var(--danger);
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger .dx-button-text,
.dx-button.btn-danger .dx-button-text {
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger .dx-icon,
.dx-button.btn-danger .dx-icon {
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-danger:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--danger-filter);
}
.dx-dropdownbutton.btn-danger.dx-state-focused,
.dx-button.btn-danger.dx-state-focused {
  background: var(--white);
  border-color: var(--danger);
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-state-focused .dx-icon,
.dx-button.btn-danger.dx-state-focused .dx-icon {
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-state-focused:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-danger.dx-state-focused:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--danger-filter);
}
.dx-dropdownbutton.btn-danger.dx-state-focused .dx-button-text,
.dx-button.btn-danger.dx-state-focused .dx-button-text {
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-state-active,
.dx-button.btn-danger.dx-state-active {
  background: var(--white);
  border-color: var(--danger);
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-state-active .dx-icon,
.dx-button.btn-danger.dx-state-active .dx-icon {
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-state-active:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-danger.dx-state-active:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--danger-filter);
}
.dx-dropdownbutton.btn-danger.dx-state-active .dx-button-text,
.dx-button.btn-danger.dx-state-active .dx-button-text {
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-state-disabled,
.dx-button.btn-danger.dx-state-disabled {
  background: var(--white);
  border-color: var(--disabled-primary);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-danger.dx-state-disabled .dx-button-content,
.dx-button.btn-danger.dx-state-disabled .dx-button-content {
  opacity: 1 !important;
}
.dx-dropdownbutton.btn-danger.dx-state-disabled .dx-icon,
.dx-button.btn-danger.dx-state-disabled .dx-icon {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-danger.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-danger.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-danger.dx-state-disabled .dx-button-text,
.dx-button.btn-danger.dx-state-disabled .dx-button-text {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-danger.dx-state-hover,
.dx-button.btn-danger.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--danger);
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-state-hover .dx-icon,
.dx-button.btn-danger.dx-state-hover .dx-icon {
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-state-hover:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-danger.dx-state-hover:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--danger-filter);
}
.dx-dropdownbutton.btn-danger.dx-state-hover .dx-button-text,
.dx-button.btn-danger.dx-state-hover .dx-button-text {
  color: var(--danger);
}
.dx-dropdownbutton.btn-danger.dx-button-has-icon:not(.dx-button-has-text),
.dx-button.btn-danger.dx-button-has-icon:not(.dx-button-has-text) {
  height: 32px !important;
  width: 32px !important;
}
.dx-dropdownbutton.btn-danger.dx-button-has-icon:not(.dx-button-has-text) .dx-icon,
.dx-button.btn-danger.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.dx-dropdownbutton.btn-info,
.dx-button.btn-info {
  background: var(--white);
  border-color: var(--info);
  color: var(--info);
}
.dx-dropdownbutton.btn-info .dx-button-text,
.dx-button.btn-info .dx-button-text {
  color: var(--info);
}
.dx-dropdownbutton.btn-info .dx-icon,
.dx-button.btn-info .dx-icon {
  color: var(--info);
}
.dx-dropdownbutton.btn-info:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-info:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--info-filter);
}
.dx-dropdownbutton.btn-info.dx-state-focused,
.dx-button.btn-info.dx-state-focused {
  background: var(--white);
  border-color: var(--info);
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-state-focused .dx-icon,
.dx-button.btn-info.dx-state-focused .dx-icon {
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-state-focused:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-info.dx-state-focused:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--info-filter);
}
.dx-dropdownbutton.btn-info.dx-state-focused .dx-button-text,
.dx-button.btn-info.dx-state-focused .dx-button-text {
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-state-active,
.dx-button.btn-info.dx-state-active {
  background: var(--white);
  border-color: var(--info);
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-state-active .dx-icon,
.dx-button.btn-info.dx-state-active .dx-icon {
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-state-active:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-info.dx-state-active:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--info-filter);
}
.dx-dropdownbutton.btn-info.dx-state-active .dx-button-text,
.dx-button.btn-info.dx-state-active .dx-button-text {
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-state-disabled,
.dx-button.btn-info.dx-state-disabled {
  background: var(--white);
  border-color: var(--disabled-primary);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-info.dx-state-disabled .dx-button-content,
.dx-button.btn-info.dx-state-disabled .dx-button-content {
  opacity: 1 !important;
}
.dx-dropdownbutton.btn-info.dx-state-disabled .dx-icon,
.dx-button.btn-info.dx-state-disabled .dx-icon {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-info.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-info.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-info.dx-state-disabled .dx-button-text,
.dx-button.btn-info.dx-state-disabled .dx-button-text {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-info.dx-state-hover,
.dx-button.btn-info.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--info);
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-state-hover .dx-icon,
.dx-button.btn-info.dx-state-hover .dx-icon {
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-state-hover:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-info.dx-state-hover:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--info-filter);
}
.dx-dropdownbutton.btn-info.dx-state-hover .dx-button-text,
.dx-button.btn-info.dx-state-hover .dx-button-text {
  color: var(--info);
}
.dx-dropdownbutton.btn-info.dx-button-has-icon:not(.dx-button-has-text),
.dx-button.btn-info.dx-button-has-icon:not(.dx-button-has-text) {
  height: 32px !important;
  width: 32px !important;
}
.dx-dropdownbutton.btn-info.dx-button-has-icon:not(.dx-button-has-text) .dx-icon,
.dx-button.btn-info.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.dx-dropdownbutton.btn-warning,
.dx-button.btn-warning {
  background: var(--white);
  border-color: var(--warning);
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning .dx-button-text,
.dx-button.btn-warning .dx-button-text {
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning .dx-icon,
.dx-button.btn-warning .dx-icon {
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-warning:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--warning-filter);
}
.dx-dropdownbutton.btn-warning.dx-state-focused,
.dx-button.btn-warning.dx-state-focused {
  background: var(--white);
  border-color: var(--warning);
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-state-focused .dx-icon,
.dx-button.btn-warning.dx-state-focused .dx-icon {
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-state-focused:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-warning.dx-state-focused:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--warning-filter);
}
.dx-dropdownbutton.btn-warning.dx-state-focused .dx-button-text,
.dx-button.btn-warning.dx-state-focused .dx-button-text {
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-state-active,
.dx-button.btn-warning.dx-state-active {
  background: var(--white);
  border-color: var(--warning);
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-state-active .dx-icon,
.dx-button.btn-warning.dx-state-active .dx-icon {
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-state-active:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-warning.dx-state-active:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--warning-filter);
}
.dx-dropdownbutton.btn-warning.dx-state-active .dx-button-text,
.dx-button.btn-warning.dx-state-active .dx-button-text {
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-state-disabled,
.dx-button.btn-warning.dx-state-disabled {
  background: var(--white);
  border-color: var(--disabled-primary);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-warning.dx-state-disabled .dx-button-content,
.dx-button.btn-warning.dx-state-disabled .dx-button-content {
  opacity: 1 !important;
}
.dx-dropdownbutton.btn-warning.dx-state-disabled .dx-icon,
.dx-button.btn-warning.dx-state-disabled .dx-icon {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-warning.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-warning.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-warning.dx-state-disabled .dx-button-text,
.dx-button.btn-warning.dx-state-disabled .dx-button-text {
  color: var(--disabled-primary);
}
.dx-dropdownbutton.btn-warning.dx-state-hover,
.dx-button.btn-warning.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--warning);
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-state-hover .dx-icon,
.dx-button.btn-warning.dx-state-hover .dx-icon {
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-state-hover:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-warning.dx-state-hover:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--warning-filter);
}
.dx-dropdownbutton.btn-warning.dx-state-hover .dx-button-text,
.dx-button.btn-warning.dx-state-hover .dx-button-text {
  color: var(--warning);
}
.dx-dropdownbutton.btn-warning.dx-button-has-icon:not(.dx-button-has-text),
.dx-button.btn-warning.dx-button-has-icon:not(.dx-button-has-text) {
  height: 32px !important;
  width: 32px !important;
}
.dx-dropdownbutton.btn-warning.dx-button-has-icon:not(.dx-button-has-text) .dx-icon,
.dx-button.btn-warning.dx-button-has-icon:not(.dx-button-has-text) .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}

.dx-dropdownbutton.btn-icon-default,
.dx-button.btn-icon-default {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-default:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-default:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--gray-900-filter) !important;
}
.dx-dropdownbutton.btn-icon-default .dx-button-content,
.dx-button.btn-icon-default .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-default .dx-icon,
.dx-button.btn-icon-default .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-default.dx-state-focused,
.dx-button.btn-icon-default.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-default.dx-state-active,
.dx-button.btn-icon-default.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-default.dx-state-disabled,
.dx-button.btn-icon-default.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-default.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-default.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-default.dx-state-hover,
.dx-button.btn-icon-default.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-primary,
.dx-button.btn-icon-primary {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-primary:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-primary:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--primary-filter) !important;
}
.dx-dropdownbutton.btn-icon-primary .dx-button-content,
.dx-button.btn-icon-primary .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-primary .dx-icon,
.dx-button.btn-icon-primary .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-primary.dx-state-focused,
.dx-button.btn-icon-primary.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-primary.dx-state-active,
.dx-button.btn-icon-primary.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-primary.dx-state-disabled,
.dx-button.btn-icon-primary.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-primary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-primary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-primary.dx-state-hover,
.dx-button.btn-icon-primary.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-secondary,
.dx-button.btn-icon-secondary {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-secondary:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-secondary:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--secondary-filter) !important;
}
.dx-dropdownbutton.btn-icon-secondary .dx-button-content,
.dx-button.btn-icon-secondary .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-secondary .dx-icon,
.dx-button.btn-icon-secondary .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-secondary.dx-state-focused,
.dx-button.btn-icon-secondary.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-secondary.dx-state-active,
.dx-button.btn-icon-secondary.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-secondary.dx-state-disabled,
.dx-button.btn-icon-secondary.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-secondary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-secondary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-secondary.dx-state-hover,
.dx-button.btn-icon-secondary.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-tertiary,
.dx-button.btn-icon-tertiary {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-tertiary:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-tertiary:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--tertiary-filter) !important;
}
.dx-dropdownbutton.btn-icon-tertiary .dx-button-content,
.dx-button.btn-icon-tertiary .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-tertiary .dx-icon,
.dx-button.btn-icon-tertiary .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-tertiary.dx-state-focused,
.dx-button.btn-icon-tertiary.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-tertiary.dx-state-active,
.dx-button.btn-icon-tertiary.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-tertiary.dx-state-disabled,
.dx-button.btn-icon-tertiary.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-tertiary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-tertiary.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-tertiary.dx-state-hover,
.dx-button.btn-icon-tertiary.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-info,
.dx-button.btn-icon-info {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-info:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-info:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--info-filter) !important;
}
.dx-dropdownbutton.btn-icon-info .dx-button-content,
.dx-button.btn-icon-info .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-info .dx-icon,
.dx-button.btn-icon-info .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-info.dx-state-focused,
.dx-button.btn-icon-info.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-info.dx-state-active,
.dx-button.btn-icon-info.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-info.dx-state-disabled,
.dx-button.btn-icon-info.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-info.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-info.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-info.dx-state-hover,
.dx-button.btn-icon-info.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-danger,
.dx-button.btn-icon-danger {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-danger:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-danger:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--danger-filter) !important;
}
.dx-dropdownbutton.btn-icon-danger .dx-button-content,
.dx-button.btn-icon-danger .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-danger .dx-icon,
.dx-button.btn-icon-danger .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-danger.dx-state-focused,
.dx-button.btn-icon-danger.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-danger.dx-state-active,
.dx-button.btn-icon-danger.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-danger.dx-state-disabled,
.dx-button.btn-icon-danger.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-danger.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-danger.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-danger.dx-state-hover,
.dx-button.btn-icon-danger.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-white,
.dx-button.btn-icon-white {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-white:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-white:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--white-filter) !important;
}
.dx-dropdownbutton.btn-icon-white .dx-button-content,
.dx-button.btn-icon-white .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-white .dx-icon,
.dx-button.btn-icon-white .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-white.dx-state-focused,
.dx-button.btn-icon-white.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-white.dx-state-active,
.dx-button.btn-icon-white.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-white.dx-state-disabled,
.dx-button.btn-icon-white.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-white.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-white.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-white.dx-state-hover,
.dx-button.btn-icon-white.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-colorful,
.dx-button.btn-icon-colorful {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-colorful:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-colorful:not(.btn-icon-colorful) img.dx-icon {
  filter: transparent !important;
}
.dx-dropdownbutton.btn-icon-colorful .dx-button-content,
.dx-button.btn-icon-colorful .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-colorful .dx-icon,
.dx-button.btn-icon-colorful .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-colorful.dx-state-focused,
.dx-button.btn-icon-colorful.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-colorful.dx-state-active,
.dx-button.btn-icon-colorful.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-colorful.dx-state-disabled,
.dx-button.btn-icon-colorful.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-colorful.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-colorful.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-colorful.dx-state-hover,
.dx-button.btn-icon-colorful.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-warning,
.dx-button.btn-icon-warning {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-warning:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-warning:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--warning-filter) !important;
}
.dx-dropdownbutton.btn-icon-warning .dx-button-content,
.dx-button.btn-icon-warning .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-warning .dx-icon,
.dx-button.btn-icon-warning .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-warning.dx-state-focused,
.dx-button.btn-icon-warning.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-warning.dx-state-active,
.dx-button.btn-icon-warning.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-warning.dx-state-disabled,
.dx-button.btn-icon-warning.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-warning.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-warning.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-warning.dx-state-hover,
.dx-button.btn-icon-warning.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}
.dx-dropdownbutton.btn-icon-black,
.dx-button.btn-icon-black {
  height: 32px !important;
  width: 32px !important;
  color: var(--gray-900);
  background: var(--transparent);
  border-color: var(--transparent);
}
.dx-dropdownbutton.btn-icon-black:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-black:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--black-filter) !important;
}
.dx-dropdownbutton.btn-icon-black .dx-button-content,
.dx-button.btn-icon-black .dx-button-content {
  padding: 0 !important;
}
.dx-dropdownbutton.btn-icon-black .dx-icon,
.dx-button.btn-icon-black .dx-icon {
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
}
.dx-dropdownbutton.btn-icon-black.dx-state-focused,
.dx-button.btn-icon-black.dx-state-focused {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-black.dx-state-active,
.dx-button.btn-icon-black.dx-state-active {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
}
.dx-dropdownbutton.btn-icon-black.dx-state-disabled,
.dx-button.btn-icon-black.dx-state-disabled {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--disabled-primary);
  opacity: unset;
}
.dx-dropdownbutton.btn-icon-black.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon,
.dx-button.btn-icon-black.dx-state-disabled:not(.btn-icon-colorful) img.dx-icon {
  filter: var(--disabled-primary-filter);
}
.dx-dropdownbutton.btn-icon-black.dx-state-hover,
.dx-button.btn-icon-black.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}

.dx-popup-normal {
  border-radius: 8px !important;
}
.dx-popup-normal .dx-popup-title {
  border-bottom: none !important;
  margin: 0;
  padding: 0;
}
.dx-popup-normal .dx-popup-title .dx-button {
  margin-top: 8px;
  margin-right: 8px;
}
.dx-popup-normal .dx-popup-title .dx-button.dx-closebutton {
  border: none;
  height: 32px !important;
  width: 32px !important;
}
.dx-popup-normal .dx-popup-title .dx-button.dx-closebutton.dx-state-active {
  background-color: transparent !important;
}
.dx-popup-normal .dx-popup-title .dx-button-has-icon .dx-icon {
  font-size: 20px !important;
}
.dx-popup-normal .dx-popup-content {
  border-bottom: none !important;
  padding: 0 38px;
  font-family: var(--font-family-light);
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  overflow-wrap: break-word;
}
.dx-popup-normal .dx-popup-content h3 {
  margin-bottom: 20px;
}
.dx-popup-normal .dx-popup-bottom {
  border-top: none !important;
  padding: 38px 38px 30px 38px;
}

.dx-dropdowneditor-overlay .dx-popup-content {
  padding: 0;
}

.dx-popup-content-p0 .dx-popup-content {
  padding: 0;
  margin-top: 4px;
}

.dx-overlay-shader {
  background-color: var(--transparent-backdrop);
}

.dx-header-filter-menu .dx-toolbar-after {
  width: 100%;
}
.dx-header-filter-menu .dx-toolbar-after .dx-toolbar-button:first-child .dx-button {
  background: var(--secondary);
  border-color: var(--secondary);
  color: var(--gray-900);
  border-radius: 50px !important;
}
.dx-header-filter-menu .dx-toolbar-after .dx-toolbar-button:first-child .dx-button.dx-state-hover {
  background: var(--hover-secondary);
  border-color: var(--hover-secondary);
}
.dx-header-filter-menu .dx-toolbar-after .dx-toolbar-button:nth-child(2) .dx-button {
  background: var(--transparent);
  border-color: var(--transparent);
  color: var(--gray-900);
  border-radius: 50px !important;
}
.dx-header-filter-menu .dx-toolbar-after .dx-toolbar-button:nth-child(2) .dx-button.dx-state-hover {
  background: var(--gray-100);
  border-color: var(--gray-100);
}

.dx-switch {
  height: 24px;
  width: 52px;
}
.dx-switch .dx-switch-container {
  height: 24px;
  width: 52px;
}
.dx-switch .dx-switch-container .dx-switch-handle {
  width: 18px;
  height: 18px;
  background-color: var(--white);
  flex-basis: 18px;
  box-shadow: unset;
}
.dx-switch .dx-switch-container .dx-switch-handle::before {
  display: none;
}
.dx-switch .dx-switch-container .dx-switch-inner {
  align-items: center;
}
.dx-switch .dx-switch-container::before {
  margin: 0;
  height: 24px;
  width: 52px;
}
.dx-switch:not(.dx-switch-on-value) .dx-switch-container::before {
  background-color: var(--gray-500);
}
.dx-switch:not(.dx-switch-on-value) .dx-switch-container .dx-switch-inner .dx-switch-on {
  width: 55px;
}
.dx-switch.dx-switch-on-value .dx-switch-container::before {
  background-color: var(--primary);
}
.dx-switch.dx-switch-on-value .dx-switch-container .dx-switch-inner .dx-switch-on {
  width: 49px;
}
.dx-switch.dx-state-disabled .dx-switch-container::before {
  background-color: var(--disabled-primary);
}
.dx-switch.dx-state-disabled .dx-switch-container .dx-switch-handle {
  background-color: var(--disabled-tertiary);
}
.dx-switch.dx-state-disabled.dx-switch-on-value .dx-switch-container::before {
  background-color: var(--disabled-tertiary);
}
.dx-switch.dx-state-disabled.dx-switch-on-value .dx-switch-container .dx-switch-handle {
  background-color: var(--disabled-primary);
}
.dx-switch.switch-small {
  height: 16px;
  width: 34px;
}
.dx-switch.switch-small .dx-switch-container {
  height: 16px;
  width: 34px;
}
.dx-switch.switch-small .dx-switch-container .dx-switch-handle {
  width: 12px;
  height: 12px;
  flex-basis: 12px;
}
.dx-switch.switch-small .dx-switch-container::before {
  height: 16px;
  width: 34px;
}
.dx-switch.switch-small:not(.dx-switch-on-value) .dx-switch-container .dx-switch-inner .dx-switch-on {
  width: 37px;
}
.dx-switch.switch-small.dx-switch-on-value .dx-switch-container .dx-switch-inner .dx-switch-on {
  width: 31px;
}

.grid-row-size-extra-small .dx-treelist .dx-treelist-rowsview .dx-row > td {
  height: 33px;
  padding-top: 0;
  padding-bottom: 0;
}
.grid-row-size-extra-small .dx-treelist-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 33px;
}

.grid-row-size-small .dx-treelist .dx-treelist-rowsview .dx-row > td {
  height: 48px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.grid-row-size-small .dx-treelist-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 48px;
}

.grid-row-size-medium .dx-treelist .dx-treelist-rowsview .dx-row > td {
  height: 56px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.grid-row-size-medium .dx-treelist-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 56px;
}

.grid-row-size-large .dx-treelist .dx-treelist-rowsview .dx-row > td {
  height: 64px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.grid-row-size-large .dx-treelist-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 64px;
}

.dx-treelist-filter-row {
  background-color: transparent;
}

.dx-treelist .dx-editor-cell .dx-texteditor {
  background-color: transparent !important;
}

.dx-treelist .dx-header-row {
  height: 40px !important;
}

.dx-treelist .dx-row > td {
  padding: 6px 15px;
}

.dx-treelist .dx-treelist-table .dx-header-row > td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dx-treelist .dx-treelist-filter-row .dx-editor-cell .dx-texteditor {
  height: 40px;
  line-height: 40px;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 40px;
}

.dx-treelist-focus-overlay {
  max-height: 40px !important;
}

.dx-treelist .dx-treelist-filter-row {
  height: 40px;
}

.dx-row .dx-treelist-filter-row {
  height: 40px;
}

.dx-treelist .dx-treelist-headers .dx-treelist-content .dx-treelist-table .dx-header-row > .dx-treelist-select-all {
  padding-top: 0.6rem;
}

.dx-treelist-headers.dx-header-multi-row .dx-treelist-content .dx-treelist-table .dx-row.dx-header-row > td {
  padding-top: 0px;
}

.dx-treelist .dx-treelist-headers {
  box-shadow: 0px 10px 8px -13px var(--black) !important;
  z-index: 3;
}

.dx-treelist-container .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-treelist-container .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none,
.dx-treelist-container .dx-treelist-headers .dx-row td.dx-pointer-events-none,
.dx-treelist-container .dx-treelist-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-treelist-container .dx-treelist-rowsview .dx-freespace-row td.dx-pointer-events-none {
  border-left: 1px solid var(--surface-secondary);
  border-right: 1px solid var(--surface-secondary);
}

.dx-treelist-headers,
.dx-treelist-headers > .dx-treelist-content {
  border-radius: 8px 8px 0 0 !important;
}

.dx-treelist-rowsview {
  border-radius: 0 0 8px 8px !important;
}

.dx-treelist-rowsview .dx-treelist-collapsed span::before {
  content: "";
  background: url("../../media/svg/icons/chevronRight.svg") no-repeat center !important;
  background-size: 20px 20px !important;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.dx-treelist-rowsview .dx-treelist-expanded span::before {
  content: "";
  background: url("../../media/svg/icons/chevronDown.svg") no-repeat center !important;
  background-size: 20px 20px !important;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.dx-treelist-container .dx-header-filter::before {
  content: "";
  background: url("../../media/svg/icons/filter.svg") no-repeat center !important;
  background-size: 20px 20px !important;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.dx-treelist-headers .dx-treelist-cell {
  border-right: none !important;
}

.dx-treelist-rowsview .dx-treelist-cell {
  border-right: none !important;
}

.dx-treelist {
  border-radius: 8px 8px 0 0;
  background-color: transparent;
  font-family: var(--font-family-regular);
  font-weight: 400;
}

.dx-treelist .dx-header-row > .dx-command-edit,
.dx-treelist .dx-header-row > td .dx-treelist-text-content {
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
}

.dx-treelist .dx-header-row > td:not(:last-child):after,
.dx-treelist-filter-row > td:not(:last-child):after {
  content: "";
  position: absolute;
  bottom: 15px;
  top: 15px;
  right: 0px;
  border-right: 1px solid var(--surface-secondary);
}

.dx-treelist-headers.dx-header-multi-row .dx-treelist-content:not(.dx-treelist-content-fixed) .dx-header-row > td {
  border-right: 1px solid var(--white) !important;
}

.dx-treelist-filter-row > td:has(+ .dx-pointer-events-none):after,
.dx-treelist-filter-row > td:has(+ .dx-hidden-cell):after,
.dx-treelist-filter-row > td.dx-command-drag:after,
.dx-treelist-filter-row > td:has(+ .dx-command-edit):after {
  border-right: 1px solid transparent;
}

.dx-treelist-rowsview .dx-row {
  border: 0;
}

.dx-treelist-filter-row > td {
  position: relative;
}

.dx-treelist-focus-overlay:after {
  visibility: hidden;
}

.dx-treelist .dx-treelist-rowsview .dx-row > td.dx-editor-cell {
  padding-top: 0;
  padding-bottom: 0;
}

.dx-treelist .dx-editor-cell.dx-focused .dx-editor-container .dx-texteditor:not(.dx-invalid)::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--primary);
  left: 0;
  right: 1px;
}

.dx-treelist .dx-editor-cell .dx-editor-container .dx-texteditor.dx-invalid::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--danger);
  left: 0;
  right: 1px;
}

.dx-treelist .dx-treelist-headers.dx-header-multi-row .dx-header-row > td:not([rowspan]):has(+ td[rowspan]):after {
  border-right: 1px solid var(--white) !important;
}

.dx-treelist .dx-treelist-rowsview .dx-data-row .dx-command-drag {
  cursor: grab;
}

.treelist-no-pager .dx-treelist-headers,
.treelist-no-pager .dx-treelist-headers > .dx-treelist-content,
.treelist-no-pager {
  border-radius: 0 !important;
}

.dx-treelist-columns-separator {
  z-index: 1001;
}

.dx-accordion-item-opened {
  margin: unset;
}

.dx-accordion {
  background-color: transparent !important;
  border-top: 1px solid var(--surface-secondary);
  border-bottom: 1px solid var(--surface-secondary);
  margin: 0px;
}
.dx-accordion.hidden-expander .dx-accordion-item-title {
  cursor: auto;
}
.dx-accordion.hidden-expander .dx-accordion-item-title::before {
  content: "" !important;
  right: 0 !important;
  background: unset !important;
}

.dx-accordion-item {
  box-shadow: none !important;
}

.dx-accordion-item > .dx-accordion-item-title,
.dx-accordion-item.dx-state-active > .dx-accordion-item-title,
.dx-accordion-item.dx-state-focused > .dx-accordion-item-title,
.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: transparent !important;
}

.dx-accordion-item > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-active > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-focused > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: transparent !important;
}

.dx-accordion-item-opened > .dx-accordion-item-title {
  height: 48px;
}

.dx-accordion-item-title::before {
  right: 22px;
  top: 2px !important;
  font-size: 35px;
  color: var(--gray-300);
}

.dx-accordion-wrapper .dx-empty-message {
  font-size: 16px;
}

pps-new-file .dx-accordion-wrapper .dx-empty-message {
  font-size: 16px;
  padding: 10px 0;
  font-family: var(--font-family-light);
}

.dx-accordion-item-title::before {
  content: "";
  background: url("../../media/svg/icons/chevronDown.svg") no-repeat center !important;
  background-size: 24px 24px !important;
  width: 24px;
  height: 24px;
  display: inline-block;
  top: 50%;
  transform: translateY(50%);
}

.dx-accordion-item-opened > .dx-accordion-item-title::before {
  content: "";
  background: url("../../media/svg/icons/chevronUp.svg") no-repeat center !important;
  background-size: 24px 24px !important;
  width: 24px;
  height: 24px;
  display: inline-block;
  top: 50%;
  transform: translateY(50%);
}

.dx-accordion-item-title {
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: var(--gray-900);
}

.dx-accordion-item.dx-accordion-item-opened > .dx-accordion-item-title {
  background-color: var(--transparent-tertiary) !important;
}

.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: var(--transparent-secondary) !important;
}

.dx-accordion .dx-accordion-item.dx-accordion-item-opened {
  border-left: 1px solid var(--transparent-secondary) !important;
  border-right: 1px solid var(--transparent-secondary) !important;
  border-bottom: 2px solid var(--gray-500) !important;
}

.dx-template-wrapper.dx-item-content.dx-accordion-item-body {
  padding-bottom: 32px;
}

.dropzone-external {
  color: var(--black);
  cursor: pointer;
  height: 55px;
  border: 2px dashed var(--surface-secondary);
}
.dropzone-external.disabled {
  cursor: default;
  opacity: 0.4;
}

.dx-htmleditor {
  /* custom style */
}
.dx-htmleditor .dx-toolbar-item .dx-button-normal {
  border-width: unset;
  border-style: unset;
}
.dx-htmleditor .dx-toolbar-after .dx-button-normal {
  border-width: unset;
  border-style: unset;
}
.dx-htmleditor.app-html-editor-style {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #5a5b5c;
}
.dx-htmleditor.app-html-editor-style .dx-htmleditor-toolbar-wrapper:first-child {
  border: none;
}
.dx-htmleditor.app-html-editor-style.dx-state-focused, .dx-htmleditor.app-html-editor-style.dx-state-disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 1;
}
.dx-htmleditor.app-html-editor-style.dx-state-focused {
  border-bottom: 1px solid var(--primary);
}
.dx-htmleditor.app-html-editor-style.dx-state-disabled {
  border-radius: 4px;
  border: 1px solid var(--transparent-secondary);
}
.dx-htmleditor.app-html-editor-style.editable .dx-htmleditor-content {
  border-radius: 4px 4px 0 0;
  background: var(--transparent-primary);
  margin: 0;
}
.dx-htmleditor.dx-state-readonly {
  border: 1px solid var(--transparent-secondary) !important;
  border-radius: 4px;
}

.dx-dropdowneditor-icon::before {
  content: "" !important;
  background: url("../../media/svg/icons/chevronDown.svg") no-repeat center;
  background-size: 20px 20px !important;
  width: 20px !important;
  height: 20px !important;
  display: inline-block !important;
}

.dx-dropdowneditor-active .dx-dropdowneditor-icon::before {
  content: "" !important;
  background: url("../../media/svg/icons/chevronUp.svg") no-repeat center;
  background-size: 20px 20px !important;
  width: 20px !important;
  height: 20px !important;
  display: inline-block !important;
}

.dx-selectbox .dx-textbox.dx-state-readonly.dx-textbox.dx-state-invisible,
.dx-selectbox .dx-textbox.dx-state-readonly,
.dx-selectbox .dx-textbox.dx-state-invisible {
  display: none !important;
}
.dx-selectbox .dx-texteditor-input:read-only {
  cursor: text;
}
.dx-selectbox .dx-dropdowneditor-field-template-wrapper .dx-template-wrapper:not(:has(.dropbox-no-template-padding)) {
  padding-top: 19px;
  padding-left: 16px;
  min-height: 46px;
}
.dx-selectbox.dx-state-invisible .dx-selectbox-container {
  display: none !important;
}

.internal-external.internal-value .dx-texteditor-input-container input {
  color: var(--primary) !important;
}

.internal-external.external-value .dx-texteditor-input-container input {
  color: var(--danger) !important;
}

.dx-datebox .dx-dropdowneditor-icon::before {
  content: "" !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: contain;
}
.dx-datebox.dx-datebox-date .dx-dropdowneditor-icon::before {
  background: url("../../media/svg/icons/calendarDot.svg") no-repeat center center;
}
.dx-datebox.dx-datebox-time .dx-dropdowneditor-icon::before {
  background: url("../../media/svg/icons/clock.svg") no-repeat center center;
}
.dx-datebox.pps-expired-deadline-warning {
  background-color: var(--warning-deadline) !important;
}
.dx-datebox.pps-expired-deadline-danger {
  background-color: var(--danger-deadline) !important;
}

dx-pivot-grid .dx-area-description-cell .dx-button {
  height: 32px !important;
  width: 32px !important;
}
dx-pivot-grid .dx-area-description-cell .dx-button.dx-state-hover, dx-pivot-grid .dx-area-description-cell .dx-button.dx-state-active {
  border: none;
}
dx-pivot-grid .dx-area-description-cell .dx-button.dx-state-active {
  background-color: transparent !important;
}

.dx-tooltip-wrapper .dx-overlay-content {
  background-color: var(--gray-700);
  border-radius: 2px !important;
}

.dx-tooltip-wrapper:has(.event-wrapper) .dx-overlay-content {
  background: white;
  border: 2px solid var(--gray-700);
}

.dx-tooltip-wrapper .dx-overlay-content::before {
  display: inline-block;
  height: 100%;
  content: "";
  vertical-align: middle;
}

.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  padding: 8px 12px;
  font-family: var(--font-family-regular);
  font-size: 12px;
  color: var(--white);
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.dx-tooltip-wrapper.dx-popover-wrapper .dx-popover-arrow {
  display: block !important;
}

.dx-popover-wrapper .dx-popover-arrow::after {
  background-color: var(--gray-700);
}

.dx-loadindicator-wrapper {
  background-image: url("/assets/media/svg/icons/spinner.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  transform-origin: 50% 50%;
  background-size: contain;
  -webkit-animation: spin 1568ms linear infinite;
  animation: spin 1568ms linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dx-loadindicator-content {
  display: none;
}

.dx-tagbox .dx-tag {
  margin-top: 12px;
}
.dx-tagbox .dx-tag-content {
  padding: 2px 35px 2px 16px;
  background-color: var(--quarternary);
  color: var(--primary);
}
.dx-tagbox .dx-tag-content .multi-tag-counter {
  color: var(--white);
  background-color: var(--primary);
  border-radius: 50%;
  padding: 0 5px;
}
.dx-tagbox .dx-tag-content .dx-tag-remove-button::before {
  background-color: var(--quarternary);
  color: var(--primary);
}
.dx-tagbox .dx-tag-content:hover {
  background-color: var(--quarternary);
  color: var(--primary);
}
.dx-tagbox .dx-tag-content:hover .dx-tag-remove-button::before {
  background-color: var(--quarternary);
  color: var(--primary);
}
.dx-tagbox.dx-state-disabled .dx-tag-content {
  padding: 2px 35px 2px 16px;
  background-color: var(--surface-tertiary);
  color: var(--gray-200);
}
.dx-tagbox.dx-state-disabled .dx-tag-content .multi-tag-counter {
  color: var(--white);
  background-color: var(--gray-200);
  border-radius: 50%;
  padding: 0 5px;
}
.dx-tagbox.dx-state-disabled .dx-tag-content .dx-tag-remove-button::before {
  background-color: var(--surface-tertiary);
  color: var(--gray-200);
}

.dx-tagbox-popup-wrapper .dx-item.dx-list-item .dx-item-content {
  text-align: left;
}
.dx-tagbox-popup-wrapper .dx-list-select-all {
  display: flex;
  justify-content: space-between;
}
.dx-tagbox-popup-wrapper .dx-list-select-all .dx-list-select-all-label {
  padding-left: 16px;
  text-align: left;
}

.dx-gantt {
  border-radius: 8px;
}

.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task {
  height: 18px;
  line-height: 18px;
  background-color: var(--info);
}

.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task.dx-gantt-parent {
  height: 18px;
  line-height: 18px;
  background-color: var(--success);
  border-left-color: var(--success);
  border-right-color: var(--success);
  border-top-color: var(--success);
}

.dx-gantt .dx-row td[grid-cell-warning] {
  background-color: var(--warning-secondary);
  color: var(--warning);
}

.dx-gantt .dx-gantt-tm.current-time {
  border-left: 2px solid var(--primary);
}

.dx-scheduler .dx-scheduler-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.dx-scheduler .dx-scheduler-work-space {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dx-scheduler.scheduler-custom-appointment .dx-scheduler-appointment {
  background: none !important;
}
.dx-scheduler .dx-toolbar-items-container .dx-buttongroup-item-content,
.dx-scheduler .dx-toolbar-items-container .dx-dropdownbutton {
  border: none;
}
.dx-scheduler .dx-toolbar-items-container .dx-dropdownbutton {
  border: 1px solid var(--primary);
}
.dx-scheduler .dx-toolbar-items-container .dx-dropdownbutton .dx-buttongroup-item {
  border-radius: 50px;
}
.dx-scheduler .dx-scheduler-date-table-row .dx-scheduler-date-table-cell:nth-child(6),
.dx-scheduler .dx-scheduler-date-table-row .dx-scheduler-date-table-cell:nth-child(7) {
  background: var(--gray-100);
}

@media (min-width: 360px) and (max-width: 719.9px) {
  .pps-custom-scheduler {
    height: calc(100vh - var(--pps-devexpress-scheduler--height-phone, 0));
    max-height: calc(100vh - var(--pps-devexpress-scheduler--max-height-phone, 0));
  }
}
@media (min-width: 720px) and (max-width: 1079.9px) {
  .pps-custom-scheduler {
    height: calc(100vh - var(--pps-devexpress-scheduler--height-tablet, 0));
    max-height: calc(100vh - var(--pps-devexpress-scheduler--max-height-tablet, 0));
  }
}
@media (min-width: 1080px) {
  .pps-custom-scheduler {
    height: calc(100vh - var(--pps-devexpress-scheduler--height-desktop, 0));
    max-height: calc(100vh - var(--pps-devexpress-scheduler--max-height-desktop, 0));
  }
}
@media (min-width: 1080px) {
  .content-wrapper {
    padding-left: 270px;
    padding-top: calc(95px + var(--release-info-label-top));
  }
  .menu-minimize .content-wrapper {
    padding-left: 56px;
  }
}
@media (max-width: 1079.9px) {
  .content-wrapper {
    padding-top: calc(85px + var(--release-info-label-top));
  }
}
.page-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
}
.page-wrapper .page {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
}
.page-wrapper .page .content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  min-width: 0;
}
.page-wrapper .page .content-wrapper .content {
  background-color: var(--body-backgroud);
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.page-with-footer {
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.page-without-footer {
  overflow-y: auto;
  overflow-x: hidden;
}

.page-content-wrapper {
  background-color: var(--body-backgroud);
}

@media (min-width: 1080px) {
  .content {
    height: calc(100vh - 65px - 30px - var(--release-info-label-top)) !important;
  }
  .page-without-footer {
    height: calc(100vh - 65px - 30px - var(--release-info-label-top)) !important;
  }
  .page-without-footer-with-subheader {
    height: calc(100vh - 65px - 70px - 30px - var(--release-info-label-top)) !important;
  }
  .page-with-footer {
    height: calc(100vh - 65px - 56px - 30px - var(--release-info-label-top)) !important;
  }
}
@media (max-width: 1079.9px) {
  .content {
    height: calc(100vh - 55px - 30px - var(--release-info-label-top)) !important;
  }
  .page-without-footer {
    height: calc(100vh - 55px - 30px - var(--release-info-label-top)) !important;
  }
  .page-with-footer {
    height: calc(100vh - 55px - 56px - 30px - var(--release-info-label-top)) !important;
  }
  .page-without-footer-with-subheader {
    height: calc(100vh - 55px - 70px - 30px - var(--release-info-label-top)) !important;
  }
}
.footer {
  background-color: var(--white);
  border-top: 1px solid var(--border-color-light);
  height: 56px;
  padding-top: 0.75rem !important;
}
.footer .footer-actions > *:not(:last-child) {
  margin-right: 16px;
}

.subheader {
  border-bottom: 1px solid var(--border-color-light);
}
.subheader .subheader-actions > *:not(:last-child) {
  margin-right: 24px;
}

@media (min-width: 360px) and (max-width: 719.9px) {
  .subheader {
    padding: 16px var(--mdc-layout-grid-margin-phone);
  }
}
@media (min-width: 720px) and (max-width: 1079.9px) {
  .subheader {
    padding: 16px var(--mdc-layout-grid-margin-tablet);
  }
}
@media (min-width: 1080px) {
  .subheader {
    padding: 16px var(--mdc-layout-grid-margin-desktop);
  }
}
#kt_error_wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
#kt_error_wrapper .logo {
  height: 75px;
  width: 80px;
}

@media (max-width: 1079.9px) {
  .error-content {
    grid-column-start: 1;
  }
}
@media (min-width: 1080px) {
  .error-content {
    grid-column-start: 3;
  }
}
.document-types-devexpress-grid {
  height: 500px;
}

@media (min-width: 720px) and (max-width: 1079.9px) {
  .document-types-devexpress-grid {
    height: 500px;
  }
}
@media (min-width: 1080px) {
  .document-types-devexpress-grid {
    height: calc(100vh - 65px - var(--mdc-layout-grid-margin-desktop) * 2 - 30px - 56px - 305px);
  }
}
pps-positions-list .dx-datagrid .dx-pager,
pps-device-tables-edit .dx-datagrid .dx-pager {
  background-color: var(--white) !important;
}

pps-repair-scope .page-content,
pps-device-settings .page-content {
  grid-column-start: 1;
}
pps-repair-scope .shape-selection-tree,
pps-device-settings .shape-selection-tree {
  width: 270px;
  float: left;
}
pps-repair-scope .page-content-wrapper,
pps-device-settings .page-content-wrapper {
  border-left: 1px solid var(--border-color-light);
}

pps-shape-selection-tree .tree-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
pps-shape-selection-tree .tree-wrapper .tree {
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px !important;
}
pps-shape-selection-tree .tree-wrapper .root-items-wrapper {
  display: flex;
  flex-direction: column;
}
pps-shape-selection-tree .tree-wrapper .root-item {
  padding: 16px;
  display: flex;
  cursor: pointer;
}
pps-shape-selection-tree .tree-wrapper .child-item-wrapper {
  height: 40px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
pps-shape-selection-tree .tree-wrapper .child-item {
  cursor: pointer;
  display: flex;
  align-items: center;
}
pps-shape-selection-tree .tree-wrapper .tree-scroll-view {
  height: calc(100vh - 65px - 30px - 56px);
}

pps-summary-table-by-design .header-data-list div:not(:last-child):after,
pps-summary-table-by-position .header-data-list div:not(:last-child):after,
pps-repair-scope-head .header-data-list div:not(:last-child):after {
  content: "/";
  margin: 0 7px 0 7px;
}

pps-repair-scope-part .repair-scope-part-footer {
  height: 55px;
}
pps-repair-scope-part .repair-scope-part-param {
  padding-left: 20px;
  padding-right: 20px;
}

pps-repair-scope-part .image-wrapper,
pps-device-tables-edit .image-wrapper {
  width: 100%;
  height: auto;
}
pps-repair-scope-part .image-wrapper img,
pps-device-tables-edit .image-wrapper img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: 450px;
}

.dx-popup-wrapper.dx-popup-content-p2 > .dx-overlay-content {
  padding: 0;
  border-radius: 8px;
}
.dx-popup-wrapper.dx-popup-content-p2 .notification-panel-header {
  padding: 0 16px;
  height: 60px !important;
}
.dx-popup-wrapper.dx-popup-content-p2 .notification-panel-header .dx-button .dx-button-content {
  padding: 6px 0 6px 10px;
}
.dx-popup-wrapper.dx-popup-content-p2 .notification-panel-header .dx-button.btn-tertiary .dx-button-text {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--font-family-bold);
  line-height: 16px;
  color: var(--gray-700);
}
.dx-popup-wrapper.dx-popup-content-p2 .notification-panel-header .dx-button .fa-question-circle {
  color: var(--primary);
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content {
  padding: 0;
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content .notification-panel-item {
  height: 65px;
  padding: 0 18px !important;
  border-bottom: 2px solid var(--surface-secondary);
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content .notification-panel-item:hover {
  background: var(--surface-primary);
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content .notification-panel-item .svg-icon-tertiary {
  height: 20px;
  width: 20px;
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content .notification-panel-item-content {
  width: calc(100% - 60px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-family-bold);
  font-size: 16px;
  line-height: 24px;
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content .notification-panel-detail-title {
  min-height: 65px;
  width: 100%;
  padding: 0 18px;
  border-bottom: 2px solid var(--surface-secondary);
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content .notification-panel-detail-title .notification-panel-detail-title-content {
  width: calc(100% - 60px);
  font-family: var(--font-family-bold);
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content .notification-panel-detail-title img {
  width: 20px;
  height: 20px;
}
.dx-popup-wrapper.dx-popup-content-p2 .dx-popup-content .notification-panel-detail-content .white-space-line {
  overflow: hidden;
  white-space: pre-line;
}
.dx-popup-wrapper.dx-popup-content-p2 .notification-panel-footer {
  border-top: 1px solid var(--surface-secondary);
  height: 56px;
  font-family: var(--font-family-bold);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: var(--gray-700);
}
.dx-popup-wrapper.dx-popup-content-p2 .notification-panel-footer img {
  width: 22px;
}

pps-drawing-documentation-kinds .dx-datagrid {
  background-color: var(--white);
}
pps-drawing-documentation-kinds .dx-datagrid-borders > .dx-datagrid-total-footer .dx-command-expand.dx-datagrid-group-space {
  background-color: var(--white);
}
pps-drawing-documentation-kinds .dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
  background-color: var(--white);
}

@media (min-width: 1080px) {
  #dispensingApp .header {
    height: 65px;
  }
  #dispensingApp .header .header-brand {
    height: 65px;
  }
  #dispensingApp .header .header-brand .header-logo {
    min-height: 65px;
    min-width: 65px;
  }
  #dispensingApp .header .header-brand .header-logo .pps-g2-logo {
    height: 65px;
  }
  #dispensingApp .header .topbar {
    height: 65px;
  }
  #dispensingApp .header .topbar .topbar-item {
    font-size: 12px;
  }
  #dispensingApp .header .topbar .circle-icon .dx-icon {
    border-radius: 50%;
  }
  #dispensingApp .footer {
    height: 76px;
    padding-block: 16px;
  }
  #dispensingApp .content-wrapper {
    padding: 95px 0 0;
  }
  #dispensingApp .content {
    overflow-y: auto;
    height: calc(100vh - 65px - 30px) !important;
  }
  #dispensingApp .page-without-footer {
    height: calc(100vh - 65px - 30px) !important;
  }
  #dispensingApp .page-with-footer {
    height: calc(100vh - 65px - 76px - 30px) !important;
  }
  #dispensingApp .dispensing-container {
    width: min(100% - 3rem, 92vw);
    margin-inline: auto;
  }
}
@media (max-width: 1079.9px) {
  #dispensingApp .footer {
    height: 76px;
  }
  #dispensingApp .content-wrapper {
    padding: 95px 0 0;
  }
  #dispensingApp .content {
    overflow-y: auto;
    height: calc(100vh - 65px - 30px) !important;
  }
  #dispensingApp .page-without-footer {
    height: calc(100vh - 65px - 30px) !important;
  }
  #dispensingApp .page-with-footer {
    height: calc(100vh - 65px - 76px - 30px) !important;
  }
}
pps-work-coordination .dashboard-item {
  border: 1px solid var(--gray-100);
  border-radius: 16px;
  padding: 16px;
  background-color: var(--transparent-primary);
  min-width: 250px;
  height: 150px;
}
pps-work-coordination .content-text {
  font-size: 48px;
}