$transition:
  left 0.3s ease,
  right 0.3s ease,
  bottom 0.3s ease,
  top 0.3s ease;

.drawer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  box-shadow: 0px 1px 9px -3px rgb(0 0 0 / 25%);
  z-index: 1001;
  position: fixed;
  top: 0;
  bottom: 0;
  transition: $transition;

  &__form {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
  }

  &__content {
    flex-grow: 1;
    overflow: hidden;
    padding: 0px 19px 19px 19px;
  }

  &__header {
    padding: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &.drawer-right {
    left: auto;

    &.drawer-on {
      transition: $transition;
      right: 0;
    }
  }

  &__footer {
    border-top: 1px solid var(--border-color-light);
    height: max-content;
    padding: 19px;

    .footer-actions > *:not(:last-child) {
      margin-right: 20px;
    }
  }
}

.drawer-overlay {
  background-color: var(--transparent-backdrop);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1000;
  animation: animation-offcanvas-fade-in 0.6s ease 1;
}

@each $breakpoint in map-keys($mdc-layout-grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    .drawer {
      width: var(--pps-drawer--width-#{$breakpoint});

      &.drawer-right {
        right: calc(#{var(--pps-drawer--width-#{$breakpoint})} * -1);
      }

      &__form {
        width: var(--pps-drawer--width-#{$breakpoint});
      }
    }
  }
}
