@each $value in $displays {
  .d-#{$value} {
    display: $value !important;
  }
}

@each $breakpoint in map-keys($mdc-layout-grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint);

    @each $value in $displays {
      .d#{$infix}-#{$value} {
        display: $value !important;
      }
    }
  }
}
