$accordion-icon-size: 24px;
$accordion-title-font-family: var(--font-family-bold);
$accordion-title-font-weight: 700;
$accordion-title-line-heigh: 24px;
$accordion-title-font-size: 16px;
$accordion-title-color: var(--gray-900);
$accordion-border-color: var(--surface-secondary);
$accordion-border-width: 1px;

.dx-accordion-item-opened {
  margin: unset;
}

.dx-accordion {
  background-color: transparent !important;
  border-top: $accordion-border-width solid $accordion-border-color;
  border-bottom: $accordion-border-width solid $accordion-border-color;
  margin: 0px;

  &.hidden-expander {
    .dx-accordion-item-title {
      cursor: auto;
    }

    .dx-accordion-item-title::before {
      content: '' !important;
      right: 0 !important;
      background: unset !important;
    }
  }
}

.dx-accordion-item {
  box-shadow: none !important;
}

.dx-accordion-item > .dx-accordion-item-title,
.dx-accordion-item.dx-state-active > .dx-accordion-item-title,
.dx-accordion-item.dx-state-focused > .dx-accordion-item-title,
.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: transparent !important;
}

.dx-accordion-item > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-active > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-focused > .dx-accordion-item-title.invalid,
.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: transparent !important;
}

.dx-accordion-item-opened > .dx-accordion-item-title {
  height: 48px;
}

.dx-accordion-item-title::before {
  right: 22px;
  top: 2px !important;
  font-size: 35px;
  color: $black-60;
}

.dx-accordion-wrapper {
  .dx-empty-message {
    font-size: 16px;
  }
}

pps-new-file {
  .dx-accordion-wrapper {
    .dx-empty-message {
      font-size: 16px;
      padding: 10px 0;
      font-family: var(--font-family-light);
    }
  }
}

.dx-accordion-item-title {
  &::before {
    content: '';
    background: url('../../media/svg/icons/chevronDown.svg') no-repeat center !important;
    background-size: $accordion-icon-size $accordion-icon-size !important;
    width: $accordion-icon-size;
    height: $accordion-icon-size;
    display: inline-block;
    top: 50%;
    transform: translateY(50%);
  }
}

.dx-accordion-item-opened > .dx-accordion-item-title {
  &::before {
    content: '';
    background: url('../../media/svg/icons/chevronUp.svg') no-repeat center !important;
    background-size: $accordion-icon-size $accordion-icon-size !important;
    width: $accordion-icon-size;
    height: $accordion-icon-size;
    display: inline-block;
    top: 50%;
    transform: translateY(50%);
  }
}

.dx-accordion-item-title {
  font-family: $accordion-title-font-family;
  font-weight: $accordion-title-font-weight;
  font-size: $accordion-title-font-size;
  line-height: $accordion-title-line-heigh;
  letter-spacing: 0.02em;
  color: $accordion-title-color;
}

// Barva pozadí hlavičky akordeonu
.dx-accordion-item.dx-accordion-item-opened > .dx-accordion-item-title {
  background-color: var(--transparent-tertiary) !important;
}

// Barva pozadí hlavičky akordeonu při hoveru
.dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: var(--transparent-secondary) !important;
}

// Ohraničení rozbaleného akordeonu
.dx-accordion .dx-accordion-item.dx-accordion-item-opened {
  border-left: 1px solid var(--transparent-secondary) !important;
  border-right: 1px solid var(--transparent-secondary) !important;
  border-bottom: 2px solid var(--gray-500) !important;
}

.dx-template-wrapper.dx-item-content.dx-accordion-item-body {
  padding-bottom: 32px;
}
