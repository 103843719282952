$colors: (
  primary: (
    color: rgba(65, 148, 104, 1),
    filter: invert(53%) sepia(34%) saturate(562%) hue-rotate(96deg) brightness(87%) contrast(94%),
  ),
  secondary: (
    color: rgba(120, 250, 174, 1),
    filter: invert(87%) sepia(10%) saturate(1865%) hue-rotate(83deg) brightness(101%) contrast(96%),
  ),
  tertiary: (
    color: rgba(14, 58, 47, 1),
    filter: invert(15%) sepia(59%) saturate(496%) hue-rotate(115deg) brightness(102%) contrast(95%),
  ),
  quarternary: (
    color: rgba(232, 255, 241, 1),
    filter: invert(97%) sepia(5%) saturate(1088%) hue-rotate(67deg) brightness(107%) contrast(101%),
  ),
  gray-100: (
    color: rgba(216, 216, 216, 1),
    filter: invert(100%) sepia(1%) saturate(2796%) hue-rotate(252deg) brightness(113%) contrast(69%),
  ),
  gray-200: (
    color: rgba(196, 198, 199, 1),
    filter: invert(88%) sepia(3%) saturate(116%) hue-rotate(155deg) brightness(89%) contrast(95%),
  ),
  gray-300: (
    color: rgba(158, 159, 160, 1),
    filter: invert(72%) sepia(5%) saturate(58%) hue-rotate(169deg) brightness(87%) contrast(90%),
  ),
  gray-500: (
    color: rgba(124, 125, 126, 1),
    filter: invert(52%) sepia(7%) saturate(58%) hue-rotate(169deg) brightness(93%) contrast(94%),
  ),
  gray-600: (
    color: rgba(90, 91, 92, 1),
    filter: invert(35%) sepia(6%) saturate(96%) hue-rotate(169deg) brightness(94%) contrast(89%),
  ),
  gray-700: (
    color: rgba(70, 71, 72, 1),
    filter: invert(27%) sepia(8%) saturate(93%) hue-rotate(169deg) brightness(96%) contrast(93%),
  ),
  gray-800: (
    color: rgba(48, 49, 50, 1),
    filter: invert(15%) sepia(7%) saturate(229%) hue-rotate(169deg) brightness(95%) contrast(86%),
  ),
  gray-900: (
    color: rgba(22, 23, 24, 1),
    filter: invert(1%) sepia(30%) saturate(545%) hue-rotate(169deg) brightness(94%) contrast(84%),
  ),
  white: (
    color: rgba(255, 255, 255, 1),
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(35deg) brightness(109%) contrast(103%),
  ),
  black: (
    color: rgba(0, 0, 0, 1),
    filter: invert(15%) sepia(7%) saturate(229%) hue-rotate(169deg) brightness(0%) contrast(86%),
  ),
  danger: (
    color: rgba(232, 43, 55, 1),
    filter: invert(33%) sepia(52%) saturate(6647%) hue-rotate(341deg) brightness(92%) contrast(98%),
  ),
  danger-deadline: (
    color: rgba(255, 122, 131, 0.689),
    filter: invert(33%) sepia(52%) saturate(6647%) hue-rotate(341deg) brightness(92%) contrast(98%),
  ),
  danger-deadline-secondary: (
    color: rgba(255, 122, 131, 0.474),
    filter: invert(33%) sepia(52%) saturate(6647%) hue-rotate(341deg) brightness(92%) contrast(98%),
  ),
  warning: (
    color: rgba(236, 101, 19, 1),
    filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%),
  ),
  warning-light: (
    color: rgba(236, 101, 19, 0.2),
    filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%),
  ),
  warning-secondary: (
    color: rgb(236, 101, 19, 0.08),
    filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%),
  ),
  warning-deadline: (
    color: rgba(255, 246, 126, 0.759),
    filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%),
  ),
  warning-deadline-secondary: (
    color: rgba(255, 246, 126, 0.564),
    filter: invert(38%) sepia(63%) saturate(2905%) hue-rotate(4deg) brightness(107%) contrast(85%),
  ),
  success: (
    color: rgba(55, 208, 2, 1),
    filter: invert(69%) sepia(69%) saturate(4721%) hue-rotate(69deg) brightness(109%) contrast(98%),
  ),
  info: (
    color: rgba(45, 113, 215, 1),
    filter: invert(53%) sepia(70%) saturate(5402%) hue-rotate(203deg) brightness(88%) contrast(91%),
  ),
  hover-primary: (
    color: rgba(89, 188, 135, 1),
    filter: invert(65%) sepia(35%) saturate(495%) hue-rotate(95deg) brightness(93%) contrast(96%),
  ),
  hover-secondary: (
    color: rgba(168, 255, 204, 1),
    filter: invert(88%) sepia(20%) saturate(591%) hue-rotate(82deg) brightness(102%) contrast(101%),
  ),
  disabled-primary: (
    color: rgba(196, 198, 199, 1),
    filter: invert(84%) sepia(6%) saturate(66%) hue-rotate(155deg) brightness(94%) contrast(91%),
  ),
  disabled-secondary: (
    color: rgba(216, 216, 216, 1),
    filter: invert(97%) sepia(0%) saturate(0%) hue-rotate(197deg) brightness(88%) contrast(97%),
  ),
  disabled-tertiary: (
    color: rgba(158, 159, 160, 1),
    filter: invert(68%) sepia(6%) saturate(55%) hue-rotate(169deg) brightness(93%) contrast(85%),
  ),
  transparent-primary: (
    color: rgba(22, 23, 24, 0.06),
    filter: invert(99%) sepia(89%) saturate(62%) hue-rotate(280deg) brightness(115%) contrast(89%),
  ),
  transparent-secondary: (
    color: rgba(22, 23, 24, 0.12),
    filter: invert(99%) sepia(89%) saturate(62%) hue-rotate(280deg) brightness(115%) contrast(89%),
  ),
  transparent-tertiary: (
    color: rgba(22, 23, 24, 0.05),
    filter: invert(99%) sepia(89%) saturate(62%) hue-rotate(280deg) brightness(115%) contrast(89%),
  ),
  transparent-backdrop: (
    color: rgba(228, 228, 228, 0.8),
    filter: invert(99%) sepia(1%) saturate(1795%) hue-rotate(105deg) brightness(116%) contrast(79%),
  ),
  surface-primary: (
    color: rgba(241, 241, 241, 1),
    filter: invert(99%) sepia(89%) saturate(62%) hue-rotate(280deg) brightness(115%) contrast(89%),
  ),
  surface-secondary: (
    color: rgba(228, 228, 228, 1),
    filter: invert(99%) sepia(1%) saturate(1795%) hue-rotate(105deg) brightness(116%) contrast(79%),
  ),
  surface-tertiary: (
    color: rgba(255, 255, 255, 1),
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(35deg) brightness(109%) contrast(103%),
  ),
  surface-info: (
    color: rgba(94, 149, 231, 0.16),
    filter: invert(1%) sepia(30%) saturate(545%) hue-rotate(169deg) brightness(94%) contrast(84%),
  ),
  danger-light: (
    color: rgba(232, 43, 55, 0.37),
    filter: invert(33%) sepia(52%) saturate(6647%) hue-rotate(341deg) brightness(92%) contrast(98%),
  ),
  primary-light: (
    color: rgba(65, 148, 104, 0.16),
    filter: invert(53%) sepia(34%) saturate(562%) hue-rotate(96deg) brightness(87%) contrast(94%),
  ),
  primary-lighter: (
    color: rgba(65, 148, 104, 0.08),
    filter: invert(53%) sepia(34%) saturate(562%) hue-rotate(96deg) brightness(87%) contrast(94%),
  ),
  surface-info-light: (
    color: rgba(94, 149, 231, 0.25),
    filter: invert(1%) sepia(30%) saturate(545%) hue-rotate(169deg) brightness(94%) contrast(84%),
  ),
);

$fontFamily: (
  light: 'SKODA Next Light',
  bold: 'SKODA Next Bold',
  regular: 'SKODA Next Regular',
);

$font-family-light: var(--font-family-light);
$font-family-bold: var(--font-family-bold);
$font-family-regular: var(--font-family-regular);
$body-font-family: $font-family-light;
$body-font-weight: 300;
$body-text-color: var(--gray-900);
$body-background-color: var(--white);
$heading-font-family: $font-family-bold;
$heading-font-weight: 700;

//REMOVE
//---------------------------------------------------------------------------
$border-color: var(--gray-900);
$brown: var(--gray-100);
$brown-dark: var(--gray-300);
$dark-50: var(--gray-300);
$black: var(--gray-300);
$text-muted: var(--gray-300);
$black-04: var(--gray-300);
$black-12: var(--gray-300);
$black-60: var(--gray-300);
$black-64: var(--gray-300);
$gray-100: var(--gray-300);
$gray-300: var(--gray-300);
$gray-500: var(--gray-300);
$gray-400: var(--gray-300);
$gray-600: var(--gray-300);
$default: var(--info);
$default-20: var(--info);
$white: var(--white);
$warning: var(--warning);
$orange: var(--warning);
$danger: var(--danger);
$danger-08: var(--danger);
$danger-20: var(--danger);
$white-60: var(--white);
$white-87: var(--white);
$primary: var(--white);
$primary-12: var(--white);
$primary-20: var(--white);
$primary-38: var(--white);

//-------------------------------------------

// HEADER
$header-height-desktop: 65px;
$header-height-tablet: 55px;
$header-height-mobile: 55px;
$subheader-height: 70px;
$footer-height: 56px;

$header-breadcrumb-font-family: $font-family-regular;
$header-breadcrumb-font-size: 16px;
$header-breadcrumb-line-height: 24px;

$header-brand-logo-height-desktop: 16px;
$header-brand-logo-height-mobile: 16px;

$header-item-size: 44px;
$header-item-icon-size: 24px;

$header-border-color: var(--gray-100);
$header-border-padding: 16px;
$header-container-padding: 0 8px 0 8px;
$header-topbar-item-padding: 10px 8px 10px 8px;

// MAIN MENU
$main-menu-default-background-color: var(--surface-primary);
$main-menu-hover-background-color: var(--surface-secondary);
$main-menu-active-background-color: var(--surface-secondary);
$main-menu-open-background-color: var(--surface-secondary);
$main-menu-separator-color: var(--surface-secondary);
$main-menu-default-icon-color: var(--gray-900);
$main-menu-open-icon-color: var(--gray-900);
$main-menu-active-icon-color: var(--gray-900);
$main-menu-hover-icon-color: var(--gray-900);
$main-menu-default-arrow-color: var(--gray-900);
$main-menu-open-arrow-color: var(--gray-900);
$main-menu-active-arrow-color: var(--gray-900);
$main-menu-hover-arrow-color: var(--gray-900);
$main-menu-default-text-color: var(--gray-900);
$main-menu-open-text-color: var(--gray-900);
$main-menu-active-text-color: var(--gray-900);
$main-menu-hover-text-color: var(--gray-900);
$main-menu-default-bullet-color: var(--gray-900);
$main-menu-open-bullet-color: var(--gray-900);
$main-menu-active-bullet-color: var(--gray-900);
$main-menu-hover-bullet-color: var(--gray-900);
$main-menu-badge-color: var(--secondary);
$main-menu-min-item-height: 56px;
$main-menu-width: 270px;
$main-menu-minimize-width: 56px;
$main-menu-item-submenu-section-indent: 8px;
$main-menu-item-link-padding: 16px 12px 16px 8px;
$main-menu-item-section-padding: 0 25px;
$main-menu-item-submenu-padding-left: 8px;
$main-menu-item-submenu-link-padding: 16px 12px 16px 16px;
$main-menu-item-submenu-section-padding: 0 25px;
$main-menu-minimize-padding: 16px 16px 16px 11px;
$main-menu-icon-size: 24px;
$main-menu-submenu-icon-size: 8px;
$main-menu-font-size: 16px;
$main-menu-font-family: $font-family-regular;
$main-menu-line-height: 24px;
$main-menu-badge-font-size: 12px;
$main-menu-badge-font-family: $font-family-bold;
$main-menu-badge-line-height: 16px;

$badges: (
  primary: (
    background-color: var(--secondary),
    color: var(--gray-900),
  ),
  info: (
    background-color: var(--info),
    color: var(--white),
  ),
  green: (
    background-color: var(--primary),
    color: var(--white),
  ),
  disabled: (
    background-color: var(--disabled-primary),
    color: var(--white),
  ),
);

$borders: (
  light: var(--border-color-light),
);

// ENUM INFO
$warning-deadline: var(--warning-deadline);
$warning-deadline-secondary: var(--warning-deadline-secondary);
$danger-deadline: var(--danger-deadline);
$danger-deadline-secondary: var(--danger-deadline-secondary);
$enum-info-font-family: $font-family-bold;
$enum-info-font-size: 11px;
$enum-info-height: 22px;

$enum-info-background-customization: (
  primary: (
    background-color: var(--primary),
  ),
  hover-primary: (
    background-color: var(--hover-primary),
  ),
  warning: (
    background-color: var(--warning),
  ),
  danger: (
    background-color: var(--danger),
  ),
  info: (
    background-color: var(--info),
  ),
  transparent: (
    background-color: transparent,
  ),
  disabled: (
    background-color: var(--disabled-tertiary),
  ),
);
$enum-info-text-customization: (
  white: (
    color: var(--white),
  ),
);
$enum-info-icon-customization: (
  white: (
    filter: var(--white-filter),
  ),
  danger: (
    filter: var(--danger-filter),
  ),
  primary: (
    filter: var(--primary-filter),
  ),
);

// WORKFLOW STATE INFO
$workflow-state-info-height: 22px;
$workflow-state-info-height: 22px;
$workflow-state-info-icon-svg-filter: brightness(0) invert(1);
$workflow-state-info-icon-size: 20px;
$workflow-state-info-line-height: 20px;
$workflow-state-info-icon-size: 20px;
$workflow-state-info-line-height: 20px;
$workflow-state-info-font-family: $font-family-bold;
$workflow-state-info-font-size: 11px;

$workflow-state-info-steps: (
  done: (
    color: var(--white),
    background-color: var(--hover-primary),
  ),
  active: (
    color: var(--white),
    background-color: var(--info),
  ),
  rejected: (
    color: var(--white),
    background-color: var(--danger),
  ),
  canceled: (
    color: var(--white),
    background-color: var(--danger),
  ),
  suspended: (
    color: var(--white),
    background-color: var(--warning),
  ),
);

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto,
);

$spacers: (
  0: 0,
  1: 0.125rem,
  2: 0.25rem,
  3: 0.5rem,
  4: 1rem,
  5: 1.25rem,
  6: 1.5rem,
  7: 2rem,
  8: 2.5rem,
  9: 3rem,
  10: 4rem,
);

$body: (
  phone: (
    font-size: 16px,
    line-height: 24px,
  ),
  tablet: (
    font-size: 16px,
    line-height: 24px,
  ),
  desktop: (
    font-size: 18px,
    line-height: 28px,
  ),
);

$svg-icon-size: (
  small: (
    width: 20px,
    height: 20px,
  ),
  medium: (
    width: 24px,
    height: 24px,
  ),
  large: (
    width: 32px,
    height: 32px,
  ),
  extralarge: (
    width: 40px,
    height: 40px,
  ),
);

$svg-icon-color: (
  default: var(--gray-900-filter),
  primary: var(--primary-filter),
  secondary: var(--secondary-filter),
  tertiary: var(--tertiary-filter),
  info: var(--info-filter),
  danger: var(--danger-filter),
  white: var(--white-filter),
  colorful: transparent,
  warning: var(--warning-filter),
  black: var(--black-filter),
);

$headings: (
  1: (
    phone: (
      font-size: 33px,
      line-height: 40px,
    ),
    tablet: (
      font-size: 38px,
      line-height: 48px,
    ),
    desktop: (
      font-size: 44px,
      line-height: 52px,
    ),
  ),
  2: (
    phone: (
      font-size: 28px,
      line-height: 36px,
    ),
    tablet: (
      font-size: 32px,
      line-height: 38px,
    ),
    desktop: (
      font-size: 36px,
      line-height: 44px,
    ),
  ),
  3: (
    phone: (
      font-size: 23px,
      line-height: 28px,
    ),
    tablet: (
      font-size: 27px,
      line-height: 32px,
    ),
    desktop: (
      font-size: 30px,
      line-height: 36px,
    ),
  ),
  4: (
    phone: (
      font-size: 20px,
      line-height: 24px,
    ),
    tablet: (
      font-size: 23px,
      line-height: 28px,
    ),
    desktop: (
      font-size: 24px,
      line-height: 28px,
    ),
  ),
  5: (
    phone: (
      font-size: 16px,
      line-height: 24px,
    ),
    tablet: (
      font-size: 16px,
      line-height: 24px,
    ),
    desktop: (
      font-size: 18px,
      line-height: 28px,
    ),
  ),
  6: (
    phone: (
      font-size: 14px,
      line-height: 20px,
    ),
    tablet: (
      font-size: 14px,
      line-height: 20px,
    ),
    desktop: (
      font-size: 16px,
      line-height: 24px,
    ),
  ),
  subheadline: (
    phone: (
      font-size: 16px,
      line-height: 24px,
    ),
    tablet: (
      font-size: 16px,
      line-height: 24px,
    ),
    desktop: (
      font-size: 18px,
      line-height: 28px,
    ),
  ),
  disclaimer: (
    phone: (
      font-size: 12px,
      line-height: 16px,
    ),
    tablet: (
      font-size: 12px,
      line-height: 16px,
    ),
    desktop: (
      font-size: 14px,
      line-height: 20px,
    ),
  ),
  caption1: (
    phone: (
      font-size: 14px,
      line-height: 20px,
    ),
    tablet: (
      font-size: 14px,
      line-height: 20px,
    ),
    desktop: (
      font-size: 16px,
      line-height: 24px,
    ),
  ),
  caption2: (
    phone: (
      font-size: 12px,
      line-height: 16px,
    ),
    tablet: (
      font-size: 12px,
      line-height: 16px,
    ),
    desktop: (
      font-size: 14px,
      line-height: 20px,
    ),
  ),
);

$buttons: (
  primary: (
    default: (
      background-color: var(--secondary),
      border-color: var(--secondary),
      color: var(--gray-900),
      svg-color-filter: var(--gray-900-filter),
    ),
    disabled: (
      background-color: var(--disabled-secondary),
      border-color: var(--disabled-secondary),
      color: var(--disabled-tertiary),
      svg-color-filter: var(--disabled-tertiary-filter),
    ),
    hover: (
      background-color: var(--hover-secondary),
      border-color: var(--hover-secondary),
      color: var(--gray-900),
      svg-color-filter: var(--gray-900-filter),
    ),
  ),
  secondary: (
    default: (
      background-color: var(--gray-700),
      border-color: var(--gray-700),
      color: var(--white),
      svg-color-filter: var(--white-filter),
    ),
    disabled: (
      background-color: var(--disabled-secondary),
      border-color: var(--disabled-secondary),
      color: var(--disabled-tertiary),
      svg-color-filter: var(--disabled-tertiary-filter),
    ),
    hover: (
      background-color: var(--gray-600),
      border-color: var(--gray-600),
      color: var(--white),
      svg-color-filter: var(--white-filter),
    ),
  ),
  tertiary: (
    default: (
      background-color: var(--transparent),
      border-color: var(--transparent),
      color: var(--gray-900),
      svg-color-filter: var(--gray-900-filter),
    ),
    disabled: (
      background-color: var(--transparent),
      border-color: var(--transparent),
      color: var(--disabled-primary),
      svg-color-filter: var(--disabled-primary-filter),
    ),
    hover: (
      background-color: var(--gray-100),
      border-color: var(--gray-100),
      color: var(--gray-900),
      svg-color-filter: var(--gray-900-filter),
    ),
  ),
  ghost: (
    default: (
      background-color: var(--white),
      border-color: var(--gray-900),
      color: var(--gray-900),
      svg-color-filter: var(--gray-900-filter),
    ),
    disabled: (
      background-color: var(--white),
      border-color: var(--disabled-primary),
      color: var(--disabled-primary),
      svg-color-filter: var(--disabled-primary-filter),
    ),
    hover: (
      background-color: var(--gray-100),
      border-color: var(--gray-900),
      color: var(--gray-900),
      svg-color-filter: var(--gray-900-filter),
    ),
  ),
  danger: (
    default: (
      background-color: var(--white),
      border-color: var(--danger),
      color: var(--danger),
      svg-color-filter: var(--danger-filter),
    ),
    disabled: (
      background-color: var(--white),
      border-color: var(--disabled-primary),
      color: var(--disabled-primary),
      svg-color-filter: var(--disabled-primary-filter),
    ),
    hover: (
      background-color: var(--gray-100),
      border-color: var(--danger),
      color: var(--danger),
      svg-color-filter: var(--danger-filter),
    ),
  ),
  info: (
    default: (
      background-color: var(--white),
      border-color: var(--info),
      color: var(--info),
      svg-color-filter: var(--info-filter),
    ),
    disabled: (
      background-color: var(--white),
      border-color: var(--disabled-primary),
      color: var(--disabled-primary),
      svg-color-filter: var(--disabled-primary-filter),
    ),
    hover: (
      background-color: var(--gray-100),
      border-color: var(--info),
      color: var(--info),
      svg-color-filter: var(--info-filter),
    ),
  ),
  warning: (
    default: (
      background-color: var(--white),
      border-color: var(--warning),
      color: var(--warning),
      svg-color-filter: var(--warning-filter),
    ),
    disabled: (
      background-color: var(--white),
      border-color: var(--disabled-primary),
      color: var(--disabled-primary),
      svg-color-filter: var(--disabled-primary-filter),
    ),
    hover: (
      background-color: var(--gray-100),
      border-color: var(--warning),
      color: var(--warning),
      svg-color-filter: var(--warning-filter),
    ),
  ),
);
