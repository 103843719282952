$gantt-border-radius: 8px;
$gantt-parent-task-background-color: var(--success);
$gantt-task-background-color: var(--info);

.dx-gantt {
  border-radius: $gantt-border-radius;
}

.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task {
  height: 18px;
  line-height: 18px;
  background-color: $gantt-task-background-color;
}

.dx-gantt .dx-gantt-taskWrapper .dx-gantt-task.dx-gantt-parent {
  height: 18px;
  line-height: 18px;
  background-color: $gantt-parent-task-background-color;
  border-left-color: $gantt-parent-task-background-color;
  border-right-color: $gantt-parent-task-background-color;
  border-top-color: $gantt-parent-task-background-color;
}

.dx-gantt .dx-row td[grid-cell-warning] {
  background-color: var(--warning-secondary);
  color: $warning;
}

// Current time
.dx-gantt .dx-gantt-tm.current-time {
  border-left: 2px solid var(--primary);
}
