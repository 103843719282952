$sides: top, bottom, right, left;
@each $border, $value in $borders {
  .border-#{$border} {
    border: 1px solid #{$value};
  }

  @each $side in $sides {
    .border-#{$side}-#{$border} {
      border-#{$side}: 1px solid #{$value};
    }
  }
}
