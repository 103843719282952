@font-face {
  font-family: 'SKODA Next Light';
  font-style: normal;
  font-weight: 300;
  src: url('SKODANext-Light.otf') format('opentype');
}

@font-face {
  font-family: 'SKODA Next Regular';
  font-style: normal;
  font-weight: 400;
  src: url('SKODANext-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SKODA Next Bold';
  font-style: normal;
  font-weight: 700;
  src: url('SKODANext-Bold.otf') format('opentype');
}
