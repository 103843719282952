@each $breakpoint in map-keys($mdc-layout-grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    @each $heading, $value in $headings {
      $deviceValue: map-get($value, $breakpoint);
      @if index(1 2 3 4 5 6, $heading) {
        h#{$heading},
        .h#{$heading} {
          font-size: map-get($deviceValue, font-size);
          line-height: map-get($deviceValue, line-height);
          font-family: $heading-font-family;
          font-weight: $heading-font-weight;
          letter-spacing: 0.02em;
          margin: 0;
        }
      } @else if index('caption1' 'caption2' 'subheadline', $heading) {
        .#{$heading} {
          font-size: map-get($deviceValue, font-size);
          line-height: map-get($deviceValue, line-height);
          font-family: $heading-font-family;
          font-weight: $heading-font-weight;
          letter-spacing: 0.02em;
          margin: 0;
        }
      } @else if $heading == 'disclaimer' {
        .#{$heading} {
          font-size: map-get($deviceValue, font-size);
          line-height: map-get($deviceValue, line-height);
          font-family: $font-family-light;
          font-weight: 300;
          letter-spacing: 0.02em;
          margin: 0;
        }
      }
    }
  }
}
