.pointer-event-none {
  pointer-events: none;
}

.pointer-event-auto {
  pointer-events: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}
