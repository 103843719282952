@include media-breakpoint-up(desktop) {
  .header-mobile {
    display: none;
  }
}

@include media-breakpoint-down(tablet) {
  .header-mobile {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0 + $environment-info-top-label-height;
    right: 0;
    left: 0;
    z-index: 97;
    height: $header-height-mobile;
    min-height: $header-height-mobile;
    background-color: var(--body-backgroud);

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      padding-left: 0;
      width: 100%;
      padding-right: 0;
      margin-right: auto;
      margin-left: auto;
    }

    .header-brand-mobile {
      height: $header-height-mobile;
      display: flex;

      .header-logo-mobile {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: $header-height-mobile;
        min-width: $header-height-mobile;
        padding: 0 10px;

        .pps-g2-logo-mobile {
          height: $header-height-mobile;
        }

        .brand-logo-mobile {
          height: $header-brand-logo-height-mobile;
        }

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: $header-border-padding;
          bottom: $header-border-padding;
          border-right: 1px solid $header-border-color;
        }
      }
    }
  }
}
