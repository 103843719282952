// FORM CONTROLS
$form-control-font-family: var(--font-family-regular);
$form-control-font-weight: 400;
$form-control-height: 48px;
$form-control-border-radius: 4px 4px 0px 0px;
$form-control-background-color: var(--transparent-primary);
$form-control-value-default-font-size: 13px;
$form-control-value-default-line-height: 20px;
$form-control-value-default-color: var(--gray-600);
$form-control-label-default-font-size: 13.2px;
$form-control-label-default-color: var(--gray-600);
$form-control-label-default-line-height: 20px;
$form-control-label-default-height: 20px;
$form-control-default-border-color: var(--gray-600);
$form-control-hover-border-color: var(--gray-900);
$form-control-label-hover-color: var(--gray-900);
$form-control-label-with-value-font-size: 10px;
$form-control-label-with-value-height: 13px;
$form-control-label-with-value-line-height: 13px;
$form-control-label-focus-font-size: 10px;
$form-control-label-focus-height: 13px;
$form-control-label-focus-line-height: 13px;
$form-control-focused-valid-border-color: var(--primary);
$form-control-label-focus-valid-color: var(--primary);
$form-control-error-border-color: var(--danger);
$form-control-label-hover-error-color: var(--danger);
$form-control-label-focus-error-color: var(--danger);
$form-control-readonly-border-radius: 4px 4px 4px 4px;
$form-control-readonly-background-color: var(--white);
$form-control-readonly-border-color: var(--transparent-secondary);
$form-control-value-readonly-color: var(--gray-900);
$form-control-label-readonly-color: var(--gray-600);
$form-control-error-message-color: var(--danger);
$form-control-error-message-font-family: var(--font-family-regular);
$form-control-error-message-font-weight: 400;
$form-control-error-message-font-size: 12px;
$form-control-error-message-height: 16px;
$form-control-error-message-line-height: 16px;

.invalid-message {
  font-family: $form-control-error-message-font-family !important;
  font-weight: $form-control-error-message-font-weight !important;
  width: 100%;
  pointer-events: none;
  display: inline-block;
  border-width: 0;
  font-size: $form-control-error-message-font-size;
  line-height: $form-control-error-message-line-height;
  height: $form-control-error-message-height;
  word-wrap: break-word;
  background-color: transparent;
  color: $form-control-error-message-color;
  padding: 6px 0 0;
}

.form-control-required {
  .dx-texteditor:not(.dx-state-readonly) {
    .dx-label-after {
      &::after {
        content: '*';
        margin-left: 3px;
        color: var(--danger);
      }
    }
  }
  &.caption1 {
    &::after {
      content: '*';
      margin-left: 3px;
      color: var(--danger);
    }
  }
}

.dx-texteditor {
  background-color: $form-control-background-color !important;
  border-radius: $form-control-border-radius !important;

  .dx-texteditor-input {
    font-family: $form-control-font-family !important;
    font-weight: $form-control-font-weight !important;
    color: $form-control-value-default-color !important;
    font-size: $form-control-value-default-font-size !important;
    line-height: $form-control-value-default-line-height !important;
  }

  .dx-texteditor-label {
    font-family: $form-control-font-family !important;
    font-weight: $form-control-font-weight !important;
    color: $form-control-label-default-color !important;

    .dx-label {
      max-width: 100% !important;
    }
  }

  &:not(.dx-texteditor-empty) {
    .dx-texteditor-label {
      height: $form-control-label-with-value-height !important;
      line-height: $form-control-label-with-value-line-height !important;
      font-size: $form-control-label-with-value-font-size !important;
    }
  }

  &.dx-texteditor-empty.dx-state-readonly {
    .dx-texteditor-label {
      height: $form-control-label-default-height !important;
      line-height: $form-control-label-default-line-height !important;
      font-size: $form-control-label-default-font-size !important;
    }
  }

  &.dx-texteditor-empty {
    .dx-texteditor-label {
      height: $form-control-label-default-height !important;
      line-height: $form-control-label-default-line-height !important;
      font-size: $form-control-label-default-font-size !important;
    }

    &:after {
      border-color: $form-control-default-border-color !important;
    }
  }

  &.dx-state-hover {
    .dx-texteditor-label {
      color: $form-control-label-hover-color !important;
    }

    &:after {
      border-color: $form-control-hover-border-color !important;
    }

    &.dx-invalid:not(.dx-state-readonly) {
      .dx-texteditor-label {
        color: $form-control-label-hover-error-color !important;
      }
    }
  }

  &.dx-state-focused {
    .dx-texteditor-label {
      height: $form-control-label-focus-height !important;
      line-height: $form-control-label-focus-line-height !important;
      font-size: $form-control-label-focus-font-size !important;
      color: $form-control-label-focus-valid-color !important;
    }

    &:after:not(.dx-invalid) {
      border-color: $form-control-focused-valid-border-color !important;
    }

    &.dx-invalid {
      .dx-texteditor-label {
        color: $form-control-label-focus-error-color !important;
      }
    }
  }

  &.dx-invalid {
    &:after {
      border-color: $form-control-error-border-color !important;
    }

    .dx-texteditor-input-container {
      &:after {
        display: none;
      }
    }
  }

  &.dx-state-disabled {
    opacity: unset;
    background-color: $form-control-readonly-background-color !important;
    border-radius: $form-control-readonly-border-radius !important;
    border: 1px solid $form-control-readonly-border-color !important;

    .dx-texteditor-buttons-container,
    .dx-placeholder,
    .dx-label-after {
      display: none;
    }

    .dx-texteditor-label {
      color: $form-control-label-readonly-color !important;
    }

    .dx-texteditor-input {
      color: $form-control-value-readonly-color !important;
    }

    &:after {
      display: none;
    }

    &:not(.dx-texteditor-empty) {
      .dx-texteditor-input {
        cursor: text;
        pointer-events: auto;
      }
    }
  }

  &.dx-state-invisible {
    height: $form-control-height;
    display: block !important;
    pointer-events: none;
    background-color: $form-control-readonly-background-color !important;
    border-radius: $form-control-readonly-border-radius !important;
    border: 1px solid $form-control-readonly-border-color !important;

    .dx-texteditor-label {
      color: $form-control-label-readonly-color !important;
    }

    .dx-texteditor-input,
    .dx-placeholder,
    .dx-texteditor-buttons-container,
    .dx-label-after {
      display: none;
    }

    &:after {
      filter: var(--gray-700-filter);
      border: unset;
      display: block !important;
      content: '';
      background: url('../../media/svg/icons/hide.svg') no-repeat center !important;
      background-size: 24px 24px !important;
      top: 50%;
      left: 50%;
      width: 24px;
      height: 24px;
      transform: translate(-50%, -50%);
    }
  }

  &.dx-state-readonly {
    background-color: $form-control-readonly-background-color !important;
    border-radius: $form-control-readonly-border-radius !important;
    border: 1px solid $form-control-readonly-border-color !important;

    .dx-texteditor-label {
      color: $form-control-label-readonly-color !important;
    }

    .dx-texteditor-input {
      color: $form-control-value-readonly-color !important;
    }

    .dx-texteditor-buttons-container,
    .dx-placeholder,
    .dx-label-after {
      display: none;
    }

    &:after {
      display: none;
    }

    &:before {
      display: none;
    }
  }

  &.form-control-required:not(.dx-state-readonly) {
    .dx-label-after {
      &::after {
        content: '*';
        margin-left: 3px;
        color: var(--danger);
      }
    }
  }

  &.form-control-modified:not(.dx-state-readonly) {
    background-color: var(--surface-info) !important;
  }

  &.bg-danger {
    background-color: var(--danger-light) !important;
  }
}

.dx-texteditor-label .dx-label {
  text-overflow: ellipsis;
  overflow: hidden;
}

.dx-textarea.dx-texteditor-empty:not(.dx-state-focused) .dx-texteditor-label {
  top: 18px !important;
}
