.pps-tooltip {
  $bg: #616161;

  position: relative;

  &:before,
  &:after {
    position: absolute;
    left: calc(100% + 8px);
    opacity: 0;
    pointer-events: none;
    z-index: 100;
    bottom: -2px;

    transition: opacity 400ms;
  }

  &:after {
    background: $bg;
    border-radius: 6px;
    box-shadow: none;
    color: $white;
    content: attr(data-pps-tooltip);
    font-size: 0.75rem;
    line-height: 1;
    padding: 6px 8px;
    max-width: 320px;
    min-width: 34px;
    text-align: center;
    text-transform: none;
    white-space: pre;
  }

  &:hover,
  &:focus {
    &:before,
    &:after {
      opacity: 1;
    }
  }
}
