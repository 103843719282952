pps-drawing-documentation-kinds {
  // Total summary background fix
  .dx-datagrid {
    background-color: $white;
  }

  // Total summary group space background
  .dx-datagrid-borders > .dx-datagrid-total-footer .dx-command-expand.dx-datagrid-group-space {
    background-color: $primary-20;
  }

  // Group rows background
  .dx-datagrid-rowsview .dx-row.dx-group-row:not(.dx-row-focused) {
    background-color: $primary-20;
  }
}
