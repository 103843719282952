@each $size, $value in $sizes {
  .w-#{$size} {
    width: #{$value} !important;
  }
  .mw-#{$size} {
    max-width: #{$value} !important;
  }
  .h-#{$size} {
    height: #{$value} !important;
  }
  .mh-#{$size} {
    max-height: #{$value} !important;
  }
}

@each $breakpoint in map-keys($mdc-layout-grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint);

    @each $size, $value in $sizes {
      .w#{$infix}-#{$size} {
        width: #{$value} !important;
      }
      .mw#{$infix}-#{$size} {
        max-width: #{$value} !important;
      }
      .h#{$infix}-#{$size} {
        height: #{$value} !important;
      }
      .mh#{$infix}-#{$size} {
        max-height: #{$value} !important;
      }
    }
  }
}
