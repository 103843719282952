.dx-dropdowneditor-icon {
  &::before {
    content: '' !important;
    background: url('../../media/svg/icons/chevronDown.svg') no-repeat center;
    background-size: 20px 20px !important;
    width: 20px !important;
    height: 20px !important;
    display: inline-block !important;
  }
}

.dx-dropdowneditor-active .dx-dropdowneditor-icon {
  &::before {
    content: '' !important;
    background: url('../../media/svg/icons/chevronUp.svg') no-repeat center;
    background-size: 20px 20px !important;
    width: 20px !important;
    height: 20px !important;
    display: inline-block !important;
  }
}

.dx-selectbox {
  .dx-textbox.dx-state-readonly.dx-textbox.dx-state-invisible,
  .dx-textbox.dx-state-readonly,
  .dx-textbox.dx-state-invisible {
    display: none !important;
  }

  .dx-texteditor-input:read-only {
    cursor: text;
  }

  .dx-dropdowneditor-field-template-wrapper {
    .dx-template-wrapper:not(:has(.dropbox-no-template-padding)) {
      padding-top: 19px;
      padding-left: 16px;
      min-height: 46px;
    }
  }

  &.dx-state-invisible {
    .dx-selectbox-container {
      display: none !important;
    }
  }
}

.internal-external.internal-value .dx-texteditor-input-container input {
  color: var(--primary) !important;
}
.internal-external.external-value .dx-texteditor-input-container input {
  color: var(--danger) !important;
}
