$tree-list-row-size-extra-small: 33px;
$tree-list-row-size-small: 48px;
$tree-list-row-size-medium: 56px;
$tree-list-row-size-large: 64px;
$tree-list-border-color: var(--surface-secondary);
$tree-list-surface-color: var(--surface-primary);
$tree-list-border-radius: 8px;
$tree-list-font-family: var(--font-family-regular);
$tree-list-headers-font-family: var(--font-family-bold);
$tree-list-headers-font-size: 14px;
$tree-list-header-icons: 20px;
$tree-list-headers-line-height: 17px;
$tree-list-control-icon-size: 24px;
$tree-list-row-focus-color: var(--primary);
$tree-list-row-error-color: var(--danger);
$tree-list-header-row-height: 40px;
$tree-list-filter-row-height: 40px;

.grid-row-size-extra-small {
  .dx-treelist .dx-treelist-rowsview .dx-row > td {
    height: $tree-list-row-size-extra-small;
    padding-top: 0;
    padding-bottom: 0;
  }

  .dx-treelist-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: $tree-list-row-size-extra-small;
  }
}

.grid-row-size-small {
  .dx-treelist .dx-treelist-rowsview .dx-row > td {
    height: $tree-list-row-size-small;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .dx-treelist-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: $tree-list-row-size-small;
  }
}

.grid-row-size-medium {
  .dx-treelist .dx-treelist-rowsview .dx-row > td {
    height: $tree-list-row-size-medium;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dx-treelist-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: $tree-list-row-size-medium;
  }
}

.grid-row-size-large {
  .dx-treelist .dx-treelist-rowsview .dx-row > td {
    height: $tree-list-row-size-large;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .dx-treelist-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: $tree-list-row-size-large;
  }
}

.dx-treelist-filter-row {
  background-color: transparent;
}

.dx-treelist .dx-editor-cell .dx-texteditor {
  background-color: transparent !important;
}

.dx-treelist .dx-header-row {
  height: $tree-list-header-row-height !important;
}

.dx-treelist .dx-row > td {
  padding: 6px 15px;
}

.dx-treelist .dx-treelist-table .dx-header-row > td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dx-treelist .dx-treelist-filter-row .dx-editor-cell .dx-texteditor {
  height: $tree-list-filter-row-height;
  line-height: $tree-list-filter-row-height;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: $tree-list-filter-row-height;
}

.dx-treelist-focus-overlay {
  max-height: 40px !important;
}

.dx-treelist .dx-treelist-filter-row {
  height: $tree-list-filter-row-height;
}

.dx-row .dx-treelist-filter-row {
  height: $tree-list-filter-row-height;
}

.dx-treelist .dx-treelist-headers .dx-treelist-content .dx-treelist-table .dx-header-row > .dx-treelist-select-all {
  padding-top: 0.6rem;
}

.dx-treelist-headers.dx-header-multi-row .dx-treelist-content .dx-treelist-table .dx-row.dx-header-row > td {
  padding-top: 0px;
}

.dx-treelist .dx-treelist-headers {
  box-shadow: 0px 10px 8px -13px var(--black) !important;
  z-index: 3;
}

.dx-treelist-container .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-treelist-container .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none,
.dx-treelist-container .dx-treelist-headers .dx-row td.dx-pointer-events-none,
.dx-treelist-container .dx-treelist-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-treelist-container .dx-treelist-rowsview .dx-freespace-row td.dx-pointer-events-none {
  border-left: 1px solid $tree-list-border-color;
  border-right: 1px solid $tree-list-border-color;
}

.dx-treelist-headers,
.dx-treelist-headers > .dx-treelist-content {
  border-radius: $tree-list-border-radius $tree-list-border-radius 0 0 !important;
}

.dx-treelist-rowsview {
  border-radius: 0 0 $tree-list-border-radius $tree-list-border-radius !important;
}

.dx-treelist-rowsview .dx-treelist-collapsed span {
  &::before {
    content: '';
    background: url('../../media/svg/icons/chevronRight.svg') no-repeat center !important;
    background-size: $tree-list-header-icons $tree-list-header-icons !important;
    width: $tree-list-header-icons;
    height: $tree-list-header-icons;
    display: inline-block;
  }
}

.dx-treelist-rowsview .dx-treelist-expanded span {
  &::before {
    content: '';
    background: url('../../media/svg/icons/chevronDown.svg') no-repeat center !important;
    background-size: $tree-list-header-icons $tree-list-header-icons !important;
    width: $tree-list-header-icons;
    height: $tree-list-header-icons;
    display: inline-block;
  }
}

.dx-treelist-container .dx-header-filter {
  &::before {
    content: '';
    background: url('../../media/svg/icons/filter.svg') no-repeat center !important;
    background-size: $tree-list-header-icons $tree-list-header-icons !important;
    width: $tree-list-header-icons;
    height: $tree-list-header-icons;
    display: inline-block;
  }
}

.dx-treelist-headers .dx-treelist-cell {
  border-right: none !important;
}

.dx-treelist-rowsview .dx-treelist-cell {
  border-right: none !important;
}

.dx-treelist {
  border-radius: $tree-list-border-radius $tree-list-border-radius 0 0;
  background-color: transparent;
  font-family: $tree-list-font-family;
  font-weight: 400;
}

.dx-treelist .dx-header-row > .dx-command-edit,
.dx-treelist .dx-header-row > td .dx-treelist-text-content {
  font-family: $tree-list-headers-font-family;
  font-weight: 700;
  font-style: normal;
  font-size: $tree-list-headers-font-size;
  line-height: $tree-list-headers-line-height;
}

.dx-treelist .dx-header-row > td:not(:last-child):after,
.dx-treelist-filter-row > td:not(:last-child):after {
  content: '';
  position: absolute;
  bottom: 15px;
  top: 15px;
  right: 0px;
  border-right: 1px solid $data-grid-border-color;
}

.dx-treelist-headers.dx-header-multi-row .dx-treelist-content:not(.dx-treelist-content-fixed) .dx-header-row > td {
  border-right: 1px solid var(--white) !important;
}

.dx-treelist-filter-row > td:has(+ .dx-pointer-events-none):after,
.dx-treelist-filter-row > td:has(+ .dx-hidden-cell):after,
.dx-treelist-filter-row > td.dx-command-drag:after,
.dx-treelist-filter-row > td:has(+ .dx-command-edit):after {
  border-right: 1px solid transparent;
}

.dx-treelist-rowsview .dx-row {
  border: 0;
}

.dx-treelist-filter-row > td {
  position: relative;
}

.dx-treelist-focus-overlay:after {
  visibility: hidden;
}

.dx-treelist .dx-treelist-rowsview .dx-row > td.dx-editor-cell {
  padding-top: 0;
  padding-bottom: 0;
}

.dx-treelist .dx-editor-cell.dx-focused .dx-editor-container .dx-texteditor:not(.dx-invalid) {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: $tree-list-row-focus-color;
    left: 0;
    right: 1px;
  }
}

.dx-treelist .dx-editor-cell .dx-editor-container .dx-texteditor.dx-invalid {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: $tree-list-row-error-color;
    left: 0;
    right: 1px;
  }
}

.dx-treelist .dx-treelist-headers.dx-header-multi-row {
  .dx-header-row > td:not([rowspan]):has(+ td[rowspan]):after {
    border-right: 1px solid var(--white) !important;
  }
}

// Drag and drop mouse cursor override
.dx-treelist .dx-treelist-rowsview .dx-data-row .dx-command-drag {
  cursor: grab;
}

.treelist-no-pager .dx-treelist-headers,
.treelist-no-pager .dx-treelist-headers > .dx-treelist-content,
.treelist-no-pager {
  border-radius: 0 !important;
}

.dx-treelist-columns-separator {
  z-index: 1001;
}
