@mixin menu-item($item) {
  cursor: pointer;
  min-height: $main-menu-min-item-height;
  margin: nth($item, 1);
  padding: nth($item, 2);

  .menu-text {
    font-family: $main-menu-font-family;
    font-size: $main-menu-font-size;
    line-height: $main-menu-line-height;
    margin-left: 16px;
    text-transform: initial;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
  }

  .menu-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50px;
    min-width: $main-menu-line-height;
    box-sizing: border-box;
  }

  .badge-text {
    font-family: $main-menu-badge-font-family;
    font-weight: 700;
    font-size: $main-menu-badge-font-size;
    line-height: $main-menu-badge-line-height;
    letter-spacing: 0.75px;
    color: $main-menu-hover-text-color;
    display: flex;
    justify-content: center;
    flex: 1;
  }

  .menu-icon {
    flex: 0 0 24px;
  }

  .menu-icon-svg {
    width: $main-menu-icon-size;
    height: $main-menu-icon-size;
  }

  .submenu-icon-svg {
    width: $main-menu-submenu-icon-size;
    height: $main-menu-submenu-icon-size;
  }

  .menu-arrow {
    flex: 0 0 20px;
    font-size: 0.8rem;
    margin-left: 8px;
    transition: all 0.3s ease;
  }
}

@mixin menu-section($item) {
  margin: nth($item, 1);
  height: nth($item, 2);

  .menu-text {
    font-size: 0.8rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }
}

@mixin menu-item-theme() {
  > .menu-link {
    &:hover {
      background-color: $main-menu-hover-background-color;

      .menu-icon {
        color: $main-menu-hover-icon-color;
      }

      .menu-text {
        color: $main-menu-hover-text-color;
      }

      .menu-arrow {
        color: $main-menu-hover-arrow-color;
      }
    }

    .menu-text {
      color: $main-menu-default-text-color;
    }

    .menu-icon {
      color: $main-menu-default-icon-color;
    }

    .menu-arrow {
      color: $main-menu-default-arrow-color;
    }
  }

  &.menu-item-open {
    transition: background-color 0.3s;
    background-color: $main-menu-open-background-color;

    > .menu-link {
      background-color: $main-menu-open-background-color;

      .menu-text {
        color: $main-menu-open-text-color;
      }

      .menu-icon {
        color: $main-menu-open-icon-color;
      }

      .menu-arrow {
        color: $main-menu-open-arrow-color;
      }
    }
  }

  &.menu-item-active {
    transition: background-color 0.3s;
    background-color: $main-menu-active-background-color;

    > .menu-link {
      background-color: $main-menu-open-background-color;

      .menu-text {
        color: $main-menu-active-text-color;
      }

      .menu-icon {
        color: $main-menu-active-icon-color;
      }

      .menu-arrow {
        color: $main-menu-active-arrow-color;
      }
    }
  }
}

@mixin menu-section-theme() {
  .menu-text {
    color: $main-menu-default-text-color;
  }

  .menu-icon {
    color: $main-menu-default-icon-color;
  }
}

@mixin menu-theme() {
  .menu-nav {
    > .menu-item {
      @include menu-item-theme();

      > .menu-arrow {
        color: $main-menu-default-arrow-color;
      }
    }

    > .menu-section {
      @include menu-section-theme();
    }

    > .menu-separator {
      border-bottom: 1px solid $main-menu-separator-color;
    }

    > .menu-item {
      .menu-submenu {
        .menu-subnav {
          background-color: $main-menu-default-background-color;
        }

        .menu-item {
          @include menu-item-theme();
        }

        .menu-section {
          @include menu-section-theme();
        }

        .menu-separator {
          border-bottom: 1px solid $main-menu-separator-color;
        }
      }
    }
  }
}
