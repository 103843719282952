@include media-breakpoint-up(desktop) {
  .content-wrapper {
    padding-left: $main-menu-width;
    padding-top: calc($header-height-desktop + $environment-info-top-label-height + var(--release-info-label-top));

    .menu-minimize & {
      padding-left: $main-menu-minimize-width;
    }
  }
}

@include media-breakpoint-down(tablet) {
  .content-wrapper {
    padding-top: calc($header-height-mobile + $environment-info-top-label-height + var(--release-info-label-top));
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 auto;

  .page {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;

    .content-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 auto;
      min-width: 0;

      .content {
        background-color: var(--body-backgroud);
        overflow: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
      }
    }
  }
}

.page-with-footer {
  padding-top: 0;
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.page-without-footer {
  overflow-y: auto;
  overflow-x: hidden;
}

.page-content-wrapper {
  background-color: var(--body-backgroud);
}

// Desktop Mode
@include media-breakpoint-up(desktop) {
  .content {
    height: calc(100vh - #{$header-height-desktop} - #{$environment-info-top-label-height} - var(--release-info-label-top)) !important;
  }

  .page-without-footer {
    height: calc(100vh - #{$header-height-desktop} - #{$environment-info-top-label-height} - var(--release-info-label-top)) !important;
  }

  .page-without-footer-with-subheader {
    height: calc(
      100vh - #{$header-height-desktop} - #{$subheader-height} - #{$environment-info-top-label-height} - var(--release-info-label-top)
    ) !important;
  }

  .page-with-footer {
    height: calc(
      100vh - #{$header-height-desktop} - #{$footer-height} - #{$environment-info-top-label-height} - var(--release-info-label-top)
    ) !important;
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(tablet) {
  .content {
    height: calc(100vh - #{$header-height-mobile} - #{$environment-info-top-label-height} - var(--release-info-label-top)) !important;
  }

  .page-without-footer {
    height: calc(100vh - #{$header-height-mobile} - #{$environment-info-top-label-height} - var(--release-info-label-top)) !important;
  }

  .page-with-footer {
    height: calc(
      100vh - #{$header-height-mobile} - #{$footer-height} - #{$environment-info-top-label-height} - var(--release-info-label-top)
    ) !important;
  }

  .page-without-footer-with-subheader {
    height: calc(
      100vh - #{$header-height-mobile} - #{$subheader-height} - #{$environment-info-top-label-height} - var(--release-info-label-top)
    ) !important;
  }
}
