pps-work-coordination {
  .dashboard-item {
    border: 1px solid var(--gray-100);
    border-radius: 16px;
    padding: 16px;
    background-color: var(--transparent-primary);
    min-width: 250px;
    height: 150px;
  }

  .content-text {
    font-size: 48px;
  }
}
