.main-menu-wrapper {
  height: 100%;
  overflow-x: hidden;
}

.main-menu {
  background-color: $main-menu-default-background-color;
  width: $main-menu-width;
  flex: 0 0 auto;
  flex-direction: column !important;
  display: flex !important;
  border-top: 1px solid $main-menu-separator-color;
  border-right: 1px solid $main-menu-separator-color;
}

.minimalize-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: $main-menu-minimize-padding;
  height: $main-menu-min-item-height;
  box-sizing: border-box;
  border-top: 1px solid $main-menu-separator-color;

  dx-button {
    border: none !important;
    padding: 0;
    min-width: $main-menu-icon-size !important;
  }

  .minimalize-icon {
    width: $main-menu-icon-size;
    height: $main-menu-icon-size;
  }

  .minimalize-text > span {
    font-family: $main-menu-font-family;
    font-size: $main-menu-font-size;
    font-weight: 400;
    line-height: $main-menu-line-height;
  }

  .menu-minimize:not(.menu-minimize-hover) & {
    padding: 0;
    justify-content: center;
  }

  .menu-minimize & {
    .minimalize-icon {
      transform: rotate(180deg);
    }
  }

  .menu-minimize.menu-minimize-hover & {
    .minimalize-icon {
      transform: rotate(180deg);
    }
  }

  .minimalize-text {
    display: flex;
    justify-content: flex-begin;

    .menu-minimize:not(.menu-minimize-hover) & {
      display: none;
    }
  }
}

@include media-breakpoint-up(desktop) {
  .main-menu {
    position: fixed;
    top: calc(#{$header-height-desktop} + #{$environment-info-top-label-height} + var(--release-info-label-top));
    bottom: 0;
    left: 0;
    z-index: 100;

    .menu-minimize:not(.menu-minimize-hover) & {
      width: $main-menu-minimize-width;

      .menu-nav {
        padding: 8px 0;

        > .menu-item {
          > .menu-link {
            padding: $main-menu-minimize-padding;

            .menu-icon {
              flex: 0 0 0;
              width: 100%;
            }

            .menu-text {
              display: none;
            }

            .menu-arrow {
              display: none;
            }

            .menu-badge {
              height: 0px;
              min-width: 0px;

              .badge-text {
                display: none;
              }
            }
          }

          > .menu-submenu {
            display: none !important;
          }

          &.menu-item-here {
            > .menu-arrow {
              display: inline-block;
            }
          }
        }
      }
    }

    .menu-minimize.menu-minimize-hover {
      width: $main-menu-width;
    }
  }
}

@include media-breakpoint-down(tablet) {
  .main-menu {
    border-top: 0px;
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -$main-menu-width;
    transition:
      left 0.3s ease,
      right 0.3s ease,
      bottom 0.3s ease,
      top 0.3s ease;

    &.main-menu-on {
      transition:
        left 0.3s ease,
        right 0.3s ease,
        bottom 0.3s ease,
        top 0.3s ease;
      left: 0;
    }
  }

  .minimalize-wrapper {
    display: none;
  }
}

.menu-nav {
  margin: 0;
  list-style: none;
  padding: 8px 0;

  .menu-scroll {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .menu-subnav {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;
    margin: 0;
    list-style: none !important;
  }

  .menu-submenu {
    display: none;
    float: none;
    margin: 0;
    padding: 0;
  }

  .menu-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    float: none;
    padding: 0;

    > .menu-link {
      display: flex;
      flex-grow: 1;
      align-items: center;
      margin: 0;
      padding: 0;
      text-decoration: none;
      position: relative;
      outline: none;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }

      .menu-text {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 0;
      }

      .menu-label {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }

      .menu-icon {
        display: flex;
        align-items: center;
        line-height: 0;
      }

      .menu-badge {
        .badge-text {
          display: block;
        }
      }

      .menu-arrow {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        line-height: 0;
        transform: translate3d(0, 0, 0);
      }
    }

    &.menu-item-open {
      > .menu-link {
        > .menu-arrow {
          transform: rotateZ(90deg);
        }
      }

      > .menu-submenu {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
    }

    .menu-submenu {
      .menu-item {
        > .menu-link {
          padding: 16px 25px;
        }
      }

      .menu-item-parent {
        display: none;
      }
    }
  }

  .menu-section {
    display: flex;
    padding: 0 25px;

    &.menu-section-first {
      margin-top: 0 !important;
    }

    .menu-text {
      display: flex;
      margin: 0;
      padding: 0;
      align-items: center;
    }

    .menu-icon {
      display: none;
      align-items: center;
      justify-content: center;
    }
  }

  .menu-separator {
    height: 0;
    overflow: hidden;
  }

  > .menu-item {
    position: relative;
    margin: 0;
    @include menu-item(
      (
        margin: 0,
        padding: 0,
      )
    );

    &.menu-item-open {
      > .menu-link {
        .menu-arrow {
          transition: all 0.3s ease;
        }
      }
    }

    .menu-submenu {
      .menu-subnav {
        margin: 0;
      }

      // menu item
      .menu-item {
        @include menu-item(
          (
            margin: 0,
            padding: 16px 25px,
          )
        );
      }

      // menu section
      .menu-section {
        @include menu-section(
          (
            margin: 20px 0 0 0,
            padding: 0 25px,
          )
        );
      }

      // menu item separator
      .menu-separator {
        margin: 0;
      }
    }
  }

  // menu section
  > .menu-section {
    @include menu-section(
      (
        margin: 20px 0 0 0,
        padding: 0 25px,
      )
    );
  }

  > .menu-item {
    > .menu-link {
      padding: $main-menu-item-link-padding;
    }

    > .menu-submenu {
      .menu-subnav {
        .menu-content {
          .menu-heading {
            padding: $main-menu-item-submenu-link-padding;
          }

          .menu-inner {
            padding: 0;
            margin: 0;

            .menu-link {
              padding: $main-menu-item-submenu-link-padding;
            }
          }
        }

        > .menu-item {
          > .menu-link {
            padding: $main-menu-item-submenu-link-padding;
          }

          > .menu-submenu {
            padding: 0;

            .menu-subnav {
              padding: 0;

              > .menu-item {
                > .menu-link {
                  padding: $main-menu-item-submenu-link-padding;
                  padding-left: 3 * $main-menu-item-submenu-padding-left;
                }

                > .menu-submenu {
                  padding: 0;

                  .menu-subnav {
                    > .menu-item {
                      > .menu-link {
                        padding: $main-menu-item-submenu-link-padding;
                        padding-left: 4 * $main-menu-item-submenu-padding-left;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        > .menu-section {
          padding: $main-menu-item-submenu-section-padding;
        }

        > .menu-item {
          > .menu-submenu {
            padding: 0;

            .menu-subnav {
              > .menu-section {
                padding: $main-menu-item-submenu-section-padding;
                padding-left: $main-menu-item-submenu-padding-left + (1 * $main-menu-item-submenu-section-indent);
              }

              > .menu-item {
                > .menu-submenu {
                  padding: 0;

                  .menu-subnav {
                    > .menu-section {
                      padding: $main-menu-item-submenu-section-padding;
                      padding-left: $main-menu-item-submenu-padding-left + (2 * $main-menu-item-submenu-section-indent);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include menu-theme();
