body {
  margin: 0;
  font-family: $body-font-family;
  font-weight: $body-font-weight;
  color: $body-text-color;
  background-color: $body-background-color;
  letter-spacing: 0.02em;
  overflow-y: hidden;
}

@each $breakpoint in map-keys($mdc-layout-grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    $value: map-get($body, $breakpoint);
    body {
      font-size: map-get($value, font-size);
      line-height: map-get($value, line-height);
    }
  }
}

@include media-breakpoint-up(desktop) {
  .mdc-layout-grid__inner {
    grid-gap: $mdc-layout-grid-gutter-desktop 24px;
  }
}

$phone: map-get($mdc-layout-grid-breakpoints, 'phone');

@media only screen and (max-width: $phone) {
  body {
    overflow-y: auto;
  }
}
