.dx-scheduler {
  .dx-scheduler-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .dx-scheduler-work-space {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &.scheduler-custom-appointment {
    .dx-scheduler-appointment {
      background: none !important;
    }
  }

  .dx-toolbar-items-container {
    .dx-buttongroup-item-content,
    .dx-dropdownbutton {
      border: none;
    }

    .dx-dropdownbutton {
      border: 1px solid var(--primary);

      .dx-buttongroup-item {
        border-radius: 50px;
      }
    }
  }

  .dx-scheduler-date-table-row {
    .dx-scheduler-date-table-cell:nth-child(6),
    .dx-scheduler-date-table-cell:nth-child(7) {
      background: var(--gray-100);
    }
  }
}

@each $breakpoint in map-keys($mdc-layout-grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    .pps-custom-scheduler {
      height: calc(100vh - var(--pps-devexpress-scheduler--height-#{$breakpoint}, 0));
      max-height: calc(100vh - var(--pps-devexpress-scheduler--max-height-#{$breakpoint}, 0));
    }
  }
}
