.footer {
  background-color: var(--white);
  border-top: 1px solid var(--border-color-light);
  height: $footer-height;
  padding-top: 0.75rem !important;

  .footer-actions > *:not(:last-child) {
    margin-right: 16px;
  }
}
