.dx-popup-normal {
  border-radius: 8px !important;

  .dx-popup-title {
    border-bottom: none !important;
    margin: 0;
    padding: 0;

    .dx-button {
      margin-top: 8px;
      margin-right: 8px;

      &.dx-closebutton {
        border: none;
        height: 32px !important;
        width: 32px !important;

        &.dx-state-active {
          background-color: transparent !important;
        }
      }
    }

    .dx-button-has-icon .dx-icon {
      font-size: 20px !important;
    }
  }

  .dx-popup-content {
    border-bottom: none !important;
    padding: 0 38px;

    h3 {
      margin-bottom: 20px;
    }

    font-family: var(--font-family-light);
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    overflow-wrap: break-word;
  }

  .dx-popup-bottom {
    border-top: none !important;
    padding: 38px 38px 30px 38px;
  }
}

.dx-dropdowneditor-overlay {
  .dx-popup-content {
    padding: 0;
  }
}

.dx-popup-content-p0 {
  .dx-popup-content {
    padding: 0;
    margin-top: 4px;
  }
}

.dx-overlay-shader {
  background-color: var(--transparent-backdrop);
}

// as second popup when setting pivot table -> row fields -> search
.dx-header-filter-menu {
  .dx-toolbar-after {
    width: 100%;

    .dx-toolbar-button:first-child {
      .dx-button {
        background: var(--secondary);
        border-color: var(--secondary);
        color: var(--gray-900);
        border-radius: 50px !important;

        &.dx-state-hover {
          background: var(--hover-secondary);
          border-color: var(--hover-secondary);
        }
      }
    }

    .dx-toolbar-button:nth-child(2) {
      .dx-button {
        background: var(--transparent);
        border-color: var(--transparent);
        color: var(--gray-900);
        border-radius: 50px !important;

        &.dx-state-hover {
          background: var(--gray-100);
          border-color: var(--gray-100);
        }
      }
    }
  }
}
