pps-repair-scope,
pps-device-settings {
  .page-content {
    grid-column-start: 1;
  }

  .shape-selection-tree {
    width: 270px;
    float: left;
  }

  .page-content-wrapper {
    border-left: 1px solid var(--border-color-light);
  }
}

pps-shape-selection-tree {
  .tree-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .tree {
      height: 100%;
      display: flex;
      flex-direction: column;
      font-size: 14px !important;
    }

    .root-items-wrapper {
      display: flex;
      flex-direction: column;
    }

    .root-item {
      padding: 16px;
      display: flex;
      cursor: pointer;
    }

    .child-item-wrapper {
      height: 40px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .child-item {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .tree-scroll-view {
      height: calc(100vh - #{$header-height-desktop} - #{$environment-info-top-label-height} - #{$footer-height});
    }
  }
}

pps-summary-table-by-design,
pps-summary-table-by-position,
pps-repair-scope-head {
  .header-data-list {
    div:not(:last-child):after {
      content: '/';
      margin: 0 7px 0 7px;
    }
  }
}

pps-repair-scope-part {
  .repair-scope-part-footer {
    height: 55px;
  }

  .repair-scope-part-param {
    padding-left: 20px;
    padding-right: 20px;
  }
}
