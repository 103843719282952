//
// Breadcrumbs
//
.header-breadcrumb {
  margin: 0 8px;
}

.header-breadcrumb-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: $header-item-icon-size;
}

.breadcrumb-item {
  white-space: nowrap;
  padding-left: 0 !important;
  height: $header-item-icon-size;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 6px;

  &:after {
    content: '/';
    font-family: $header-breadcrumb-font-family;
    font-weight: 400;
    font-size: $header-breadcrumb-font-size;
    line-height: $header-breadcrumb-line-height;
    letter-spacing: 0.75px;
    color: $main-menu-hover-text-color;
    display: flex;
    justify-content: center;
    flex: 1;
    margin-left: 12px;
  }

  &:before {
    display: none;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
}

.breadcrumbs-full {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.breadcrumbs-compact {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.breadcrumb-item-text {
  font-family: $main-menu-badge-font-family;
  font-weight: 700;
  font-size: $main-menu-badge-font-size;
  line-height: $main-menu-badge-line-height;
  letter-spacing: 0.75px;
  color: $main-menu-hover-text-color;
  display: flex;
  justify-content: center;
  flex: 1;
}

// Tablet & Mobile Modes
@include media-breakpoint-down(tablet) {
  .header-breadcrumb {
    display: none;
  }

  .breadcrumbs-compact {
    display: flex;
  }

  .breadcrumbs-full {
    display: none;
  }
}

@include media-breakpoint-up(desktop) {
  .breadcrumbs-full {
    display: flex;
  }

  .breadcrumbs-compact {
    display: none;
  }
}
