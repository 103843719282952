pps-repair-scope-part,
pps-device-tables-edit {
  .image-wrapper {
    width: 100%;
    height: auto;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: 100%;
      width: 100%;
      object-fit: contain;
      max-height: 450px;
    }
  }
}
