.dx-tagbox {
  .dx-tag {
    margin-top: 12px;
  }

  .dx-tag-content {
    padding: 2px 35px 2px 16px;
    background-color: var(--quarternary);
    color: var(--primary);

    .multi-tag-counter {
      color: var(--white);
      background-color: var(--primary);
      border-radius: 50%;
      padding: 0 5px;
    }

    .dx-tag-remove-button {
      &::before {
        background-color: var(--quarternary);
        color: var(--primary);
      }
    }

    &:hover {
      background-color: var(--quarternary);
      color: var(--primary);

      .dx-tag-remove-button {
        &::before {
          background-color: var(--quarternary);
          color: var(--primary);
        }
      }
    }
  }

  &.dx-state-disabled {
    .dx-tag-content {
      padding: 2px 35px 2px 16px;
      background-color: var(--surface-tertiary);
      color: var(--gray-200);

      .multi-tag-counter {
        color: var(--white);
        background-color: var(--gray-200);
        border-radius: 50%;
        padding: 0 5px;
      }

      .dx-tag-remove-button {
        &::before {
          background-color: var(--surface-tertiary);
          color: var(--gray-200);
        }
      }
    }
  }
}
.dx-tagbox-popup-wrapper {
  .dx-item.dx-list-item {
    .dx-item-content {
      text-align: left;
    }
  }

  .dx-list-select-all {
    display: flex;
    justify-content: space-between;

    .dx-list-select-all-label {
      padding-left: 16px;
      text-align: left;
    }
  }
}
