.dx-datebox {
  .dx-dropdowneditor-icon::before {
    content: '' !important;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: contain;
  }

  &.dx-datebox-date {
    .dx-dropdowneditor-icon::before {
      background: url('../../media/svg/icons/calendarDot.svg') no-repeat center center;
    }
  }

  &.dx-datebox-time {
    .dx-dropdowneditor-icon::before {
      background: url('../../media/svg/icons/clock.svg') no-repeat center center;
    }
  }

  &.pps-expired-deadline-warning {
    background-color: $warning-deadline !important;
  }

  &.pps-expired-deadline-danger {
    background-color: $danger-deadline !important;
  }
}
