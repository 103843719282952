.form-field-denied {
  display: block;
  pointer-events: none;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid var(--transparent-secondary);

  .form-field__value {
    display: none;
  }

  .form-field:after {
    content: '';
    filter: var(--gray-700-filter);
    position: absolute;
    display: block;
    background: url('../../media/svg/icons/hide.svg') no-repeat center;
    background-size: 24px 24px;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// overflow visible when absolute element inside is clipping
.overflow-visible .form-field__value {
  overflow: visible !important;
}
