.dropzone-external {
  color: var(--black);
  cursor: pointer;
  height: 55px;
  border: 2px dashed var(--surface-secondary);

  &.disabled {
    cursor: default;
    opacity: 0.4;
  }
}
