.topbar {
  display: flex;
  align-self: end;
  align-items: stretch;
  padding: 0;
  height: $header-height-desktop;

  .topbar-item {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 0.25rem;
    min-width: 65px;
    justify-content: center;
    padding: $header-topbar-item-padding;
    box-sizing: border-box;

    &.disabled {
      user-select: none;
      pointer-events: none;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 16px;
      bottom: 16px;
      border-right: 1px solid var(--gray-100);
    }
  }
}

@include media-breakpoint-down(tablet) {
  .topbar {
    height: $header-height-mobile;
    left: 0;
    right: 0;
    transition: all 0.3s ease;
    justify-content: flex-end;
    padding: 0 15px;
    border-top: 1px solid transparent;
  }

  .topbar-item {
    .user-info {
      &__name {
        display: none;
      }
    }
  }
}
