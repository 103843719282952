#kt_error_wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .logo {
    height: 75px;
    width: 80px;
  }
}

@include media-breakpoint-down(tablet) {
  .error-content {
    grid-column-start: 1;
  }
}

@include media-breakpoint-up(desktop) {
  .error-content {
    grid-column-start: 3;
  }
}
