$release-info-top-label-height: var(--release-info-label-top);
$release-info-label-bg-color: var(--primary);
$release-info-label-color: var(--white);

.release-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.release-info-top-label {
    height: $release-info-top-label-height;
    background-color: $release-info-label-bg-color;
    color: $release-info-label-color;
    position: fixed;
    top: $environment-info-top-label-height;
    right: 0;
    left: 0;
    z-index: 97;
  }
}

.release-info-link {
  text-decoration: underline;
  cursor: pointer;
}
