.dx-htmleditor {
  .dx-toolbar-item {
    .dx-button-normal {
      border-width: unset;
      border-style: unset;
    }
  }

  .dx-toolbar-after {
    .dx-button-normal {
      border-width: unset;
      border-style: unset;
    }
  }

  /* custom style */
  &.app-html-editor-style {
    .dx-htmleditor-toolbar-wrapper:first-child {
      border: none;
    }

    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #5a5b5c;

    &.dx-state-focused,
    &.dx-state-disabled {
      -webkit-box-shadow: none;
      box-shadow: none;
      opacity: 1;
    }

    &.dx-state-focused {
      border-bottom: 1px solid var(--primary);
    }

    &.dx-state-disabled {
      border-radius: 4px;
      border: 1px solid var(--transparent-secondary);
    }

    &.editable .dx-htmleditor-content {
      border-radius: 4px 4px 0 0;
      background: var(--transparent-primary);
      margin: 0;
    }
  }

  &.dx-state-readonly {
    border: 1px solid var(--transparent-secondary) !important;
    border-radius: 4px;
  }
}
