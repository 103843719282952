.header-loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
  z-index: 1000;

  .dx-progressbar-wrapper {
    position: absolute;
  }

  .dx-progressbar-container {
    height: 3px;
  }
}
