.environment-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.environment-info-top-label {
    height: $environment-info-top-label-height;
    background-color: $environment-info-label-bg-color;
    color: $environment-info-label-color;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 97;
  }

  &.environment-info-label {
    height: $environment-info-label-height;
    background-color: $environment-info-label-bg-color;
    color: $environment-info-label-color;
  }
}
