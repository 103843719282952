.subheader {
  border-bottom: 1px solid var(--border-color-light);

  .subheader-actions > *:not(:last-child) {
    margin-right: 24px;
  }
}

@each $breakpoint in map-keys($mdc-layout-grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    .subheader {
      padding: 16px var(--mdc-layout-grid-margin-#{$breakpoint});
    }
  }
}
