dx-pivot-grid {
  .dx-area-description-cell {
    .dx-button {
      height: 32px !important;
      width: 32px !important;

      &.dx-state-hover,
      &.dx-state-active {
        border: none;
      }

      &.dx-state-active {
        background-color: transparent !important;
      }
    }
  }
}
