$data-grid-row-size-extra-small: 33px;
$data-grid-row-size-small: 48px;
$data-grid-row-size-medium: 56px;
$data-grid-row-size-large: 64px;
$data-grid-border-color: var(--surface-secondary);
$data-grid-surface-color: var(--surface-primary);
$data-grid-border-radius: 8px;
$data-grid-font-family: var(--font-family-regular);
$data-grid-headers-font-family: var(--font-family-bold);
$data-grid-headers-font-size: 14px;
$data-grid-header-icons: 20px;
$data-grid-headers-line-height: 17px;
$data-grid-control-icon-size: 24px;
$data-grid-row-focus-color: var(--primary);
$data-grid-row-error-color: var(--danger);
$data-grid-header-row-height: 40px;
$data-grid-filter-row-height: 40px;

.grid-toolbar-wrapper {
  height: 55px;
  background-color: var(--white);
  border-top-left-radius: $data-grid-border-radius;
  border-top-right-radius: $data-grid-border-radius;
  border-top: 1px solid $data-grid-border-color;
  border-left: 1px solid $data-grid-border-color;
  border-right: 1px solid $data-grid-border-color;
  .dx-tabs {
    margin-left: 5px;
  }
}

.grid-no-pager .dx-datagrid-borders > .dx-datagrid-rowsview {
  border-bottom-left-radius: $data-grid-border-radius;
  border-bottom-right-radius: $data-grid-border-radius;
}

.grid-row-size-extra-small {
  .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
  .dx-datagrid .dx-datagrid-revert-tooltip .dx-revert-button {
    height: $data-grid-row-size-extra-small;
    padding-top: 0;
    padding-bottom: 0;
  }

  .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: $data-grid-row-size-extra-small;
  }
}

.grid-row-size-small {
  .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
  .dx-datagrid .dx-datagrid-revert-tooltip .dx-revert-button {
    height: $data-grid-row-size-small;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: $data-grid-row-size-small;
  }
}

.grid-row-size-medium {
  .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
  .dx-datagrid .dx-datagrid-revert-tooltip .dx-revert-button {
    height: $data-grid-row-size-medium;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: $data-grid-row-size-medium;
  }
}

.grid-row-size-large {
  .dx-datagrid .dx-datagrid-rowsview .dx-row > td,
  .dx-datagrid .dx-datagrid-revert-tooltip .dx-revert-button {
    height: $data-grid-row-size-large;
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .dx-datagrid-rowsview .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: $data-grid-row-size-large;
  }
}

.dx-datagrid .dx-datagrid-rowsview .dx-row > td.dx-editor-cell {
  padding-top: 0;
  padding-bottom: 0;
}

.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-freespace-row > td,
.dx-datagrid-rowsview .dx-datagrid-table .dx-row.dx-virtual-row > td {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.linked-documents-icon {
  font-size: 18px;
}

.dx-datagrid .dx-header-filter {
  position: relative;
  width: $data-grid-header-icons;
  height: $data-grid-header-icons;
  margin-left: 5px;

  &.dx-header-filter-empty {
    &::before {
      content: '';
      display: inline-block;
      width: $data-grid-header-icons;
      height: $data-grid-header-icons;
      background: url('../../media/svg/icons/filter.svg') no-repeat center;
      filter: none;
      background-size: $data-grid-header-icons $data-grid-header-icons;
    }
  }

  &::before {
    content: '';
    display: inline-block;
    width: $data-grid-header-icons;
    height: $data-grid-header-icons;
    background: url('../../media/svg/icons/filterFull.svg') no-repeat center;
    filter: invert(50%) sepia(70%) saturate(310%) hue-rotate(96deg) brightness(87%) contrast(89%);
    background-size: $data-grid-header-icons $data-grid-header-icons;
  }
}

.dx-datagrid {
  border-radius: $data-grid-border-radius $data-grid-border-radius 0 0;
  background-color: transparent;
  font-family: $data-grid-font-family;
  font-weight: 400;
}

.dx-datagrid .dx-header-row {
  height: $data-grid-header-row-height !important;
}

.dx-datagrid .dx-row > td {
  padding: 6px 15px;
}

.dx-datagrid .dx-datagrid-table .dx-header-row > td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dx-datagrid .dx-datagrid-filter-row .dx-editor-cell .dx-texteditor {
  height: $data-grid-filter-row-height;
  line-height: $data-grid-filter-row-height;
}

.dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: $data-grid-filter-row-height;
}

.dx-datagrid-focus-overlay {
  max-height: 40px !important;
}

.dx-datagrid .dx-datagrid-filter-row {
  height: $data-grid-filter-row-height;
}

.dx-row .dx-datagrid-filter-row {
  height: $data-grid-filter-row-height;
}

.dx-datagrid-headers.dx-header-multi-row .dx-datagrid-content .dx-datagrid-table .dx-row.dx-header-row > td {
  padding-top: 0px;
}

.dx-datagrid .dx-header-row > .dx-command-edit,
.dx-datagrid .dx-header-row > td .dx-datagrid-text-content {
  font-family: $data-grid-headers-font-family;
  font-weight: 700 !important;
  font-style: normal;
  font-size: $data-grid-headers-font-size !important;
  line-height: $data-grid-headers-line-height !important;
}

.dx-datagrid-rowsview .dx-row {
  border: 0;
}

.dx-datagrid-borders > .dx-datagrid-headers {
  border-radius: $data-grid-border-radius $data-grid-border-radius 0 0;
  background-color: $white;
}

.dx-datagrid-borders > .dx-datagrid-total-footer,
.dx-datagrid-borders > .dx-datagrid-rowsview {
  background-color: $white;
}

.dx-datagrid-borders > .dx-datagrid-total-footer {
  background-color: var(--primary-light);
}

.dx-datagrid-headers .dx-datagrid-content {
  border-radius: $data-grid-border-radius $data-grid-border-radius 0 0;
}

.dx-datagrid .dx-datagrid-headers {
  box-shadow: 0px 10px 8px -13px var(--black) !important;
  z-index: 3;
}

.dx-datagrid-borders > .dx-datagrid-pager {
  border: 1px solid $data-grid-border-color;
  border-top: 1px solid transparent;
  border-radius: 0 0 $data-grid-border-radius $data-grid-border-radius;
}

.dx-pager .dx-page,
.dx-pager .dx-page-size {
  border-radius: $data-grid-border-radius;
}

.dx-datagrid .dx-pager {
  background-color: transparent;
}

.dx-invalid.dx-template-wrapper {
  position: relative;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  font-size: 12px;
}

.dx-invalid.dx-template-wrapper::after {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 23px;
  font-size: 14px;
  font-weight: 500;
  background-color: #b00020;
  color: #fff;
  content: '!';
  border-radius: 50%;
  right: 8px;
}

.dx-datagrid-filter-row {
  background-color: transparent;
}

.dx-datagrid .dx-editor-cell .dx-texteditor {
  background-color: transparent !important;
}

.dx-datagrid-filter-row .dx-filter-modified {
  background-color: transparent;
}

.dx-datagrid .dx-invalid-message {
  display: block;
  padding: 0px !important;
}

.dx-datagrid .dx-column-lines > td {
  border-left: 1px solid $data-grid-border-color;
  border-right: none;
}

.dx-datagrid .dx-datagrid-headers .dx-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-header-row td.dx-pointer-events-none,
.dx-datagrid .dx-datagrid-rowsview .dx-virtual-row td.dx-pointer-events-none {
  border-left: 1px solid $data-grid-border-color;
  border-right: 1px solid $data-grid-border-color;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused) {
  background-color: var(--surface-secondary) !important;
}
.dx-data-row {
  &.row-background-gray {
    background-color: var(--gray-200) !important;
  }
}
.dx-data-row.dx-selection {
  background-color: var(--gray-200) !important;

  /* technology - selectable tree component*/
  &.row-selection-error {
    background-color: var(--danger-light) !important;
  }

  &.row-selection-success {
    background-color: var(--hover-primary) !important;
  }
}

// Technology colored cells
.technology-alignment-grid-cell-valid {
  background-color: rgba(55, 208, 2, 0.16) !important;
}
.technology-alignment-grid-cell-invalid {
  background-color: rgba(235, 43, 55, 0.16) !important;
}

.devexpress-grid .dx-row[data-pps-selected],
.devexpress-grid .dx-row[data-pps-selected] td {
  background-color: $data-grid-surface-color;
}

.devexpress-grid .dx-row.dx-row-alt[data-pps-selected],
.devexpress-grid .dx-row.dx-row-alt[data-pps-selected] td {
  background-color: $data-grid-surface-color;
}

.devexpress-grid .dx-row td[grid-cell-editable] {
  background-color: var(--quarternary);
}

.devexpress-grid .dx-row.dx-row-alt td[grid-cell-editable] {
  background-color: var(--quarternary);
}

.devexpress-grid .dx-row td[grid-cell-warning] {
  background-color: $warning;
}

.devexpress-grid .dx-row.dx-row-alt td[grid-cell-warning] {
  background-color: $warning;
}

.devexpress-grid .dx-row td[grid-cell-error] {
  background-color: $danger;
}

.devexpress-grid .dx-row.dx-row-alt td[grid-cell-error] {
  background-color: $danger;
}

.devexpress-grid .dx-row td[grid-cell-disabled],
.devexpress-grid .dx-row.dx-row-alt td[grid-cell-disabled] {
  background-color: $data-grid-surface-color;
  opacity: 0.33;
}

// Přebití výchozí barvy pozadí pro alternating rows
@each $color, $value in $colors {
  .devexpress-grid .dx-row.dx-selection td.bg-#{$color},
  .devexpress-grid .dx-row.dx-row-alt td.bg-#{$color},
  .devexpress-grid .dx-row.dx-row-alt.dx-selection td.bg-#{$color} {
    background-color: var(--#{$color}) !important;
  }
}

.devexpress-grid .dx-row.dx-footer-row td[grid-total-footer-success] {
  background-color: var(--quarternary);
}

.devexpress-grid .dx-row.dx-footer-row td[grid-total-footer-error] {
  background-color: var(--danger-light);
}

.devexpress-grid {
  width: 100%;
}

.grid-with-toolbar .devexpress-grid {
  .dx-datagrid-borders > .dx-datagrid-headers {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@each $breakpoint in map-keys($mdc-layout-grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    .page-variable-height {
      .devexpress-grid {
        height: calc(100vh - var(--pps-devexpress-grid--height-#{$breakpoint}, 0));
        max-height: calc(100vh - var(--pps-devexpress-grid--max-height-#{$breakpoint}, 0));
      }
    }

    .page-default-height {
      .devexpress-grid {
        height: calc(
          100vh - var(--header-height-#{$breakpoint}) - var(--mdc-layout-grid-margin-#{$breakpoint}) * 2 - var(--release-info-label-top) - #{$environment-info-top-label-height} - var(
              --pps-devexpress-grid--height-#{$breakpoint},
              0
            )
        );
        max-height: calc(
          100vh - var(--header-height-#{$breakpoint}) - var(--mdc-layout-grid-margin-#{$breakpoint}) * 2 - var(--release-info-label-top) - #{$environment-info-top-label-height} - var(
              --pps-devexpress-grid--max-height-#{$breakpoint},
              0
            )
        );
      }
    }
  }
}

.dx-icon-filter-operation-equals::before {
  content: '' !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../media/svg/icons/equalsign.svg') no-repeat center center;
  background-size: contain;
}

.dx-icon-filter-operation-default::before {
  content: '' !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../media/svg/icons/search.svg') no-repeat center center;
  background-size: contain;
}

.dx-icon-filter-operation-not-equals::before {
  color: #161718;
  content: '\f045';
}

.dx-icon-filter-operation-less::before {
  content: '' !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../media/svg/icons/chevronLeft.svg') no-repeat center center;
  background-size: contain;
}

.dx-icon-filter-operation-less-equal::before {
  color: #161718;
  content: '\f048';
}

.dx-icon-filter-operation-greater::before {
  content: '' !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../media/svg/icons/chevronRight.svg') no-repeat center center;
  background-size: contain;
}

.dx-icon-filter-operation-greater-equal::before {
  color: #161718;
  content: '\f049';
}

.dx-icon-filter-operation-contains::before {
  color: #161718;
  content: '\f063';
}

.dx-icon-filter-operation-not-contains::before {
  color: #161718;
  content: '\f066';
}

.dx-icon-filter-operation-starts-with::before {
  color: #161718;
  content: '\f064';
}

.dx-icon-filter-operation-ends-with::before {
  color: #161718;
  content: '\f065';
}

.dx-icon-filter-operation-between::before {
  color: #161718;
  content: '\f06a';
}

.devexpress-grid .dx-datebox .dx-dropdowneditor-button .dx-dropdowneditor-icon::before {
  content: '' !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../media/svg/icons/calendar.svg') no-repeat center center;
  background-size: contain;
}

.dx-sort.dx-sort-up::before {
  content: '' !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../media/svg/icons/sortUp.svg') no-repeat center center;
  background-size: contain;
}

.dx-sort.dx-sort-down::before {
  content: '' !important;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../../media/svg/icons/sortDown.svg') no-repeat center center;
  background-size: contain;
}

.dx-sort.dx-sort-up,
.dx-sort.dx-sort-down {
  padding-right: 5px;
}

.dx-header-row > td:not(:last-child):after,
.dx-datagrid-filter-row > td:not(:last-child):after {
  content: '';
  position: absolute;
  bottom: 15px;
  top: 15px;
  right: 0;
  border-right: 1px solid $data-grid-border-color;
}

.dx-header-row > td:has(+ .dx-pointer-events-none):after,
.dx-header-row > td:has(+ .dx-hidden-cell):after,
.dx-header-row > td.dx-hidden-cell:after,
.dx-header-row > td.dx-command-drag:after,
.dx-header-row > td:has(+ .dx-command-edit):after,
.dx-datagrid-filter-row > td:has(+ .dx-pointer-events-none):after,
.dx-datagrid-filter-row > td:has(+ .dx-hidden-cell):after,
.dx-datagrid-filter-row > td.dx-command-drag:after,
.dx-datagrid-filter-row > td:has(+ .dx-command-edit):after {
  border-right: 1px solid transparent;
}

.dx-header-row > td,
.dx-datagrid-filter-row > td {
  position: relative;
}

.dx-toolbar-label .dx-toolbar-item-content > div {
  font-family: $data-grid-headers-font-family;
  font-weight: 700;
  font-style: normal;
  font-size: $data-grid-headers-font-size;
  line-height: $data-grid-headers-line-height;
  padding-left: 10px;
}

.dx-datagrid-headers.dx-header-multi-row .dx-header-row > td {
  border-right: 1px solid var(--white) !important;
}

// RANGE filter
.dx-datagrid-filter-range-overlay {
  .dx-overlay-content {
    background: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .dx-editor-container {
    margin-bottom: 2px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.15);

    &.dx-datagrid-filter-range-end {
      margin-bottom: 0;
    }
  }

  .dx-texteditor.dx-editor-filled::after {
    border-bottom: none;
  }

  .dx-dropdowneditor-input-wrapper,
  .dx-texteditor-input-container {
    background: var(--white);
    border-radius: $data-grid-border-radius / 2;
  }
}

// RANGE filter - END

.inline-filter-multiple {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  background: #f0f6f3;
  width: fit-content;
  margin-left: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.inline-filter-multiple::before {
  content: '';
  display: inline-block;
  width: $data-grid-header-icons;
  height: $data-grid-header-icons;
  background: url('../../media/svg/icons/filterFull.svg') no-repeat center;
  filter: invert(50%) sepia(70%) saturate(310%) hue-rotate(96deg) brightness(87%) contrast(89%);
  background-size: $data-grid-header-icons $data-grid-header-icons;
  vertical-align: middle;
}

.inline-filter-multiple-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-bold);
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: var(--gray-900);
  min-width: 16px;
}

.inline-filter-active-icon {
  background-color: var(--primary-lighter);
  border-radius: 4px;
}

.inline-filter-active-icon::before {
  filter: invert(50%) sepia(70%) saturate(310%) hue-rotate(96deg) brightness(87%) contrast(89%);
}

.dx-datagrid-focus-overlay:after {
  visibility: hidden;
}

.dx-datagrid .dx-editor-cell.dx-focused .dx-editor-container .dx-texteditor:not(.dx-invalid) {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: $data-grid-row-focus-color;
    left: 0;
    right: 1px;
  }
}

.dx-datagrid .dx-editor-cell .dx-editor-container .dx-texteditor.dx-invalid {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: $data-grid-row-error-color;
    left: 0;
    right: 1px;
  }
}

// Drag and drop mouse cursor override
.dx-datagrid .dx-datagrid-rowsview .dx-data-row .dx-command-drag {
  cursor: grab;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused):has(.pps-expired-deadline-warning) {
  background-color: $warning-deadline !important;
}
.devexpress-grid .dx-row.dx-state-hover td:has(.pps-expired-deadline-warning),
.devexpress-grid .dx-row td:has(.pps-expired-deadline-warning) {
  background-color: $warning-deadline-secondary !important;
}

.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused)
  > td:not(.dx-focused):has(.pps-expired-deadline-danger) {
  background-color: $danger-deadline !important;
}
.devexpress-grid .dx-row.dx-state-hover td:has(.pps-expired-deadline-danger),
.devexpress-grid .dx-row td:has(.pps-expired-deadline-danger) {
  background-color: $danger-deadline-secondary !important;
}

.devexpress-grid .dx-scrollable-scrollbar {
  &.dx-scrollbar-horizontal {
    height: 8px;

    .dx-scrollable-scroll {
      height: 8px;
      padding: 0px;
    }

    .dx-scrollable-scroll-content {
      border-radius: 8px;
    }
  }

  &.dx-scrollable-scrollbar-active {
    background-color: var(--surface-primary);

    .dx-scrollable-scroll-content {
      background-color: var(--gray-300);
    }
  }
}

.inline-grid {
  overflow-x: auto;
  width: 100%;
}

.dx-datagrid-columns-separator {
  z-index: 1001;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll .dx-scrollable-scroll-content {
  border-radius: 8px;
}
